import { defineMessages } from 'react-intl';

const messages = defineMessages({
    product: {
        id: 'HoverCart.product',
        defaultMessage: 'Product',
    },
    products: {
        id: 'HoverCart.producs',
        defaultMessage: 'Products',
    },
    yourShoppingCart: {
        id: 'HoverCart.yourShoppingCart',
        defaultMessage: 'Your Shopping Cart',
    },
});

export default messages;
