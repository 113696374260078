/**
 * @author RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { TextField } from '@material-ui/core';

import messages from './messages';

class InputLastName extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            lastName: props.lastName,
            lastNameLabel: messages.lastName,
            lastNameError: false,
            lastNameDirty: false,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.lastName && prevState.lastName !== nextProps.lastName) {
            return {
                ...prevState,
                lastName: nextProps.lastName,
            };
        }
        return null;
    }

    onChangeLastName(event) {
        const val = event.target.value;
        this.setStateLastName(val, this.state.lastNameDirty);
        this.props.onChange(val);
    }

    onBlurLastName(event) {
        const val = event.target.value;
        this.setStateLastName(val, true);
    }

    /**
     * Validates the last name, and sets the state for it.
     * @param {string} val The value of the name input.
     * @param {boolean} dirty Flag if the input has already lost the focus once.
     * @return {boolean} True if valid, otherwise false.
     */
    setStateLastName(val, dirty = false) {
        const lastName = val.trim();
        let lastNameLabel = messages.lastName;
        let lastNameError = false;

        if (dirty && !val) {
            lastNameLabel = messages.lastNameRequired;
            lastNameError = true;
        } else if (dirty && val.length < 1) {
            lastNameLabel = messages.lastNameInvalid;
            lastNameError = true;
        } else if (dirty && val.length > 75) {
            lastNameLabel = messages.lastNameInvalid;
            lastNameError = true;
        }

        this.setState({
            lastName,
            lastNameLabel,
            lastNameError,
            lastNameDirty: dirty,
        });

        return !lastNameError;
    }

    validate() {
        return this.setStateLastName(this.state.lastName, true);
    }

    render() {
        return (
            <TextField
                required
                fullWidth
                autoComplete="new-last-name"
                id="lastName"
                type="text"
                value={this.state.lastName}
                label={<FormattedMessage {...this.state.lastNameLabel} />}
                error={this.state.lastNameError}
                onChange={e => this.onChangeLastName(e)}
                InputProps={{
                    onBlur: e => this.onBlurLastName(e),
                }}
                margin="normal"
            />
        );
    }

}

InputLastName.propTypes = {
    // initial last name value
    lastName: PropTypes.string.isRequired,
    // triggered on change
    onChange: PropTypes.func.isRequired,
};

export default InputLastName;
