import React from 'react';
import { Switch } from '@material-ui/core';
import Storage from 'services/Storage';

import './styles.scss';

export default class SettingsPayment extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            checked: Storage.getPreferPaymentTypeStripe() || false,
        };
    }

    onChange(event) {
        const { checked } = event.target;
        this.setState({ checked });

        Storage.setPreferPaymentTypeStripe(checked)
            .catch(e => console.log('Set prefer online payment failed', e));
    }

    render() {
        const { checked } = this.state;

        return (
            <Switch
                className="settings-payment"
                checked={!!checked}
                onChange={e => this.onChange(e)}
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
        );
    }

}
