import { defineMessages } from 'react-intl';

const messages = defineMessages({
    logout: {
        id: 'MenuDrawer.logout',
        defaultMessage: 'Logout',
    },
    settings: {
        id: 'MenuDrawer.settings',
        defaultMessage: 'Settings',
    },
    confirmLogoutTitle: {
        id: 'MenuDrawer.confirmLogoutTitle',
        defaultMessage: 'Logout',
    },
    confirmLogoutContent: {
        id: 'MenuDrawer.confirmLogoutContent',
        defaultMessage: 'Do you really want to logout?',
    },
});

export default messages;
