/**
 * @author RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
import React from 'react';
import {
    Grid,
    Typography,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import MenuBar from 'containers/MenuBar';
import SettingsLocale from '../SettingsLocale';
import SettingsPayment from '../SettingsPayment';
import messages from './messages';

export default function Settings() {
    return (
        <div className="settings-page">
            <MenuBar titleMessage={messages.title} />
            <div className="page-content">
                <form autoComplete="off">
                    <Grid container justify="center" spacing={0} className="page">
                        <Grid item xs={4}>
                            <Typography
                                variant="body2"
                                align="left"
                                style={{ lineHeight: '2rem' }}
                            >
                                <FormattedMessage {...messages.language} />
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <SettingsLocale />
                        </Grid>
                    </Grid>
                    <Grid container justify="center" spacing={0} className="page">
                        <Grid item xs={10}>
                            <Typography
                                variant="body2"
                                align="left"
                                style={{ lineHeight: '2rem' }}
                            >
                                <FormattedMessage {...messages.preferOnlinePayment} />
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <SettingsPayment />
                        </Grid>
                    </Grid>
                </form>
            </div>
        </div>
    );
}
