import { defineMessages } from 'react-intl';

const messages = defineMessages({
    email: {
        id: 'InputEmail.email',
        defaultMessage: 'Email',
    },
    emailRequired: {
        id: 'InputEmail.emailRequired',
        defaultMessage: 'Email required',
    },
    emailInvalid: {
        id: 'InputEmail.emailInvalid',
        defaultMessage: 'Email invalid',
    },
    emailInvalidLength: {
        id: 'InputEmail.emailInvalidLength',
        defaultMessage: 'Email must have between 6 and 100 characters',
    },
});

export default messages;
