import { push } from 'connected-react-router';
import Storage from 'services/Storage';
import Push from 'services/Push';
import Device from 'services/Device';
import Network from 'services/Network';
import Deeplink from 'services/Deeplink';
import Statusbar from 'services/Statusbar';
import { showSnackbar } from 'containers/Snackbar/actions';
import { setLogin, logoutSuccess } from 'containers/Login/actions';
import { changeLocale } from 'containers/LanguageProvider/actions';
import {
    CORDOVA_INIT_DONE,
    REQUEST_LOCATION_INFO,
    UPDATE_LOCATION_INFO,
    UNSET_LOCATION_INFO,
    UPDATE_CONNECTION_INFO,
    PUSH_NOTIFICATION_RECEIVE,
    PUSH_NOTIFICATION_CLEAR,
    VERIFY_TOKEN_REQUEST,
    VERIFY_TOKEN_SUCCESS,
    VERIFY_TOKEN_ERROR,
    LOAD_API_VERSION_REQUEST,
    LOAD_API_VERSION_SUCCESS,
    LOAD_API_VERSION_ERROR,
    SET_TERMS_OF_SERVICE_COMPATIBLE,
    CHECK_TERMS_OF_SERVICE_REQUEST,
    CHECK_TERMS_OF_SERVICE_SUCCESS,
    CHECK_TERMS_OF_SERVICE_ERROR,
    AGREE_TERMS_OF_SERVICE_REQUEST,
    AGREE_TERMS_OF_SERVICE_SUCCESS,
    AGREE_TERMS_OF_SERVICE_ERROR,
    UPDATE_DEVICE_INFORMATION_REQUEST,
    UPDATE_DEVICE_INFORMATION_SUCCESS,
    UPDATE_DEVICE_INFORMATION_ERROR,
    LOAD_SETTINGS_REQUEST,
    LOAD_SETTINGS_SUCCESS,
    LOAD_SETTINGS_ERROR,
} from './constants';
import messages from './messages';
import { API_URI } from '../../config';
import { getDeviceWithPushToken } from '../../device';

export function cordovaInitDone() {
    return {
        type: CORDOVA_INIT_DONE,
    };
}

export function requestLocationInfo() {
    return {
        type: REQUEST_LOCATION_INFO,
    };
}

export function updateLocationInfo(coordinates) {
    return {
        type: UPDATE_LOCATION_INFO,
        coordinates,
    };
}

export function unsetLocationInfo() {
    return {
        type: UNSET_LOCATION_INFO,
    };
}

export function updateConnectionInfo(isConnected) {
    return {
        type: UPDATE_CONNECTION_INFO,
        isConnected,
    };
}

export function pushNotificationReceive(pushNotification) {
    return {
        type: PUSH_NOTIFICATION_RECEIVE,
        pushNotification,
    };
}

export function pushNotificationClear() {
    return {
        type: PUSH_NOTIFICATION_CLEAR,
    };
}

/**
 * Runs the initialization of the Cordova plugins.
 * Once all plugins have been initialized, the cordovaInitDone
 * action is dispatched.
 * @return {function(*)}
 */
export function initCordova(history) {
    return dispatch => {
        Promise.all([
            Storage.init(),
            Push.init(dispatch),
            Device.init(),
            Network.init(dispatch),
            Deeplink.init(history),
            Statusbar.init(),
        ]).then(() => {
            console.log('All services initialized, fetching API version');
            dispatch(loadApiVersion());
            dispatch(loadSettings());
            dispatch(changeLocale(Storage.getLocale()));
            if (Storage.isLoggedIn()) {
                dispatch(setLogin());
                console.log('Token available, verifying if token is valid');
                dispatch(verifyToken());
                console.log('Send device (including Push token) to backend');
                dispatch(updateDeviceInformation());
            }
            dispatch(cordovaInitDone());
        }).catch(e => {
            console.log(`Error during initialization: ${e}`);
            console.log(e);
            dispatch(cordovaInitDone());
        });
    };
}

export function clearPushNotifications() {
    return dispatch => dispatch(pushNotificationClear());
}

export function setTermsOfServiceCompatible(isCompatible) {
    return {
        type: SET_TERMS_OF_SERVICE_COMPATIBLE,
        isCompatible,
    };
}

// #region verifyToken

export function verifyTokenRequest() {
    return {
        type: VERIFY_TOKEN_REQUEST,
    };
}

export function verifyTokenSuccess() {
    return {
        type: VERIFY_TOKEN_SUCCESS,
    };
}

export function verifyTokenError(error) {
    return {
        type: VERIFY_TOKEN_ERROR,
        error,
    };
}

export function verifyToken() {
    return dispatch => {
        dispatch(verifyTokenRequest());

        const url = new URL('/api/verify', API_URI).toString();

        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${Storage.getToken()}`,
                'Content-Type': 'application/json',
            },
        }).then(response => {
            if (response.ok) {
                dispatch(verifyTokenSuccess());
                return Promise.resolve();
            } else if (response.status === 401) {
                // Unauthorized -- token is invalid
                return Storage.unsetToken()
                    .then(() => {
                        dispatch(showSnackbar(messages.invalidToken));
                        dispatch(logoutSuccess());
                        dispatch(push('/login'));
                    });
            } else {
                return response.json()
                    .then(json => Promise.reject(json));
            }
        }).catch(error => {
            dispatch(verifyTokenError(error));
        });
    };
}

// #endregion

// #region loadApiVerion

export function loadApiVersionRequest() {
    return {
        type: LOAD_API_VERSION_REQUEST,
    };
}

export function loadApiVersionSuccess(apiVersion) {
    return {
        type: LOAD_API_VERSION_SUCCESS,
        apiVersion,
    };
}

export function loadApiVersionError(error) {
    return {
        type: LOAD_API_VERSION_ERROR,
        error,
    };
}

export function loadApiVersion() {
    return dispatch => {
        dispatch(loadApiVersionRequest());

        const url = new URL('/api/public/version', API_URI).toString();

        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                return response.json()
                    .then(json => Promise.reject(json));
            }
        }).then(result => {
            dispatch(loadApiVersionSuccess(result.customer));
        }).catch(error => {
            dispatch(loadApiVersionError(error));
        });
    };
}

// #endregion

// #region checkTermsOfService

export function checkTermsOfServiceRequest() {
    return {
        type: CHECK_TERMS_OF_SERVICE_REQUEST,
    };
}

/**
 * The check for the terms of service succeeded.
 * @param {Boolean} compatible True if the terms of service must not be updated and no action is required,
 *                             or false if the terms of service have changed and must be updated.
 * @return {{type: string, compatible: boolean}}
 */
export function checkTermsOfServiceSuccess(compatible) {
    return {
        type: CHECK_TERMS_OF_SERVICE_SUCCESS,
        compatible,
    };
}

export function checkTermsOfServiceError(error) {
    return {
        type: CHECK_TERMS_OF_SERVICE_ERROR,
        error,
    };
}

export function checkTermsOfService() {
    return dispatch => {
        dispatch(checkTermsOfServiceRequest());

        const url = new URL('/api/customer/terms-of-service', API_URI).toString();

        fetch(url, {
            method: 'HEAD',
            headers: {
                Authorization: `Bearer ${Storage.getToken()}`,
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (response.ok) {
                    // terms of service have not changed, no update required
                    return Promise.resolve(true);
                } else if (response.status === 409) {
                    // terms of service have changed and must be updated
                    return Promise.resolve(false);
                } else {
                    return response.json()
                        .then(json => Promise.reject(json));
                }
            })
            .then(compatible => {
                dispatch(checkTermsOfServiceSuccess(compatible));
            })
            .catch(error => {
                dispatch(checkTermsOfServiceError(error));
            });
    };
}

// #endregion

// #region agreeTermsOfService

export function agreeTermsOfServiceRequest() {
    return {
        type: AGREE_TERMS_OF_SERVICE_REQUEST,
    };
}

export function agreeTermsOfServiceSuccess() {
    return {
        type: AGREE_TERMS_OF_SERVICE_SUCCESS,
    };
}

export function agreeTermsOfServiceError(error) {
    return {
        type: AGREE_TERMS_OF_SERVICE_ERROR,
        error,
    };
}

export function agreeTermsOfService() {
    return dispatch => {
        dispatch(agreeTermsOfServiceRequest());

        const url = new URL('/api/customer/terms-of-service', API_URI).toString();

        fetch(url, {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${Storage.getToken()}`,
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (response.ok) {
                    return Promise.resolve(true);
                } else {
                    return response.json()
                        .then(json => Promise.reject(json));
                }
            })
            .then(compatible => {
                dispatch(agreeTermsOfServiceSuccess(compatible));
            })
            .catch(error => {
                dispatch(agreeTermsOfServiceError(error));
            });
    };
}

// #endregion

// #region updateDeviceInformation

export function updateDeviceInformationRequest() {
    return {
        type: UPDATE_DEVICE_INFORMATION_REQUEST,
    };
}

export function updateDeviceInformationSuccess() {
    return {
        type: UPDATE_DEVICE_INFORMATION_SUCCESS,
    };
}

export function updateDeviceInformationError(error) {
    return {
        type: UPDATE_DEVICE_INFORMATION_ERROR,
        error,
    };
}

export function updateDeviceInformation() {
    return async dispatch => {
        dispatch(updateDeviceInformationRequest());

        const device = await getDeviceWithPushToken();
        if (!device) {
            console.info('Updating of device information skipped');
            return;
        }

        const url = new URL('/api/customer/device', API_URI).toString();

        fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${Storage.getToken()}`,
            },
            body: JSON.stringify(device),
        })
            .then(response => {
                if (response.ok) {
                    dispatch(updateDeviceInformationSuccess());
                } else {
                    dispatch(updateDeviceInformationError(`${response.status} ${response.statusText}`));
                }
            })
            .catch(error => dispatch(updateDeviceInformationError(error)));
    };
}

// #endregion

// #region loadSettings

export function loadSettingsRequest() {
    return {
        type: LOAD_SETTINGS_REQUEST,
    };
}

export function loadSettingsSuccess(settings) {
    return {
        type: LOAD_SETTINGS_SUCCESS,
        settings,
    };
}

export function loadSettingsError(error) {
    return {
        type: LOAD_SETTINGS_ERROR,
        error,
    };
}

export function loadSettings() {
    return dispatch => {
        dispatch(loadSettingsRequest());

        const url = new URL('/api/public/settings', API_URI).toString();

        fetch(url, { method: 'GET' })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json()
                        .then(json => Promise.reject(json));
                }
            })
            .then(json => {
                dispatch(loadSettingsSuccess(json));
                return Promise.resolve(json);
            })
            .catch(err => {
                dispatch(loadSettingsError(err));
                return Promise.reject(err);
            });
    };
}

// #endregion
