import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Fade } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';

import HoverCart from 'components/HoverCart';
import BoxLink from 'components/BoxLink';
import MerchantBox from 'components/MerchantBox';
import Alert from 'components/Alert';

import MenuBar from 'containers/MenuBar';
import Scroll from 'containers/Scroll';

import { loadMerchants as loadMerchantsAction } from 'containers/Merchants/actions';
import messages from 'containers/App/messages';

import './styles.scss';

class Merchants extends React.Component { // eslint-disable-line

    componentDidMount() {
        const {
            merchants,
            merchantsLoading,
        } = this.props;

        if (!merchants && !merchantsLoading) {
            this.loadData();
        }

        this.resume = this.loadData.bind(this);
        document.addEventListener('resume', this.resume);
    }

    componentWillUnmount() {
        document.removeEventListener('resume', this.resume);
    }

    loadData() {
        const {
            loadMerchants,
            loggedIn,
            customer,
            userCoordinates,
        } = this.props;

        loadMerchants(
            loggedIn,
            customer,
            userCoordinates,
        );
    }

    handleRefresh(e) {
        e.preventDefault();
        this.loadData();
    }

    renderMerchantBoxes() {
        const {
            merchants,
            userCoordinates,
        } = this.props;

        // Merchants are always available
        return Object.keys(merchants).map(mId => this.renderMerchantBox(merchants[mId], userCoordinates));
    }

    renderMerchantBox(merchant, userCoordinates) {
        return (
            merchant && merchant._id &&
            // "will-change" required for Safari (#281)
            <Fade in key={merchant._id} style={{ willChange: 'opacity' }}>
                <BoxLink to={`/products/${merchant._id}`}>
                    <MerchantBox
                        key={merchant._id}
                        merchant={merchant}
                        userCoordinates={userCoordinates}
                    />
                </BoxLink>
            </Fade>
        );
    }

    render() {
        const {
            merchants,
            hasShoppingCart,
            settings,
        } = this.props;

        let content;

        if (!merchants) {
            content = <div />;
        } else {
            content = this.renderMerchantBoxes();
        }

        return (
            <div className={`merchants-page ${hasShoppingCart ? 'has-shopping-cart' : ''}`}>
                <MenuBar
                    titleMessage={messages.stores}
                    onRefreshClick={e => this.handleRefresh(e)}
                />
                <Scroll sid="merchants" className="page-content">
                    {settings?.deliveryNotes &&
                        <Alert severity="info">
                            <ReactMarkdown
                                skipHtml
                                unwrapDisallowed
                                allowedTypes={['text', 'paragraph', 'strong', 'break']}
                                className="delivery-notes"
                            >
                                {settings.deliveryNotes}
                            </ReactMarkdown>
                        </Alert>
                    }
                    {content}
                </Scroll>
                <HoverCart />
            </div>
        );
    }

}

Merchants.propTypes = {
    merchants: PropTypes.object,
    merchantsLoading: PropTypes.bool.isRequired,
    customer: PropTypes.object,
    loadMerchants: PropTypes.func, // eslint-disable-line
    hasShoppingCart: PropTypes.bool,
    loggedIn: PropTypes.bool, // eslint-disable-line
    userCoordinates: PropTypes.object,
    settings: PropTypes.object,
};

function mapStateToProps(state) {
    return {
        userCoordinates: state.global.coordinates,
        userCoordinatesUpdated: state.global.coordinatesUpdated,
        merchants: state.merchants.merchants,
        merchantsLoading: state.merchants.merchantsLoading,
        customer: state.customer.customer,
        hasShoppingCart: state.shoppingCart.hover,
        loggedIn: state.login.loggedIn,
        requestCoordinates: state.global.requestCoordinates,
        settings: state.global.settings,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadMerchants: (loggedIn, customer, coords) => dispatch(loadMerchantsAction(loggedIn, customer, coords)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Merchants);
