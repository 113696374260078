import React from 'react';
import PropTypes from 'prop-types';
import MaterialAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MaterialAlert {...props} style={{ wordBreak: 'break-word' }}>{props.children}</MaterialAlert>;
}

Alert.propTypes = {
    children: PropTypes.node,
};

export default Alert;
