import { defineMessages } from 'react-intl';

const messages = defineMessages({
    title: {
        id: 'Settings.title',
        defaultMessage: 'Settings',
    },
    language: {
        id: 'Settings.language',
        defaultMessage: 'Language',
    },
    preferOnlinePayment: {
        id: 'Settings.preferOnlinePayment',
        defaultMessage: 'Prefer online payment',
    },
});

export default messages;
