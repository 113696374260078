import { defineMessages } from 'react-intl';

/* eslint-disable max-len */
const messages = defineMessages({
    confirmSwitchCartTitle: {
        id: 'Products.confirmSwitchCartTitle',
        defaultMessage: 'Replace shopping cart',
    },
    confirmSwitchCartContent: {
        id: 'Products.confirmSwitchCartContent',
        defaultMessage: 'Your shopping cart has products from another merchant. Do you want to choose products from this merchant instead?',
    },
    switchCart: {
        id: 'Products.switchCart',
        defaultMessage: 'Replace cart',
    },
});
/* eslint-enable max-len */

export default messages;
