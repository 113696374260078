/**
 * @author RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
    Grid,
    Button,
    Typography,
    CircularProgress,
    Card,
    CardContent,
    CardActions,
} from '@material-ui/core';

import MenuBar from 'containers/MenuBar';
import AuthFormFields from 'components/AuthFormFields';
import StorageDialog from 'components/StorageDialog';

import { setPhoneNumber as setPhoneNumberAction } from 'containers/PasswordReset/actions';

import Storage from 'services/Storage';

import { login as loginAction } from './actions';

import globalMessages from '../App/messages';
import messages from './messages';

class Login extends React.Component {

    constructor(props) {
        const secureStorageDialogOpen = (Storage.isMobile() && !Storage.hasSecureStorage())
            || (Storage.isBrowser() && !Storage.hasLocalStorage());

        super(props);

        this.state = {
            areaCode: '41',
            phoneNumber: '',
            password: '',
            secureStorageDialogOpen,
            secureStorageDialogBrowser: Storage.isBrowser(),
        };

        this.handleDialogCloseClick = this.handleDialogCloseClick.bind(this);
        this.doLogin = this.doLogin.bind(this);

        props.setPhoneNumber('', '');
    }

    onBlurPhoneNumber(phoneNumber) {
        this.setState({ phoneNumber }, () => {
            this.props.setPhoneNumber(this.state.areaCode, this.state.phoneNumber);
        });
    }

    onChangePhoneNumber(phoneNumber) {
        this.setState({ phoneNumber });
    }

    onChangePassword(password) {
        this.setState({ password });
    }

    doLogin(event) {
        const valid = this.form.validate();
        if (valid) {
            this.props.login(this.state.areaCode, this.state.phoneNumber, this.state.password);
        }
        event.preventDefault();
    }

    handleDialogCloseClick() {
        this.setState({ secureStorageDialogOpen: false });
    }

    render() {
        const { loggedIn, location } = this.props;
        const {
            secureStorageDialogOpen,
            secureStorageDialogBrowser,
        } = this.state;

        if (loggedIn) {
            return <Redirect to={location.state?.from || '/merchants'} />;
        }

        return (
            <div className="login-page">
                <MenuBar titleMessage={globalMessages.login} />
                <div className="page-content">
                    <Grid container justify="center" spacing={0} className="page">
                        <Grid item xs={12} sm={8}>
                            <Card variant="outlined" style={{ border: 0 }}>
                                <form
                                    onSubmit={this.doLogin}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <CardContent>
                                        <AuthFormFields
                                            ref={form => { this.form = form; }}
                                            phoneNumber=""
                                            onBlurPhoneNumber={p => this.onBlurPhoneNumber(p)}
                                            onChangePhoneNumber={p => this.onChangePhoneNumber(p)}
                                            onChangePassword={p => this.onChangePassword(p)}
                                        />
                                        <Button
                                            component={Link}
                                            to="/password/reset"
                                            color="primary"
                                            className="default-link-style"
                                        >
                                            <FormattedMessage {...messages.resetPassword} />
                                        </Button>
                                    </CardContent>
                                    <CardActions style={{ justifyContent: 'center' }}>
                                        <Button
                                            type="submit"
                                            data-testid="login-button"
                                            variant="contained"
                                            color="primary"
                                            disabled={this.props.loading}
                                        >
                                            {this.props.loading && (
                                                <React.Fragment>
                                                    <CircularProgress color="inherit" size="1rem" />
                                                &nbsp;
                                                </React.Fragment>
                                            )}
                                            <FormattedMessage {...globalMessages.login} />
                                        </Button>
                                    </CardActions>
                                </form>
                            </Card>
                            <div className="login-register-now" style={{ textAlign: 'center' }}>
                                <Typography variant="subtitle1" className="text-hint">
                                    <FormattedMessage {...messages.registerNow} />
                                </Typography>
                                <Button component={Link} to="/registration" color="primary">
                                    <FormattedMessage {...messages.register} />
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <StorageDialog
                    open={secureStorageDialogOpen}
                    browser={secureStorageDialogBrowser}
                    onClose={this.handleDialogCloseClick}
                    onAccept={this.handleDialogCloseClick}
                />
            </div>
        );
    }

}

Login.propTypes = {
    dispatch: PropTypes.func.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    login: PropTypes.func.isRequired,
    setPhoneNumber: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return {
        loggedIn: state.login.loggedIn,
        loading: state.login.loading,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        login: (areaCode, phoneNumber, password) => dispatch(loginAction(areaCode, phoneNumber, password)),
        setPhoneNumber: (areaCode, phoneNumber) => dispatch(setPhoneNumberAction(areaCode, phoneNumber)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
