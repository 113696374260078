/**
 * @author RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { TextField } from '@material-ui/core';

import messages from './messages';

class InputFirstName extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            firstName: props.firstName,
            firstNameLabel: messages.firstName,
            firstNameError: false,
            firstNameDirty: false,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.firstName && prevState.firstName !== nextProps.firstName) {
            return {
                ...prevState,
                firstName: nextProps.firstName,
            };
        }
        return null;
    }

    onChangeFirstName(event) {
        const val = event.target.value;
        this.setStateFirstName(val, this.state.firstNameDirty);
        this.props.onChange(val);
    }

    onBlurFirstName(event) {
        const val = event.target.value;
        this.setStateFirstName(val, true);
    }

    /**
     * Validates the first name, and sets the state for it.
     * @param {string} val The value of the name input.
     * @param {boolean} dirty Flag if the input has already lost the focus once.
     * @return {boolean} True if valid, otherwise false.
     */
    setStateFirstName(val, dirty = false) {
        const firstName = val.trim();
        let firstNameLabel = messages.firstName;
        let firstNameError = false;

        if (dirty && !val) {
            firstNameLabel = messages.firstNameRequired;
            firstNameError = true;
        } else if (dirty && val.length < 1) {
            firstNameLabel = messages.firstNameInvalid;
            firstNameError = true;
        } else if (dirty && val.length > 75) {
            firstNameLabel = messages.firstNameInvalid;
            firstNameError = true;
        }

        this.setState({
            firstName,
            firstNameLabel,
            firstNameError,
            firstNameDirty: dirty,
        });

        return !firstNameError;
    }

    validate() {
        return this.setStateFirstName(this.state.firstName, true);
    }

    render() {
        return (
            <TextField
                required
                fullWidth
                autoComplete="new-first-name"
                id="firstName"
                type="text"
                value={this.state.firstName}
                label={<FormattedMessage {...this.state.firstNameLabel} />}
                error={this.state.firstNameError}
                onChange={e => this.onChangeFirstName(e)}
                InputProps={{
                    onBlur: e => this.onBlurFirstName(e),
                }}
                margin="normal"
            />
        );
    }

}

InputFirstName.propTypes = {
    // initial first name value
    firstName: PropTypes.string.isRequired,
    // triggered on change
    onChange: PropTypes.func.isRequired,
};

export default InputFirstName;
