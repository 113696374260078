import React from 'react';
import PropTypes from 'prop-types';

function formatSupportId(value) {
    try {
        // pattern "XXX-XXX-XXX"
        const id = value.toUpperCase();
        return `${id.substr(0, 3)}-${id.substr(3, 3)}-${id.substr(6, 3)}`;
    } catch (e) {
        return value;
    }
}

function FormattedSupportId(props) {
    return (
        <span className="support-id">{formatSupportId(props.value)}</span>
    );
}

FormattedSupportId.propTypes = {
    value: PropTypes.string.isRequired,
};

export default FormattedSupportId;
