/**
 * @author RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { TextField } from '@material-ui/core';

import messages from './messages';

class InputEmail extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email: props.email || '',
            emailLabel: messages.email,
            emailError: false,
            emailDirty: false,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.email && prevState.email !== nextProps.email) {
            return {
                ...prevState,
                email: nextProps.email,
            };
        }
        return null;
    }

    onChangeEmail(event) {
        const val = event.target.value;
        this.setStateEmail(val, this.state.emailDirty);
        this.props.onChange(val);
    }

    onBlurEmail(event) {
        const val = event.target.value;
        this.setStateEmail(val, true);
    }

    /**
     * Validates the email, and sets the state for it.
     * @param {string} val The value of the email input.
     * @param {boolean} dirty Flag if the input has already lost the focus once.
     * @return {boolean} True if valid, otherwise false.
     */
    setStateEmail(val, dirty = false) {
        const email = val.trim();
        let emailLabel = messages.email;
        let emailError = false;

        const re = /\S+@\S+\.\S{2,}/;

        if (dirty && !val) {
            emailLabel = messages.emailRequired;
            emailError = true;
        } else if (dirty && !re.test(email)) {
            emailLabel = messages.emailInvalid;
            emailError = true;
        } else if (dirty && val.length > 100) {
            emailLabel = messages.emailInvalidLength;
            emailError = true;
        }

        this.setState({
            email,
            emailLabel,
            emailError,
            emailDirty: dirty,
        });

        return !emailError;
    }

    validate() {
        return this.setStateEmail(this.state.email, true);
    }

    render() {
        return (
            <TextField
                required
                fullWidth
                autoComplete="new-email"
                id="email"
                type="text"
                value={this.state.email}
                label={<FormattedMessage {...this.state.emailLabel} />}
                error={this.state.emailError}
                onChange={e => this.onChangeEmail(e)}
                InputProps={{
                    onBlur: e => this.onBlurEmail(e),
                }}
                margin="normal"
            />
        );
    }

}

InputEmail.propTypes = {
    email: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

export default InputEmail;
