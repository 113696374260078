export const SET_PRODUCT = 'ShoppingCart/SET_PRODUCT';
export const SET_PRODUCT_VALIDITY = 'ShoppingCart/SET_PRODUCT_VALIDITY';
export const CLEAR_CART = 'ShoppingCart/CLEAR_CART';
export const SET_COMMENT = 'ShoppingCart/SET_COMMENT';
export const SET_PAYMENT_TYPE = 'ShoppingCart/SET_PAYMENT_TYPE';
export const SET_PAYMENT_METHOD = 'ShoppingCart/SET_PAYMENT_METHOD';
export const SAVE_PAYMENT_METHOD = 'ShoppingCart/SAVE_PAYMENT_METHOD';
export const CREATE_NEW_PAYMENT_METHOD = 'ShoppingCart/CREATE_NEW_PAYMENT_METHOD';

export const OPEN_HOVERCART = 'ShoppingCart/OPEN_HOVERCART';
export const CLOSE_HOVERCART = 'ShoppingCart/CLOSE_HOVERCART';

export const SUBMIT_ORDER_REQUEST = 'ShoppingCart/SUBMIT_ORDER_REQUEST';
export const SUBMIT_ORDER_SUCCESS = 'ShoppingCart/SUBMIT_ORDER_SUCCESS';
export const SUBMIT_ORDER_ERROR = 'ShoppingCart/SUBMIT_ORDER_ERROR';

export const PAYMENT_TYPE = Object.freeze({
    stripe: 'STRIPE',
});
