import { updateConnectionInfo as updateConnectionInfoAction } from 'containers/App/actions';

class Network {

    static init(dispatch) {

        document.addEventListener('deviceready', () => {

            // Hook into cordova lifecycle events
            document.addEventListener('online', () => {
                console.log('Network: Switched state to network online');
                Network.updateConnectionInfo(dispatch, true);
            });

            document.addEventListener('offline', () => {
                console.log('Network: Switched state to network offline');
                Network.updateConnectionInfo(dispatch, false);
            });

            document.addEventListener('resume', () => {
                console.log('Network: Switched state to resume app');
                Network.updateConnectionInfo(dispatch, Network.isConnected());
            });

            Network.updateConnectionInfo(dispatch, Network.isConnected());

        }, false);

    }

    static updateConnectionInfo(dispatch, isConnected) {
        dispatch(updateConnectionInfoAction(isConnected));
    }

    static isConnected() {
        if (!navigator.connection) {
            return false;
        }

        const networkState = navigator.connection.type;
        return !(!networkState || networkState.toLowerCase() === 'none');
    }

}

export default Network;
