import { defineMessages } from 'react-intl';

const messages = defineMessages({
    code: {
        id: 'InputCode.code',
        defaultMessage: 'Confirmation Code',
    },
    codeRequired: {
        id: 'InputCode.codeRequired',
        defaultMessage: 'Confirmation Code required',
    },
    codeInvalid: {
        id: 'InputCode.codeInvalid',
        defaultMessage: 'Confirmation Code must have 6 digits',
    },
});

export default messages;
