import { defineMessages } from 'react-intl';

const messages = defineMessages({
    street: {
        id: 'InputStreet.street',
        defaultMessage: 'Street and number',
    },
    streetRequired: {
        id: 'InputStreet.streetRequired',
        defaultMessage: 'Street required',
    },
    streetInvalid: {
        id: 'InputStreet.streetInvalid',
        defaultMessage: 'Street must have between 1 and 150 characters',
    },
});

export default messages;
