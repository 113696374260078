import { defineMessages } from 'react-intl';

const messages = defineMessages({
    termsOfService: {
        id: 'TermsOfServiceRef.termsOfService',
        defaultMessage: 'general terms of service and privacy policy',
    },
});

export default messages;
