import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';

import messages from './messages';

function ConfirmDialog({
    onClose,
    onCancelClick,
    onConfirmClick,
    titleMessage,
    contentMessage,
    confirmMessage,
    open,
}) {
    const platformId = window.cordova ? window.cordova.platformId : '';

    return (
        <Dialog open={open} onClose={onClose} className={platformId}>
            <DialogTitle>
                <FormattedMessage {...titleMessage} />
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <FormattedMessage {...contentMessage} />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancelClick} color="default" data-testid="confirm-dialog-cancel">
                    <FormattedMessage {...messages.cancel} />
                </Button>
                <Button onClick={onConfirmClick} color="primary" data-testid="confirm-dialog-confirm">
                    <FormattedMessage {...confirmMessage} />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ConfirmDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired,
    onConfirmClick: PropTypes.func.isRequired,
    titleMessage: PropTypes.object.isRequired,
    contentMessage: PropTypes.object.isRequired,
    confirmMessage: PropTypes.object.isRequired,
    open: PropTypes.bool,
};

export default ConfirmDialog;
