import React from 'react';
import PropTypes from 'prop-types';
import ProductOptionRadioGroup from 'components/ProductOptionRadioGroup';
import ProductOptionCheckboxGroup from 'components/ProductOptionCheckboxGroup';

import './styles.scss';

class ProductOptionTable extends React.PureComponent {

    render() {
        const content = this.props.productOptionGroupIds.map(pogId => {
            const optionGroup = this.props.allOptionGroups.find(pog => pog._id === pogId);
            const options = this.props.allOptions.filter(o => o.optionGroup === pogId);
            const selectedOptions = this.props.allSelectedOptions.filter(
                selectedOption => options.find(option => option._id === selectedOption._id)
            );
            if (optionGroup.multipleChoice) {
                return (
                    <div key={`pog-${optionGroup._id}`} className="product-option-group">
                        <ProductOptionCheckboxGroup
                            selected={selectedOptions}
                            quantity={this.props.quantity}
                            optionGroup={optionGroup}
                            options={options}
                            locale={this.props.locale}
                            onChange={(o, g) => this.props.onChange(o, g)}
                        />
                    </div>
                );
            } else {
                return (
                    <div key={`pog-${optionGroup._id}`} className="product-option-group">
                        <ProductOptionRadioGroup
                            selected={selectedOptions.length > 0 ? selectedOptions[0]._id : ''}
                            quantity={this.props.quantity}
                            optionGroup={optionGroup}
                            options={options}
                            locale={this.props.locale}
                            onChange={(o, g) => this.props.onChange(o, g)}
                        />
                    </div>
                );
            }
        });

        return (
            <div>
                {content}
            </div>
        );
    }

}

ProductOptionTable.propTypes = {
    allSelectedOptions: PropTypes.array,
    quantity: PropTypes.number,
    productOptionGroupIds: PropTypes.array.isRequired,
    allOptionGroups: PropTypes.array.isRequired,
    allOptions: PropTypes.array.isRequired,
    locale: PropTypes.string.isRequired,
    onChange: PropTypes.func,
};

export default ProductOptionTable;
