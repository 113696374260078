import {
    PASSWORD_RESET_REQUEST,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_ERROR,
    SET_PHONE_NUMBER,
} from './constants';

const initialState = {
    areaCode: '',
    phoneNumber: '',
    loading: false,
    result: null,
    error: null,
};

/*
 * REDUCERS
 */

function passwordResetReducer(state = initialState, action) {
    switch (action.type) {
        case PASSWORD_RESET_REQUEST:
            return {
                ...state,
                areaCode: action.areaCode,
                phoneNumber: action.phoneNumber,
                loading: true,
                result: null,
                error: null,
            };
        case PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                loading: false,
                result: action.result,
                error: null,
            };
        case PASSWORD_RESET_ERROR:
            return {
                ...state,
                loading: false,
                result: null,
                error: action.error,
            };
        case SET_PHONE_NUMBER:
            return {
                ...state,
                areaCode: action.areaCode,
                phoneNumber: action.phoneNumber,
            };
        default:
            return state;
    }
}

export default passwordResetReducer;
