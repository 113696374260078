import { defineMessages } from 'react-intl';

const messages = defineMessages({
    city: {
        id: 'InputCity.city',
        defaultMessage: 'City',
    },
    cityRequired: {
        id: 'InputCity.cityRequired',
        defaultMessage: 'City required',
    },
    cityInvalid: {
        id: 'InputCity.cityInvalid',
        defaultMessage: 'City must have between 1 and 75 characters',
    },
});

export default messages;
