/* eslint-disable max-len */
import { createMuiTheme } from '@material-ui/core/styles';

export const COLOR_BRAND = '#03528a';

// define the mybuxi Colors here
export default createMuiTheme({
    palette: {
        primary: {
            light: '#546597',
            main: COLOR_BRAND,
            dark: '#00163e',
            contrastText: '#fff',
        },
        secondary: {
            main: '#C9004A',
        },
        text: {
            primary: 'rgba(0, 0, 0, 0.87)',
            secondary: 'rgba(0,0,0, 0.54)',
            disabled: 'rgba(0, 0, 0, 0.38)',
            hint: 'rgba(0, 0, 0, 0.38)',
        },
        error: {
            main: '#ED1C24',
        },
    },
    overrides: {
        MuiDivider: {
            root: {
                backgroundColor: '#E3E3E3',
            },
        },
        MuiListItemIcon: {
            root: {
                minWidth: '40px',
            },
        },
        MuiTab: {
            root: {
                fontWeight: 600,
            },
        },
        MuiButton: {
            root: {
                fontWeight: 600,
            },
        },
    },
    typography: {
        caption: {
            color: 'rgba(0,0,0, 0.54)',
            display: 'block',
        },
        body2: {
            color: 'rgba(0, 0, 0, 0.87)',
        },
        h5: {
            color: 'rgba(0, 0, 0, 0.87)',
        },
        h6: {
            fontWeight: 600,
        },
        subtitle1: {
            lineHeight: '1.5',
        },
        fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    },
});
