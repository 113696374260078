import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    NativeSelect,
} from '@material-ui/core';
import { injectIntl } from 'react-intl';

import Storage from 'services/Storage';
import { changeLocale } from 'containers/LanguageProvider/actions';
import messages from './messages';

import './styles.scss';

class SettingsLocale extends React.PureComponent {

    onChangeLocale(event) {
        const locale = event.target.value;
        let promise;

        if (!locale) {
            promise = Storage.unsetLocale()
                .catch(e => console.log('Unset locale failed', e));
        } else {
            promise = Storage.setLocale(locale)
                .catch(e => console.log('Set locale failed', e));
        }

        promise
            .then(() => Storage.loadLocale())
            .then(() => this.props.onLocaleSelect(Storage.getLocale()));
    }

    render() {
        const { intl } = this.props;

        const selectItems = [
            <option key="" value="">
                {intl.formatMessage({ ...messages.default })}
            </option>,
            <option key="de-CH" value="de-CH">
                {intl.formatMessage({ ...messages['german-ch'] })}
            </option>,
            <option key="de" value="de">
                {intl.formatMessage({ ...messages.german })}
            </option>,
            <option key="en" value="en">
                {intl.formatMessage({ ...messages.english })}
            </option>,
        ];

        return (
            <NativeSelect
                className="locale-select"
                value={this.props.locale}
                onChange={e => this.onChangeLocale(e)}
            >
                {selectItems}
            </NativeSelect>
        );
    }

}

SettingsLocale.propTypes = {
    intl: PropTypes.object,
    locale: PropTypes.string.isRequired,
    onLocaleSelect: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        locale: state.languageProvider.locale,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        onLocaleSelect: locale => dispatch(changeLocale(locale)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SettingsLocale));
