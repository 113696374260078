import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { push } from 'connected-react-router';
import {
    Grid,
    IconButton,
    Divider,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    ListItemSecondaryAction,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core';
import Fade from '@material-ui/core/Fade';

import {
    PinDrop as PinDropIcon,
    AccessTime as AccessTimeIcon,
    Call as CallIcon,
    Map as MapIcon,
    Language as LanguageIcon,
    OpenInBrowser as OpenInBrowserIcon,
    Email as EmailIcon,
    AlternateEmail as EmailAlternativeIcon,
} from '@material-ui/icons';

import FormattedPhoneNumber from 'components/FormattedPhoneNumber';
import MerchantLogo from 'components/MerchantLogo';

import MenuBar from 'containers/MenuBar';

import globalMessages from 'containers/App/messages';

import { loadMerchantDetails as loadMerchantDetailsAction } from './actions';

import messages from './messages';

import {
    DAY_ENUM,
    DAY_ENUM_KEYS_SORTED,
    DAY_MESSAGE_ID,
    getOpeningHoursForDay,
    formatAddress,
    formatHours,
} from '../../utils';

import './styles.scss';

class Details extends React.Component {

    static renderOpeningHours(merchant) {
        return DAY_ENUM_KEYS_SORTED.map(id => {
            const dayMessage = globalMessages[DAY_MESSAGE_ID[id]];
            const hours = getOpeningHoursForDay(id, merchant.openingHours);
            const subText = hours.length > 0
                ? formatHours(hours)
                : <FormattedMessage {...globalMessages.closed} />;

            return (
                <ListItem className="compact-list-item-inset" key={DAY_ENUM[id]}>
                    <Grid container>
                        <Grid item xs={4}>
                            <Typography variant="body2" color="textSecondary">
                                <FormattedMessage {...dayMessage} />
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body2">
                                {subText}
                            </Typography>
                        </Grid>
                    </Grid>
                </ListItem>
            );
        });
    }

    constructor(props) {
        super(props);

        this.state = {
            preorderHelpOpen: false,
        };

        this.resume = this.loadData.bind(this);
        document.addEventListener('resume', this.resume);
        this.handlePreOrderHelpToggle = this.handlePreOrderHelpToggle.bind(this);
    }

    componentDidMount() {
        const from = this.props.location.state && this.props.location.state.from;

        if (!from || !from.pathname.includes('/products/')) {
            this.loadData();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('resume', this.resume);
    }

    loadData() {
        this.props.loadMerchantDetails(this.props.match.params.id);
    }

    handleBackClick() {
        const {
            location,
            dispatch,
            match,
        } = this.props;

        const { id: merchantId } = match.params;
        const { from } = location.state || { from: { pathname: `/products/${merchantId}` } };
        from.state = { from: { pathname: `/merchants/${merchantId}` }, ...from.state };
        dispatch(push(from));
    }

    handlePreOrderHelpToggle() {
        this.setState(prevState => ({
            preorderHelpOpen: !prevState.preorderHelpOpen,
        }));
    }

    render() {
        const {
            merchant,
        } = this.props;
        const {
            preorderHelpOpen,
        } = this.state;

        let content;
        let merchantName = '';

        if (!merchant) {
            content = <div />;
        } else {
            const { address } = merchant;
            const openingHours = Details.renderOpeningHours(merchant);

            const mapQuery = encodeURI([merchant.name, address.street, address.postcode, address.city].join(' '));
            const MapLink = React.forwardRef((props, ref) => (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                    href={`https://www.google.com/maps/?q=${mapQuery}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    {...props}
                    ref={ref}
                />
            ));

            const PhoneLink = React.forwardRef((props, ref) => (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                    href={`tel://+${merchant.phoneNumber}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    {...props}
                    ref={ref}
                />
            ));

            const UriLink = React.forwardRef((props, ref) => (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                    href={merchant.uri}
                    target="_blank"
                    rel="noopener noreferrer"
                    {...props}
                    ref={ref}
                />
            ));

            const MailLink = React.forwardRef((props, ref) => (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                    href={`mailto:${merchant.email}`}
                    rel="noopener noreferrer"
                    {...props}
                    ref={ref}
                />
            ));

            merchantName = merchant.name;

            content = (
                <div>
                    <MerchantLogo isOpen logo={merchant.logo} />
                    {/* "will-change" required for Safari (#281) */}
                    <Fade in style={{ willChange: 'opacity' }}>
                        <div>
                            <Typography align="center" variant="h6" className="list-heading">
                                {merchant.name}
                            </Typography>
                            <Divider />
                            <List>
                                {merchant.description &&
                                    <React.Fragment>
                                        <ListItem className="compact-list-item">
                                            <ListItemText
                                                className="compact-list-item-text"
                                                primaryTypographyProps={{ className: 'break-string' }}
                                                primary={merchant.description}
                                            />
                                        </ListItem>
                                        <Divider />
                                    </React.Fragment>
                                }
                                <ListItem className="compact-list-item">
                                    <ListItemIcon>
                                        <PinDropIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        className="compact-list-item-text"
                                        primary={formatAddress(merchant.address)}
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton component={MapLink}>
                                            <MapIcon color="secondary" />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                                {merchant.uri &&
                                    <React.Fragment>
                                        <ListItem className="compact-list-item">
                                            <ListItemIcon>
                                                <LanguageIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                className="compact-list-item-text"
                                                primaryTypographyProps={{ className: 'truncated' }}
                                                primary={merchant.uri}
                                            />
                                            <ListItemSecondaryAction>
                                                <IconButton component={UriLink}>
                                                    <OpenInBrowserIcon color="secondary" />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <Divider />
                                    </React.Fragment>
                                }
                                {merchant.email &&
                                    <React.Fragment>
                                        <ListItem className="compact-list-item">
                                            <ListItemIcon>
                                                <EmailAlternativeIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                className="compact-list-item-text"
                                                primaryTypographyProps={{ className: 'truncated' }}
                                                primary={merchant.email}
                                            />
                                            <ListItemSecondaryAction>
                                                <IconButton component={MailLink}>
                                                    <EmailIcon color="secondary" />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <Divider />
                                    </React.Fragment>
                                }
                                {merchant.phoneNumber &&
                                    <React.Fragment>
                                        <ListItem className="compact-list-item">
                                            <ListItemIcon>
                                                <CallIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                className="compact-list-item-text"
                                                primary={<FormattedPhoneNumber value={merchant.phoneNumber} />}
                                            />
                                            <ListItemSecondaryAction>
                                                <IconButton component={PhoneLink}>
                                                    <CallIcon color="secondary" />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <Divider />
                                    </React.Fragment>
                                }
                                <ListItem className="compact-list-item">
                                    <ListItemIcon>
                                        <AccessTimeIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        disableTypography
                                        className="compact-list-item-text secondary-text"
                                        primary={
                                            <Typography variant="subtitle1" color="textSecondary">
                                                <FormattedMessage {...messages.openingHours} />
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                <List style={{ paddingTop: 0 }}>
                                    {openingHours}
                                </List>
                            </List>
                        </div>
                    </Fade>
                </div>
            );
        }

        return (
            <div className="merchant-details-page">
                <MenuBar
                    title={merchantName}
                    onBackClick={() => this.handleBackClick()}
                />
                <div className="page-content">
                    {content}
                </div>
                <Dialog
                    open={preorderHelpOpen}
                    onClose={() => this.handlePreorderHelpToggle()}
                >
                    <DialogTitle>
                        <FormattedMessage {...messages.preorderTitle} />
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="body2">
                            <FormattedMessage {...messages.preorderHelp} />
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handlePreOrderHelpToggle} color="primary" autoFocus>
                            <FormattedMessage {...messages.closeDialog} />
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

}

Details.propTypes = {
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.object,
    merchant: PropTypes.object,
    match: PropTypes.object,
    customer: PropTypes.object,
    loggedIn: PropTypes.bool,
    userCoordinates: PropTypes.object,
    loadMerchantDetails: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        merchant: state.merchantDetails.merchant,
        userCoordinates: state.global.coordinates,
        customer: state.customer.customer,
        loggedIn: state.login.loggedIn,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        loadMerchantDetails: id => dispatch(loadMerchantDetailsAction(id)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Details);
