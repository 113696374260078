/**
 * @author RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Route,
    Redirect,
    Switch,
    withRouter,
} from 'react-router-dom';
import { connect } from 'react-redux';

import { API_VERSION } from 'config';

import TermsOfServiceDialog from 'components/TermsOfServiceDialog';

import LanguageProvider from 'containers/LanguageProvider';
import LoadingOverlay from 'containers/LoadingOverlay';
import Login from 'containers/Login';
import Profile from 'containers/Profile';
import Merchants from 'containers/Merchants';
import Products from 'containers/Products';
import ProductOptions from 'containers/ProductOptions';
import MerchantDetails from 'containers/MerchantDetails';
import Snackbar from 'containers/Snackbar';
import Registration from 'containers/Registration';
import Activation from 'containers/Activation';
import PasswordReset from 'containers/PasswordReset';
import PasswordSet from 'containers/PasswordSet';
import Settings from 'containers/Settings';
import ShoppingCart from 'containers/ShoppingCart';
import Orders from 'containers/Orders';
import OrderDetails from 'containers/OrderDetails';
import PushReceiver from 'containers/PushReceiver';
import About from 'containers/About';
import ErrorBoundary from 'containers/ErrorBoundary';
import PaymentMethods from 'containers/PaymentMethods';
import UpgradeScreen from 'components/UpgradeScreen';
import OfflineScreen from 'components/OfflineScreen';
import PrivateRoute from 'components/PrivateRoute';
import { hideSnackbarPageChange as hideSnackbarPageChangeAction } from 'containers/Snackbar/actions';
import { closeMenuDrawer as closeMenuDrawerAction } from 'containers/MenuDrawer/action';
import { logout as logoutAction } from 'containers/Login/actions';

import { fetchCustomerData as fetchCustomerDataAction } from 'containers/Profile/actions';
import {
    initCordova as initCordovaAction,
    agreeTermsOfService as agreeTermsOfServiceAction,
} from './actions';

class App extends React.Component {

    constructor(props) {
        super(props);

        this.handleAcceptClick = this.handleAcceptClick.bind(this);
        this.handleDeclineClick = this.handleDeclineClick.bind(this);
    }

    componentDidMount() {
        const {
            history,
            hideSnackbarPageChange,
            closeMenuDrawer,
            initCordova,
        } = this.props;

        history.listen(() => {
            // hide Snackbar on location change
            hideSnackbarPageChange();
            // always close Menu Drawer on location change
            closeMenuDrawer();
        });

        initCordova(history);
    }

    handleAcceptClick() {
        this.props.agreeTermsOfService();
        this.props.fetchCustomerData();
    }

    handleDeclineClick() {
        this.props.logout();
    }

    render() {
        const {
            location,
            cordovaInitDone,
            isConnected,
            apiVersion,
            loggedIn,
            termsOfServiceCompatible,
            coordinatesUpdated,
            messages,
        } = this.props;

        if (!cordovaInitDone && !coordinatesUpdated) {
            return null;
        }

        if (!isConnected) {
            return (
                <LanguageProvider messages={messages}>
                    <OfflineScreen />
                </LanguageProvider>
            );
        }

        if (apiVersion && apiVersion !== API_VERSION) {
            return (
                <LanguageProvider messages={messages}>
                    <UpgradeScreen />
                </LanguageProvider>
            );
        }

        return (
            <LanguageProvider messages={messages}>
                <ErrorBoundary>
                    <div className="page-wrapper">
                        <Switch location={location}>
                            {/* Private Routes require a login token */}
                            <PrivateRoute loggedIn={loggedIn} exact path="/orders" component={Orders} />
                            <PrivateRoute loggedIn={loggedIn} path="/orders/:id" component={OrderDetails} />
                            <PrivateRoute loggedIn={loggedIn} path="/profile" component={Profile} />
                            <PrivateRoute loggedIn={loggedIn} path="/payment-methods" component={PaymentMethods} />

                            {/* Accessible for all users */}
                            <Route exact path="/(merchants|)" component={Merchants} />
                            <Route path="/merchants/:id" component={MerchantDetails} />
                            <Route path="/products/:id" component={Products} />
                            <Route path="/product-options/:id/:product_id" component={ProductOptions} />
                            <Route path="/cart" component={ShoppingCart} />
                            <Route path="/login" component={Login} />
                            <Route path="/registration" component={Registration} />
                            <Route path="/activation" component={Activation} />
                            <Route path="/password/reset" component={PasswordReset} />
                            <Route path="/password/set" component={PasswordSet} />
                            <Route path="/settings" component={Settings} />
                            <Route path="/about" component={About} />

                            {/* Fallback */}
                            <Redirect to="/" />
                        </Switch>
                    </div>
                    <LoadingOverlay />
                    <Snackbar />
                    <PushReceiver />
                    <TermsOfServiceDialog
                        open={loggedIn && termsOfServiceCompatible === false}
                        onClose={this.handleDeclineClick}
                        onDecline={this.handleDeclineClick}
                        onAccept={this.handleAcceptClick}
                    />
                </ErrorBoundary>
            </LanguageProvider>
        );
    }

}

App.propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    initCordova: PropTypes.func.isRequired,
    cordovaInitDone: PropTypes.bool,
    hideSnackbarPageChange: PropTypes.func.isRequired,
    closeMenuDrawer: PropTypes.func.isRequired,
    isConnected: PropTypes.bool,
    apiVersion: PropTypes.number,
    loggedIn: PropTypes.bool,
    termsOfServiceCompatible: PropTypes.bool,
    agreeTermsOfService: PropTypes.func.isRequired,
    fetchCustomerData: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    coordinatesUpdated: PropTypes.instanceOf(Date),
    messages: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return {
        cordovaInitDone: state.global.cordovaInitDone,
        coordinatesUpdated: state.global.coordinatesUpdated,
        loggedIn: state.login.loggedIn,
        isConnected: state.global.isConnected,
        apiVersion: state.global.apiVersion,
        termsOfServiceCompatible: state.global.termsOfServiceCompatible,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        initCordova: history => dispatch(initCordovaAction(history)),
        hideSnackbarPageChange: () => dispatch(hideSnackbarPageChangeAction()),
        closeMenuDrawer: () => dispatch(closeMenuDrawerAction()),
        agreeTermsOfService: () => dispatch(agreeTermsOfServiceAction()),
        fetchCustomerData: () => dispatch(fetchCustomerDataAction()),
        logout: () => dispatch(logoutAction()),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
