import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';

import './styles.scss';

function LoadingBox({ isVisible }) {
    return (
        <div className={`loading-box ${!isVisible ? 'hidden' : ''}`}>
            <CircularProgress className="loading-box-progress" />
        </div>
    );
}

LoadingBox.propTypes = {
    isVisible: PropTypes.bool.isRequired,
};

export default LoadingBox;
