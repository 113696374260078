import Device from 'services/Device';
import Push from 'services/Push';
import Storage from 'services/Storage';

/**
 * Get the device including the Push token, if we are on a mobile platform.
 * If the current locale or Push token is unknown, undefined is returned.
 * This function is called from the
 *   - login action
 *   - updateDeviceInformation action,
 *   - activation action
 *   - setPassword action
 *   - About page
 * @return {Promise<{model, platform, id, version, manufacturer, token, locale: *}>}
 */
export async function getDeviceWithPushToken() {
    if (Device.isDevice()) {
        try {
            // get current set locale
            const locale = Storage.getLocale();

            // asks for permission on iOS
            const token = await Push.getPushToken();

            if (locale && token) {
                return {
                    ...Device.getDeviceInfo(),
                    token,
                    locale,
                };
            }
        } catch (e) {
            console.error('Error fetching Push token');
            return undefined;
        }
    }

    return undefined;
}
