import { push } from 'connected-react-router';

import { API_URI } from 'config';
import { sanitizePhoneNumber } from 'utils';
import Storage from 'services/Storage';
import Device from 'services/Device';

import { showSnackbar } from 'containers/Snackbar/actions';
import { checkTermsOfService } from 'containers/App/actions';
import {
    unsetCustomerData,
    fetchCustomerData,
} from 'containers/Profile/actions';

import { getDeviceWithPushToken } from '../../device';

import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_ERROR,
} from './constants';
import messages from './messages';

/*
 * ACTION CREATORS
 */

function loginRequest() {
    return {
        type: LOGIN_REQUEST,
    };
}

export function loginSuccess(result) {
    return {
        type: LOGIN_SUCCESS,
        result,
    };
}

function loginError(error) {
    return {
        type: LOGIN_ERROR,
        error,
    };
}

function logoutRequest() {
    return {
        type: LOGOUT_REQUEST,
    };
}

export function logoutSuccess() {
    return {
        type: LOGOUT_SUCCESS,
    };
}

function logoutError(error) {
    return {
        type: LOGOUT_ERROR,
        error,
    };
}

/*
 * DISPATCHERS
 */

/**
 * Set the login state.
 * If the JWT token is given, the token is set in the storage.
 * @param {string|null} token JWT token
 * @returns {function(*)}
 */
export function setLogin(token = null) {
    return dispatch => {
        if (token) {
            Storage.setToken(token)
                .catch(() => console.log('Set token failed: storage unavailable'));
        }
        const result = Storage.getLogin();
        if (result) {
            dispatch(loginSuccess(result));

            dispatch(checkTermsOfService());

            // load the actual order queue after login
            // dispatch(loadOrderQueue());

            dispatch(fetchCustomerData());
        }
    };
}

export function login(areaCode, phoneNumber, password) {
    return async dispatch => {
        dispatch(loginRequest());

        const device = await getDeviceWithPushToken();
        const phoneNumberWithAreaCode = `${areaCode}${sanitizePhoneNumber(phoneNumber)}`;
        const url = new URL('/api/login/customer', API_URI).toString();
        const payload = {
            phoneNumber: phoneNumberWithAreaCode,
            password,
            device,
        };

        fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json()
                        .then(json => Promise.reject(json));
                }
            })
            .then(json => {
                dispatch(setLogin(json.token));
                dispatch(showSnackbar(messages.loginSuccess));
            })
            .catch(error => {
                dispatch(loginError(error));
                dispatch(showSnackbar(messages.loginError));
            });
    };
}

export function logout() {
    return dispatch => {
        dispatch(logoutRequest);

        const url = new URL('/api/logout/customer', API_URI).toString();
        const payload = {};

        if (Device.isDevice()) {
            payload.device = {
                id: Device.getDeviceInfo().id,
            };
        }

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${Storage.getToken()}`,
            },
            body: JSON.stringify(payload),
        })
            .then(response => {
                // response 200 - Ok
                // response 401 - Unauthorized - Token invalid
                if (response.ok || response.status === 401) {
                    return Storage.unsetToken()
                        .catch(() => console.log('Unset token failed: storage unavailable'));
                } else {
                    return Promise.reject();
                }
            })
            .then(() => {
                dispatch(logoutSuccess());
                dispatch(showSnackbar(messages.logoutSuccess));
                dispatch(unsetCustomerData());
                dispatch(push('/login'));
            })
            .catch(error => {
                dispatch(logoutError(error));
            });
    };
}
