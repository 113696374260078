import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import {
    Divider,
    Grid,
    Typography,
    Button,
} from '@material-ui/core';
import {
    Smartphone as SmartphoneIcon,
    Place as PlaceIcon,
} from '@material-ui/icons';

import FormattedPhoneNumber from 'components/FormattedPhoneNumber';

import messages from './messages';

function MerchantSummary({ merchant, location }) {
    return (
        <div className="merchant-summary">
            <Typography variant="h6" className="page">
                {merchant.name}
            </Typography>
            <Grid container justify="center" alignItems="center" spacing={0} className="page">
                <Grid item xs={9} className="icon-label-wrapper ">
                    <PlaceIcon />
                    <Typography variant="body2" className="">
                        {`${merchant.address.street}`}, {`${merchant.address.postcode} ${merchant.address.city}`}
                    </Typography>
                </Grid>
                <Grid item xs className="align-right">
                    <Button
                        component={Link}
                        to={{ pathname: `/merchants/${merchant._id}`, state: { from: location } }}
                        color="primary"
                        className="link-plain"
                    >
                        Details
                    </Button>
                </Grid>
            </Grid>
            <Divider />
            {merchant.phoneNumber &&
                <Grid container justify="center" alignItems="center" spacing={0} className="page">
                    <Grid item xs={4} className="icon-label-wrapper">
                        <SmartphoneIcon />
                        <Typography variant="body2" align="left" className="phone-number">
                            <FormattedMessage {...messages.phoneNumber} />
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body2" align="right">
                            <FormattedPhoneNumber value={merchant.phoneNumber} />
                        </Typography>
                    </Grid>
                </Grid>
            }
        </div>
    );
}

MerchantSummary.propTypes = {
    location: PropTypes.object,
    merchant: PropTypes.object.isRequired,
};

export default withRouter(MerchantSummary);
