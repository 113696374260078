import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

function createReducer(reducers, history) {
    return combineReducers({
        ...reducers,
        router: connectRouter(history),
    });
}

export default createReducer;
