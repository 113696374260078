import {
    START_POLLING,
    STOP_POLLING,
} from 'middlewares/polling';
import {
    LOAD_ORDER_GROUPS_REQUEST,
    LOAD_ORDER_GROUPS_SUCCESS,
    LOAD_ORDER_GROUPS_ERROR,
    LOAD_ORDER_GROUP_STATE_SUCCESS,
} from './constants';

const initialState = {
    loading: false,
    error: null,
    orderGroups: null,
    polling: undefined,
};

function ordersReducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_ORDER_GROUPS_REQUEST:
        case LOAD_ORDER_GROUPS_SUCCESS:
            return {
                ...state,
                loading: false,
                orderGroups: action.result,
                error: null,
            };
        case LOAD_ORDER_GROUPS_ERROR:
        case LOAD_ORDER_GROUP_STATE_SUCCESS: {
            const orderGroups = state.orderGroups.map(og => {
                if (og._id === action.result._id) {
                    const orders = og.orders.map(o => ({
                        ...o,
                        state: action.result.state.find(s => s.orderId === o._id).orderState,
                        paymentState: action.result.state.find(s => s.orderId === o._id).orderPaymentState,
                    }));

                    return {
                        ...og,
                        paymentState: action.result.paymentState,
                        orders,
                    };
                }

                return og;
            });

            return {
                ...state,
                orderGroups,
            };
        }
        case START_POLLING:
            return {
                ...state,
                polling: action.id,
            };
        case STOP_POLLING:
            return {
                ...state,
                polling: undefined,
            };
        default:
            return state;
    }
}

export default ordersReducer;
