class Deeplink {

    static init(history) {
        document.addEventListener('deviceready', () => {
            try {
                const deeplink = Deeplink.getPlugin();
                deeplink.onDeepLink(data => {
                    const { url, fragment } = data;
                    console.log(`Deeplink: onDeeplink called with url=${url} fragment=${fragment}`);
                    history.push(fragment);
                });

                console.log('Deeplink: service initialized');

            } catch (err) {
                console.log(`Deeplink: Error initializing service: ${err}`);
            }
        });
    }

    /**
     * Get the Cordova Deeplink plugin instance.
     * @returns {null|*}
     */
    static getPlugin() {
        if (window.cordova
            && (window.cordova.platformId === 'ios' || window.cordova.platformId === 'android')
            && window.IonicDeeplink) {
            return window.IonicDeeplink;
        } else {
            throw Error(`Deeplink: Plugin for platform ${window.cordova.platformId} not available`);
        }
    }

}

export default Deeplink;
