import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Snackbar } from '@material-ui/core';

import CartContentWrapper from './CartContentWrapper';

class HoverCart extends React.Component { // eslint-disable-line react/prefer-stateless-function

    render() {
        const {
            hover,
            totalCount,
            totalAmount,
            location,
            locale,
        } = this.props;

        return (
            <Snackbar
                className="hover-cart-wrapper"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={hover}
                onClose={this.handleClose}
            >
                <CartContentWrapper
                    classes={{
                        root: 'hover-cart',
                    }}
                    size={totalCount}
                    value={totalAmount}
                    from={location.pathname}
                    locale={locale}
                />
            </Snackbar>
        );
    }

}

HoverCart.propTypes = {
    location: PropTypes.object.isRequired,
    hover: PropTypes.bool,
    totalCount: PropTypes.number.isRequired,
    totalAmount: PropTypes.number.isRequired,
    locale: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
    return {
        location: state.router.location,
        totalCount: state.shoppingCart.totalCount,
        totalAmount: state.shoppingCart.totalAmount,
        hover: state.shoppingCart.hover,
        locale: state.languageProvider.locale,
    };
}

export default connect(mapStateToProps)(HoverCart);
