import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

function MultilineText({ text }) {
    return <span className="multilinetext">{text}</span>;
}

MultilineText.propTypes = {
    text: PropTypes.string.isRequired,
};

export default MultilineText;
