import { push } from 'connected-react-router';
import Storage from 'services/Storage';
import { showSnackbar } from 'containers/Snackbar/actions';
import {
    PASSWORD_RESET_REQUEST,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_ERROR,
    SET_PHONE_NUMBER,
} from './constants';
import messages from './messages';
import { API_URI } from '../../config';

/*
 * ACTION CREATORS
 */

function passwordResetRequest(areaCode, phoneNumber) {
    return {
        type: PASSWORD_RESET_REQUEST,
        areaCode,
        phoneNumber,
    };
}

function passwordResetSuccess(result) {
    return {
        type: PASSWORD_RESET_SUCCESS,
        result,
    };
}

function passwordResetError(error) {
    return {
        type: PASSWORD_RESET_ERROR,
        error,
    };
}

export function setPhoneNumber(areaCode, phoneNumber) {
    return {
        type: SET_PHONE_NUMBER,
        areaCode,
        phoneNumber,
    };
}

/*
 * DISPATCHERS
 */

export function resetPassword(areaCode, phoneNumber) {
    return dispatch => {
        dispatch(passwordResetRequest(areaCode, phoneNumber));

        const phoneNumberWithAreaCode = `${areaCode}${phoneNumber}`;
        const url = new URL('/api/public/customer/reset', API_URI).toString();
        const payload = {
            phoneNumber: phoneNumberWithAreaCode,
            locale: Storage.getLocale(),
        };

        fetch(url, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
        })
            .then(response => {
                if (response.ok) {
                    return Promise.resolve();
                } else {
                    return response.json()
                        .then(json => Promise.reject(json));
                }
            })
            .then(() => {
                dispatch(passwordResetSuccess());
                dispatch(showSnackbar(messages.confirmationCodeSent));

                // navigate to password assign page
                dispatch(push('/password/set'));
            })
            .catch(error => {
                dispatch(passwordResetError(error));

                if (error.message.toLowerCase() === 'too many reset password attempts') {
                    dispatch(showSnackbar(messages.tooManyPasswordResetAttempts));
                } else {
                    dispatch(showSnackbar(messages.resetError));
                }
            });
    };
}
