/**
 * @author RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { TextField } from '@material-ui/core';

import messages from './messages';

class InputCity extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            city: props.city || '',
            cityLabel: messages.city,
            cityError: false,
            cityDirty: false,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.city && prevState.city !== nextProps.city) {
            return {
                ...prevState,
                city: nextProps.city,
            };
        }
        return null;
    }

    onChangeCity(event) {
        const val = event.target.value;
        this.setStateCity(val, this.state.cityDirty);
        this.props.onChange(val);
    }

    onBlurCity(event) {
        const val = event.target.value;
        this.setStateCity(val, true);
    }

    /**
     * Validates the city, and sets the state for it.
     * @param {string} val The value of the city input.
     * @param {boolean} dirty Flag if the input has already lost the focus once.
     * @return {boolean} True if valid, otherwise false.
     */
    setStateCity(val, dirty = false) {
        const city = val.trim();
        let cityLabel = messages.city;
        let cityError = false;

        if (dirty && !val) {
            cityLabel = messages.cityRequired;
            cityError = true;
        } else if (dirty && val.length < 1) {
            cityLabel = messages.cityInvalid;
            cityError = true;
        } else if (dirty && val.length > 75) {
            cityLabel = messages.cityInvalid;
            cityError = true;
        }

        this.setState({
            city,
            cityLabel,
            cityError,
            cityDirty: dirty,
        });

        return !cityError;
    }

    validate() {
        return this.setStateCity(this.state.city, true);
    }

    render() {
        return (
            <TextField
                required
                fullWidth
                autoComplete="new-city"
                id="city"
                type="text"
                value={this.state.city}
                label={<FormattedMessage {...this.state.cityLabel} />}
                error={this.state.cityError}
                onChange={e => this.onChangeCity(e)}
                InputProps={{
                    onBlur: e => this.onBlurCity(e),
                }}
                margin="normal"
            />
        );
    }

}

InputCity.propTypes = {
    city: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

export default InputCity;
