import { defineMessages } from 'react-intl';

const messages = defineMessages({
    reset: {
        id: 'Login.reset',
        defaultMessage: 'Reset Password',
        description: 'Label for the rest password button',
    },
    resetPassword: {
        id: 'Login.resetPassword',
        defaultMessage: 'Lost your password?',
    },
    register: {
        id: 'Login.register',
        defaultMessage: 'Register Now',
        description: 'Label for the registration button',
    },
    registerNow: {
        id: 'Login.registerNow',
        defaultMessage: 'Not registered yet?',
    },
    loginSuccess: {
        id: 'Login.loginSuccess',
        defaultMessage: 'You are now logged in',
    },
    loginError: {
        id: 'Login.loginError',
        defaultMessage: 'Error during login',
    },
    logoutSuccess: {
        id: 'Login.logoutSuccess',
        defaultMessage: 'You are now logged out',
    },
});

export default messages;
