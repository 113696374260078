import { pushNotificationReceive as pushNotificationReceiveAction } from 'containers/App/actions';

class Push {

    /**
     * Initializes the Firebase service.
     */
    static init(dispatch) { // eslint-disable-line no-unused-vars
        document.addEventListener('deviceready', () => {
            try {
                const FirebasePlugin = Push.getPlugin();

                Push.initializeFirebase()
                    .then(() => {
                        FirebasePlugin.onNotificationOpen(
                            result => {
                                console.log(`Push: notification received: ${JSON.stringify(result)}`);
                                dispatch(pushNotificationReceiveAction(result));
                            },
                            err => {
                                console.log(`Push: Error receiving push notification: ${err}`);
                            }
                        );

                        console.log('Push: service initialized');
                    })
                    .catch(err => {
                        console.log(`Push: Error initializing FirebasePlugin: ${err}`);
                    });
            } catch (err) {
                console.log(`Push: Error initializing service: ${err}`);
            }
        });
    }

    /**
     * Get the Cordova FirebasePlugin object.
     * @returns {null|Object}
     */
    static getPlugin() {
        const { cordova, FirebasePlugin } = window;
        if (cordova
            && (cordova.platformId === 'ios' || cordova.platformId === 'android')
            && FirebasePlugin) {
            return FirebasePlugin;
        } else {
            throw Error(`FirebasePlugin for platform ${cordova.platformId} not available`);
        }
    }

    /**
     * Get the Push token, asking for permission if required.
     * @returns {Promise}
     */
    static getPushToken() {
        const { cordova } = window;

        if (cordova.platformId === 'ios') {
            return Push
                .hasPermission()
                .then(data => {
                    if (!data.isEnabled) {
                        return Push
                            .grantPermission()
                            .then(() => Push.getToken());
                    } else {
                        return Push.getToken();
                    }
                });
        } else {
            return Push.getToken();
        }
    }

    /**
     * Promisify FirebasePlugin.initFirebase().
     * @return {Promise<any>}
     */
    static initializeFirebase() {
        return new Promise((resolve, reject) => {
            try {
                const FirebasePlugin = Push.getPlugin();
                FirebasePlugin.initFirebase(
                    result => resolve(result),
                    error => reject(error),
                );
            } catch (err) {
                reject(err);
            }
        });
    }

    /**
     * Promisify FirebasePlugin.hasPermission().
     * @return {Promise<any>}
     */
    static hasPermission() {
        return new Promise((resolve, reject) => {
            try {
                const FirebasePlugin = Push.getPlugin();
                FirebasePlugin.hasPermission(
                    result => resolve(result),
                    error => reject(error),
                );
            } catch (err) {
                reject(err);
            }
        });
    }

    /**
     * Promisify FirebasePlugin.grantPermission().
     * @return {Promise<any>}
     */
    static grantPermission() {
        return new Promise((resolve, reject) => {
            try {
                const FirebasePlugin = Push.getPlugin();
                FirebasePlugin.grantPermission(
                    result => resolve(result),
                    error => reject(error),
                );
            } catch (err) {
                reject(err);
            }
        });
    }

    /**
     * Promisify FirebasePlugin.getToken().
     * @return {Promise<any>}
     */
    static getToken() {
        return new Promise((resolve, reject) => {
            try {
                const FirebasePlugin = Push.getPlugin();
                FirebasePlugin.onTokenRefresh(
                    result => {
                        console.log(`Push: push token received: ${result.substring(0, 15)}…`);
                        resolve(result);
                    },
                    error => reject(error),
                );
            } catch (err) {
                reject(err);
            }
        });
    }

}

export default Push;
