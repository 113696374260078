/**
 * @author RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
    Grid,
    Button,
    Typography,
    CircularProgress,
    Card,
    CardContent,
    CardActions,
} from '@material-ui/core';

import MenuBar from 'containers/MenuBar';
import InputPhoneNumber from 'components/InputPhoneNumber';
import { resetPassword as resetPasswordAction } from './actions';
import messages from './messages';

class PasswordReset extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            areaCode: '41',
            phoneNumber: props.phoneNumber,
        };
    }

    onChangePhoneNumber(phoneNumber) {
        this.setState({ phoneNumber });
    }

    onBackClick() {
        this.props.dispatch(push('/login'));
    }

    doResetPassword() {
        const valid = this.inputPhoneNumber.validate();

        if (valid) {
            this.props.resetPassword(this.state.areaCode, this.state.phoneNumber);
        }
    }

    render() {
        if (this.props.loggedIn) {
            return <Redirect to="/merchants" />;
        }

        return (
            <div className="password-reset-page logistration-page">
                <MenuBar titleMessage={messages.title} onBackClick={() => this.onBackClick()} />
                <div className="page-content">
                    <Grid container justify="center" spacing={0} className="page">
                        <Grid item xs={12} sm={8}>
                            <Card variant="outlined" style={{ border: 0 }}>
                                <CardContent>
                                    <Typography variant="h6" align="center">
                                        <FormattedMessage {...messages.subtitle} />
                                    </Typography>
                                    <form noValidate autoComplete="off">
                                        <InputPhoneNumber
                                            ref={input => { this.inputPhoneNumber = input; }}
                                            phoneNumber={this.state.phoneNumber}
                                            onChange={val => this.onChangePhoneNumber(val)}
                                        />
                                    </form>
                                </CardContent>
                                <CardActions style={{ justifyContent: 'center' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={this.props.loading}
                                        onClick={() => this.doResetPassword()}
                                    >
                                        {this.props.loading && (
                                            <React.Fragment>
                                                <CircularProgress color="inherit" size="1rem" />
                                                &nbsp;
                                            </React.Fragment>
                                        )}
                                        <FormattedMessage {...messages.reset} />
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }

}

PasswordReset.propTypes = {
    loggedIn: PropTypes.bool.isRequired,
    areaCode: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string,
    loading: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    resetPassword: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        loggedIn: state.login.loggedIn,
        areaCode: state.passwordReset.areaCode,
        phoneNumber: state.passwordReset.phoneNumber,
        loading: state.passwordReset.loading,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        resetPassword: (areaCode, phoneNumber) => dispatch(resetPasswordAction(areaCode, phoneNumber)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
