import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons';

function InfoButton({ onClick }) {
    return (
        <IconButton color="inherit" onClick={onClick} data-testid="menu-info">
            <InfoOutlinedIcon />
        </IconButton>
    );
}

InfoButton.propTypes = {
    onClick: PropTypes.func,
};

export default InfoButton;
