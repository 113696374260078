import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import MenuBar from 'containers/MenuBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from '@rise/react-swipeable-views';

import globalMessages from 'containers/App/messages';
import TermsOfServiceRef from 'components/TermsOfServiceRef';

import Device from '../../services/Device';
import Push from '../../services/Push';

import messages from './messages';
import './styles.scss';
import { API_URI } from '../../config';
import { getDeviceWithPushToken } from '../../device';

/*
 * webpack.DefinePlugin will inject the variable '__VERSION__' and '__BUILD_TARGET__'.
 * To change it, the app needs to be recompiled.
 */
const injectedVersion = __VERSION__; // eslint-disable-line no-undef
const buildTarget = __BUILD_TARGET__; // eslint-disable-line no-undef

class About extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedTab: 0,
            pushToken: null,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeIndex = this.handleChangeIndex.bind(this);
    }

    componentDidMount() {
        if (Device.isDevice()) {
            // show the Push token only if we have already the permission of the operating system
            Push.hasPermission()
                .then(data => {
                    if (data.isEnabled) {
                        return getDeviceWithPushToken();
                    } else {
                        return Promise.reject();
                    }
                })
                .then(device => this.setState({ pushToken: device ? device.token : '' }))
                .catch(() => console.error('Error fetching Push token -- Push token not available'));
        }
    }

    handleChange(event, selectedTab) {
        this.setState({ selectedTab });
    }

    handleChangeIndex(index) {
        this.setState({ selectedTab: index });
    }

    render() {
        const { selectedTab, pushToken } = this.state;

        return (
            <div className="about-page">
                <MenuBar
                    titleMessage={globalMessages.about}
                />
                <div className="page-content">
                    <AppBar position="sticky" className="sticky-app-bar">
                        <Tabs
                            value={selectedTab}
                            onChange={this.handleChange}
                            variant="fullWidth"
                        >
                            <Tab label={<FormattedMessage {...messages.general} />} />
                            <Tab label={<FormattedMessage {...messages.imprint} />} />
                        </Tabs>
                    </AppBar>
                    <SwipeableViews
                        index={selectedTab}
                        onChangeIndex={this.handleChangeIndex}
                        adjustHeight
                    >
                        <VersionContainer pushToken={pushToken} />
                        <ImpressumContainer />
                    </SwipeableViews>
                </div>
            </div>
        );
    }

}

function VersionContainer({ pushToken }) {
    const { id: deviceId } = Device.getDeviceInfo();

    return (
        <div className="about-tab-container">
            <Typography variant="body2" paragraph>
                <FormattedMessage
                    {...messages.considerTermsOfService}
                    values={{
                        termsOfService: <TermsOfServiceRef />,
                    }}
                />.
            </Typography>

            <Typography variant="body2" paragraph>
                <FormattedMessage {...messages.contactInfo} />
            </Typography>
            <ul>
                <li>
                    <Typography variant="body2">
                        <FormattedMessage {...messages.byEmail} />:&nbsp;
                        <a
                            href="mailto:market@mybuxi.ch"
                            className="external-link"
                        >
                            market@mybuxi.ch
                        </a>
                    </Typography>
                </li>
                <li>
                    <Typography variant="body2">
                        <FormattedMessage {...messages.byPhone} />:&nbsp;
                        +41 78 847 38 98
                    </Typography>
                </li>
            </ul>

            <Typography variant="body2" paragraph>
                <strong><FormattedMessage {...messages.version} />:</strong> {injectedVersion}
            </Typography>

            { buildTarget === 'debug' &&
            <Typography variant="body2" paragraph>
                <strong>API URI:</strong> {API_URI}
            </Typography>}

            { buildTarget === 'debug' &&
            <Typography variant="body2" paragraph>
                <strong>Device Identifier:</strong> {deviceId || '-'}
            </Typography>}

            { buildTarget === 'debug' &&
            <Typography variant="body2" paragraph>
                <strong>Push Token:</strong> {pushToken ? `${pushToken.substring(0, 15)}…` : '-'}
            </Typography>}

            <Typography variant="body2" paragraph>
                Powered by&nbsp;
                <a
                    href="https://www.rise-world.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="external-link"
                >
                    RISE
                </a>
            </Typography>
        </div>
    );
}

VersionContainer.propTypes = {
    pushToken: PropTypes.string,
};

function ImpressumContainer() {
    return (
        <div className="about-tab-container">
            <Typography variant="body2" paragraph>
                mybuxi ist ein Service der Kronawitter Innovation GmbH.
            </Typography>
            <Typography variant="body2" paragraph>
                Kronawitter Innovation GmbH<br />
                Andreas Kronawitter<br />
                Geschäftsführer<br />
                Hessstrasse 18<br />
                CH-3097 Liebefeld
            </Typography>
            <br />
            <Typography variant="body2" paragraph>
                Tel: +41 79 948 94 30
                <br />
                Mail: <a href="mailto:andreas.kronawitter@mybuxi.ch">andreas.kronawitter@mybuxi.ch</a>
                <br />
                <br />
                Website: <a href="https://www.mybuxi.ch" target="_blank" rel="noopener noreferrer">www.mybuxi.ch</a>
            </Typography>
        </div>
    );
}

export default About;
