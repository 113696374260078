/**
 * @author RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
import React from 'react';
import PropTypes from 'prop-types';

import InputPhoneNumber from '../InputPhoneNumber';
import InputPassword from '../InputPassword';

class AuthFormFields extends React.PureComponent {

    validate() {
        const phoneNumberValid = this.inputPhoneNumber.validate();
        const passwordValid = this.inputPassword.validate();
        return phoneNumberValid && passwordValid;
    }

    render() {
        return (
            <React.Fragment>
                <InputPhoneNumber
                    ref={input => { this.inputPhoneNumber = input; }}
                    phoneNumber={this.props.phoneNumber}
                    onBlur={val => this.props.onBlurPhoneNumber(val)}
                    onChange={val => this.props.onChangePhoneNumber(val)}
                />
                <InputPassword
                    ref={input => { this.inputPassword = input; }}
                    onChange={val => this.props.onChangePassword(val)}
                />
            </React.Fragment>
        );
    }

}

AuthFormFields.propTypes = {
    // initial phone number value
    phoneNumber: PropTypes.string.isRequired,
    onBlurPhoneNumber: PropTypes.func.isRequired,
    // triggered on change
    onChangePhoneNumber: PropTypes.func.isRequired,
    onChangePassword: PropTypes.func.isRequired,
};

export default AuthFormFields;
