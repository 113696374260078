import { defineMessages } from 'react-intl';

const messages = defineMessages({
    header: {
        id: 'ErrorBoundary.header',
        defaultMessage: 'Something went wrong',
    },
    body: {
        id: 'ErrorBoundary.body',
        defaultMessage: 'An unexpected error occured. Please reload the app and try again.',
    },
    reload: {
        id: 'ErrorBoundary.reload',
        defaultMessage: 'Reload',
    },
});

export default messages;
