import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

function formatPhoneNumber(value) {
    try {
        // The Swiss are used to the following formatting: +44 12 345 12 12 ([code] [2] [3] [2] [2])
        // eslint-disable-next-line max-len
        return `+${value.substr(0, 2)} ${value.substr(2, 2)} ${value.substr(4, 3)} ${value.substr(7, 2)} ${value.substr(9)}`;
    } catch (e) {
        return value;
    }
}

function FormattedPhoneNumber(props) {
    return (
        <span className="phone-number">{formatPhoneNumber(props.value)}</span>
    );
}

FormattedPhoneNumber.propTypes = {
    value: PropTypes.string.isRequired,
};

export default FormattedPhoneNumber;
