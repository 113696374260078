export const LOAD_ORDER_GROUPS_REQUEST = 'Orders/LOAD_ORDER_GROUPS_REQUEST';
export const LOAD_ORDER_GROUPS_SUCCESS = 'Orders/LOAD_ORDER_GROUPS_SUCCESS';
export const LOAD_ORDER_GROUPS_ERROR = 'Orders/LOAD_ORDER_GROUPS_ERROR';

export const CANCEL_ORDER_GROUP_REQUEST = 'Orders/CANCEL_ORDER_GROUP_REQUEST';
export const CANCEL_ORDER_GROUP_SUCCESS = 'Orders/CANCEL_ORDER_GROUP_SUCCESS';
export const CANCEL_ORDER_GROUP_ERROR = 'Orders/CANCEL_ORDER_GROUP_ERROR';

export const LOAD_ORDER_GROUP_STATE_REQUEST = 'Orders/LOAD_ORDER_GROUP_STATE_REQUEST';
export const LOAD_ORDER_GROUP_STATE_SUCCESS = 'Orders/LOAD_ORDER_GROUP_STATE_SUCCESS';
export const LOAD_ORDER_GROUP_STATE_ERROR = 'Orders/LOAD_ORDER_GROUP_STATE_ERROR';

export const PAYMENT_STATE = Object.freeze({
    pending: 'PENDING',
    successful: 'SUCCESSFUL',
});
