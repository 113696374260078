import { defineMessages } from 'react-intl';

const messages = defineMessages({
    postcode: {
        id: 'InputPostcode.postcode',
        defaultMessage: 'Postcode',
    },
    postcodeRequired: {
        id: 'InputPostcode.postcodeRequired',
        defaultMessage: 'Postcode required',
    },
    postcodeInvalid: {
        id: 'InputPostcode.postcodeInvalid',
        defaultMessage: 'Postcode invalid',
    },
});

export default messages;
