import { defineMessages } from 'react-intl';

const messages = defineMessages({
    openOrders: {
        id: 'Orders.openOrders',
        defaultMessage: 'Open orders',
    },
    previousOrders: {
        id: 'Orders.previousOrders',
        defaultMessage: 'Previous orders',
    },
    noOrdersAvailable: {
        id: 'Orders.noOrdersAvailable',
        defaultMessage: 'No orders available',
    },
    canceled: {
        id: 'Orders.canceled',
        defaultMessage: 'Canceled',
    },
    aborted: {
        id: 'Orders.aborted',
        defaultMessage: 'Aborted',
    },
    resumePayment: {
        id: 'Orders.resumePayment',
        defaultMessage: 'Resume payment',
    },
    cancelOrderGroup: {
        id: 'Orders.cancelOrderGroup',
        defaultMessage: 'Cancel order',
    },
    confirmCancelTitle: {
        id: 'Orders.confirmCancelTitle',
        defaultMessage: 'Cancel order?',
    },
    confirmCancelContent: {
        id: 'Orders.confirmCancelContent',
        defaultMessage: 'Do you really want to cancel this order?',
    },
});

export default messages;
