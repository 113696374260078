import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import {
    PinDrop as PinDropIcon,
    Smartphone as SmartphoneIcon,
} from '@material-ui/icons';

import MerchantLogo from 'components/MerchantLogo';
import FormattedPhoneNumber from 'components/FormattedPhoneNumber';

import {
    formatAddress,
} from '../../utils';

import './styles.scss';

function MerchantBox({ merchant }) {
    const open = true;

    return (
        <div className={`merchant-box ${open ? 'open' : 'closed'}`} data-testid="merchant-box">
            {/* <MerchantLogo isOpen={open} hoursCurrent={hoursCurrent} logo={merchant.logo} /> */}
            <MerchantLogo isOpen={open} logo={merchant.logo} />
            <div className="merchant-box-content">
                <div className="merchant-box-title-wrapper">
                    <Typography variant="h6">
                        {merchant.name}
                    </Typography>
                </div>
                {merchant.description &&
                    <div className="merchant-box-content-entry break-string">
                        <Typography variant="body2">
                            {merchant.description}
                        </Typography>
                    </div>
                }
                <div className="merchant-box-content-entry">
                    <PinDropIcon className="merchant-box-icon secondary-icon" />
                    <Typography variant="body2">
                        {formatAddress(merchant.address)}
                    </Typography>
                </div>
                {merchant.phoneNumber &&
                    <div className="merchant-box-content-entry">
                        <SmartphoneIcon className="merchant-box-icon secondary-icon" />
                        <Typography variant="body2">
                            <FormattedPhoneNumber value={merchant.phoneNumber} />
                        </Typography>
                    </div>
                }
            </div>
        </div>
    );
}
/* eslint-enable */

MerchantBox.propTypes = {
    merchant: PropTypes.object.isRequired,
};

export default MerchantBox;
