import { defineMessages } from 'react-intl';

const messages = defineMessages({
    general: {
        id: 'About.general',
        defaultMessage: 'Info',
    },
    imprint: {
        id: 'About.imprint',
        defaultMessage: 'Imprint',
    },
    version: {
        id: 'About.version',
        defaultMessage: 'App version',
    },
    considerTermsOfService: {
        id: 'About.usageNotice',
        defaultMessage: 'To use mybuxi please consider the {termsOfService}',
    },
    contactInfo: {
        id: 'About.contactInfo',
        defaultMessage: 'If you have any questions or suggestions please contact us',
    },
    byEmail: {
        id: 'About.perEmail',
        defaultMessage: 'by E-Mail',
    },
    byPhone: {
        id: 'About.byPhone',
        defaultMessage: 'by phone',
    },
});

export default messages;
