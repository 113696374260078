import {
    DRAWER_OPEN,
    DRAWER_CLOSE,
} from './constants';

/*
 * ACTION CREATORS
 */

function drawerOpen() {
    return {
        type: DRAWER_OPEN,
    };
}

export function drawerClose() {
    return {
        type: DRAWER_CLOSE,
    };
}

/*
 * DISPATCHERS
 */

export function openMenuDrawer() {
    return dispatch => dispatch(drawerOpen());
}

export function closeMenuDrawer() {
    return dispatch => dispatch(drawerClose());
}
