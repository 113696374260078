import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { injectIntl, FormattedMessage } from 'react-intl';
import {
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Divider,
    CircularProgress,
} from '@material-ui/core';
import { Delete as DeleteIcon, CreditCard as CreditCardIcon } from '@material-ui/icons';

import MenuBar from 'containers/MenuBar';
import ConfirmDialog from 'components/ConfirmDialog';

import {
    loadPaymentMethods as loadPaymentMethodsAction,
    deletePaymentMethod as deletePaymentMethodAction,
} from './actions';
import messages from './messages';

function PaymentMethods({
    intl,
    paymentMethods,
    loading,
    deletingId,
    dispatch,
    loadPaymentMethods,
    deletePaymentMethod,
}) {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [paymentMethodToDelete, setPaymentMethodToDelete] = useState(null);

    useEffect(() => {
        loadPaymentMethods();
    }, []);

    const handleBackClick = () => {
        dispatch(push('/merchants'));
    };

    const openDialog = paymentMethodId => {
        setPaymentMethodToDelete(paymentMethodId);
        setIsDialogOpen(true);
    };

    const handleDelete = () => {
        deletePaymentMethod(paymentMethodToDelete);
        setPaymentMethodToDelete(null);
        setIsDialogOpen(false);
    };

    const renderPaymentMethods = () => paymentMethods.map(pm => (
        <React.Fragment key={pm.id}>
            <ListItem>
                <ListItemIcon>
                    <CreditCardIcon />
                </ListItemIcon>
                <ListItemText
                    primary={pm.displayBrand}
                    secondary={
                        `${pm.displayCardNumber}, ${intl.formatMessage(messages.validUntil)} ${pm.displayExpDate}`
                    }
                />
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={() => openDialog(pm.id)}>
                        {deletingId === pm.id
                            ? <CircularProgress size={24} color="inherit" />
                            : <DeleteIcon />
                        }
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <Divider />
        </React.Fragment>
    ));

    return (
        <div>
            <MenuBar
                titleMessage={messages.storedPaymentMethods}
                onBackClick={() => handleBackClick()}
            />
            <div className="page-content">
                <List>
                    {paymentMethods.length <= 0 && !loading
                        ? (
                            <ListItem>
                                <Typography>
                                    <FormattedMessage {...messages.noPaymentMethodsStored} />
                                </Typography>
                            </ListItem>
                        )
                        : renderPaymentMethods()
                    }
                </List>
            </div>
            <ConfirmDialog
                onClose={() => setIsDialogOpen(false)}
                open={isDialogOpen}
                titleMessage={messages.deletePaymentMethodDialogTitle}
                contentMessage={messages.deletePaymentMethodDialogContent}
                confirmMessage={messages.deletePaymentMethodDialogConfirm}
                onCancelClick={() => setIsDialogOpen(false)}
                onConfirmClick={handleDelete}
            />
        </div>
    );
}

PaymentMethods.propTypes = {
    // intl
    intl: PropTypes.object.isRequired,

    // redux state
    paymentMethods: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    deletingId: PropTypes.string.isRequired,

    // redux dispatch
    dispatch: PropTypes.func.isRequired,
    loadPaymentMethods: PropTypes.func.isRequired,
    deletePaymentMethod: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        paymentMethods: state.paymentMethods.paymentMethods,
        loading: state.paymentMethods.loading,
        deletingId: state.paymentMethods.deletingId,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({
            loadPaymentMethods: loadPaymentMethodsAction,
            deletePaymentMethod: deletePaymentMethodAction,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PaymentMethods));
