import {
    LOAD_PRODUCTS_REQUEST,
    LOAD_PRODUCTS_SUCCESS,
    LOAD_PRODUCTS_ERROR,
} from './constants';

export function loadProductsRequest() {
    return {
        type: LOAD_PRODUCTS_REQUEST,
    };
}

export function loadProductsSuccess(result) {
    return {
        type: LOAD_PRODUCTS_SUCCESS,
        result,
    };
}

export function loadProductsError(error) {
    return {
        type: LOAD_PRODUCTS_ERROR,
        error,
    };
}
