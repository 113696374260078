/**
 * @author RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
    TextField,
    IconButton,
    InputAdornment,
} from '@material-ui/core';
import {
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';

import messages from './messages';

class InputPassword extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            password: '',
            passwordLabel: props.messageName,
            passwordError: false,
            passwordDirty: false,
            passwordVisible: false,
        };
    }

    onChangePassword(event) {
        const val = event.target.value;
        this.setStatePassword(val, this.state.passwordDirty);
        this.props.onChange(val);
    }

    onBlurPassword(event) {
        const val = event.target.value;
        this.setStatePassword(val, true);
    }

    /**
     * Validates the password, and sets the state for it.
     * @param {string} val The value of the password input.
     * @param {boolean} dirty Flag if the input has already lost the focus once.
     * @return {boolean} True if valid, otherwise false.
     */
    setStatePassword(val, dirty = false) {
        let passwordLabel = this.props.messageName;
        let passwordError = false;

        if (dirty && !val) {
            passwordLabel = messages.passwordRequired;
            passwordError = true;
        } else if (dirty && val.length < 8) {
            passwordLabel = messages.passwordInvalid;
            passwordError = true;
        }

        this.setState({
            password: val,
            passwordLabel,
            passwordError,
            passwordDirty: dirty,
        });

        return !passwordError;
    }

    handleClickShowPasssword() {
        this.setState(prevState => ({
            passwordVisible: !prevState.passwordVisible,
        }));
    }

    validate() {
        return this.setStatePassword(this.state.password, true);
    }

    render() {
        return (
            <TextField
                required
                fullWidth
                id={this.props.autoComplete || 'current-password'}
                autoComplete={this.props.autoComplete || 'current-password'}
                value={this.state.password}
                label={<FormattedMessage {...this.state.passwordLabel} />}
                error={this.state.passwordError}
                onChange={e => this.onChangePassword(e)}
                InputProps={{
                    onBlur: e => this.onBlurPassword(e),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="Toggle password visibility"
                                onClick={() => this.handleClickShowPasssword()}
                                onMouseDown={e => e.preventDefault()}
                            >
                                {this.state.passwordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                type={this.state.passwordVisible ? 'text' : 'password'}
                margin="normal"
            />
        );
    }

}

InputPassword.propTypes = {
    onChange: PropTypes.func.isRequired,
    autoComplete: PropTypes.string,
    messageName: PropTypes.object,
};

InputPassword.defaultProps = {
    messageName: messages.password,
};

export default InputPassword;
