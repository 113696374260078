import { defineMessages } from 'react-intl';

const messages = defineMessages({
    firstName: {
        id: 'InputFirstName.firstName',
        defaultMessage: 'First name',
    },
    firstNameRequired: {
        id: 'InputFirstName.firstNameRequired',
        defaultMessage: 'First name required',
    },
    firstNameInvalid: {
        id: 'InputFirstName.firstNameInvalid',
        defaultMessage: 'First name must have between 1 and 75 characters',
    },
});

export default messages;
