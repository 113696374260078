import { defineMessages } from 'react-intl';

const messages = defineMessages({
    next: {
        id: 'PushReceiver.next',
        defaultMessage: 'Your order will be prepared shortly in the restaurant.',
    },
    inPreperation: {
        id: 'PushReceiver.inPreparation',
        defaultMessage: 'Your order is currently being prepared in the restaurant',
    },
    prepared: {
        id: 'PushReceiver.prepared',
        defaultMessage: 'Your order has been prepared and can be picked up',
    },
    canceled: {
        id: 'PushReceiver.canceled',
        defaultMessage: 'Your order has been canceled by the restaurant',
    },
});

export default messages;
