import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

function Title({ text }) {
    return (
        <Typography variant="h6" color="inherit" className="title">{text}</Typography>
    );
}

Title.propTypes = {
    text: PropTypes.string,
};

export default Title;
