class File {

    /**
     * Checks if the file exists in the private data storage of the application.
     * @param {String} fileName The filename to check existance of.
     * @return {Promise<Boolean>} True if exists, otherwise false.
     */
    static fileExistsInDocuments(fileName) {
        const { cordova } = window;

        return new Promise((resolve, reject) => {
            window.resolveLocalFileSystemURL(
                cordova.file.dataDirectory,
                dir => {
                    dir.getFile(
                        fileName,
                        { create: false },
                        () => resolve(true),
                        () => resolve(false),
                    );
                },
                error => {
                    console.log(`File: Error opening app data directory: ${error}`);
                    reject(error);
                }
            );
        });

    }

    /**
     * Creates a zero-length file in the private data storage of the application.
     * @param {String} fileName The filename to create.
     * @return {Promise<void>} Calls the success callback of the promise when created.
     */
    static touchFileInDocuments(fileName) {
        const { cordova } = window;

        return new Promise((resolve, reject) => {
            window.resolveLocalFileSystemURL(
                cordova.file.dataDirectory,
                dir => {
                    dir.getFile(
                        fileName,
                        { create: true },
                        file => {
                            console.log(`File: Created file '${file.name}' in app data directory`);
                            resolve();
                        },
                        error => {
                            console.log(`File: Error creating file '${fileName}' in app data directory: ${error}`);
                            reject(error);
                        }
                    );
                },
                error => {
                    console.log(`File: Error opening app data directory: ${error}`);
                    reject(error);
                }
            );
        });
    }

}

export default File;
