import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';

function BackButton({ onClick }) {
    return (
        <IconButton color="inherit" onClick={onClick}>
            <ArrowBackIcon />
        </IconButton>
    );
}

BackButton.propTypes = {
    onClick: PropTypes.func,
};

export default BackButton;
