import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { Cached as CachedIcon } from '@material-ui/icons';

function RefreshButton({ onClick }) {
    return (
        <IconButton color="inherit" onClick={onClick}>
            <CachedIcon />
        </IconButton>
    );
}

RefreshButton.propTypes = {
    onClick: PropTypes.func,
};

export default RefreshButton;
