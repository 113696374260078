import { defineMessages } from 'react-intl';

const messages = defineMessages({
    header: {
        id: 'StorageDialog.header',
        defaultMessage: 'Warning',
    },
    browserBody: {
        id: 'StorageDialog.browserBody',
        // eslint-disable-next-line max-len
        defaultMessage: 'Retaining of session data is deactivated in your browser. Therefore, a login is required each time you open mybuxi.',
    },
    mobileBody: {
        id: 'StorageDialog.mobileBody',
        // eslint-disable-next-line max-len
        defaultMessage: 'Your device does not have an active pin code protection. Therefore, a login is required each time you open mybuxi.',
    },
    buttonAccept: {
        id: 'StorageDialog.buttonAccept',
        defaultMessage: 'Accept',
    },

});

export default messages;
