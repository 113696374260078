/**
 * @author RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Redirect, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
    Grid,
    Button,
    Typography,
    CircularProgress,
    Card,
    CardContent,
    CardActions,
} from '@material-ui/core';

import MenuBar from 'containers/MenuBar';
import FormattedPhoneNumber from 'components/FormattedPhoneNumber';
import InputCode from 'components/InputCode';
import InputPassword from 'components/InputPassword';
import { resetPassword as resetPasswordAction } from 'containers/PasswordReset/actions';
import { setPassword as setPasswordAction } from './actions';

import globalMessages from '../App/messages';
import messages from './messages';

class PasswordSet extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            resetCode: '',
            password: '',
        };
    }

    onBackClick() {
        this.props.dispatch(push('/login'));
    }

    onChangeResetCode(resetCode) {
        this.setState({ resetCode });
    }

    onChangePassword(password) {
        this.setState({ password });
    }

    doPasswordUpdate() {
        const {
            setPassword,
            areaCode,
            phoneNumber,
        } = this.props;

        const validResetCode = this.inputCode.validate();
        const validPassword = this.inputPassword.validate();

        if (validResetCode && validPassword) {
            setPassword(areaCode, phoneNumber, this.state.resetCode, this.state.password);
        }
    }

    doResend() {
        const { resetPassword, areaCode, phoneNumber } = this.props;

        resetPassword(areaCode, phoneNumber);
    }

    render() {
        if (this.props.loggedIn) {
            return <Redirect to="/merchants" />;
        }

        // prior to password set the password reset page must be opened
        if (!this.props.phoneNumber) {
            return <Redirect to="/password/reset" />;
        }

        const phoneNumberWithAreaCode = `${this.props.areaCode}${this.props.phoneNumber}`;

        return (
            <div className="password-set-page">
                <MenuBar titleMessage={messages.title} onBackClick={() => this.onBackClick()} />
                <div className="page-content">
                    <Grid container justify="center" spacing={0} className="page">
                        <Grid item xs={12} sm={8}>
                            <Card variant="outlined" style={{ border: 0 }}>
                                <CardContent className="logistration-form">
                                    <Typography variant="h6" align="center">
                                        <FormattedMessage {...messages.subtitle} />
                                    </Typography>
                                    <Typography variant="subtitle1" align="center">
                                        <FormattedPhoneNumber value={phoneNumberWithAreaCode} />
                                    </Typography>
                                    <Typography variant="subtitle1" align="center">
                                        <Link to="/password/reset">
                                            <FormattedMessage {...messages.changePhoneNumber} />
                                        </Link>
                                    </Typography>
                                    <form noValidate autoComplete="off">
                                        <InputCode
                                            ref={input => { this.inputCode = input; }}
                                            onChange={val => this.onChangeResetCode(val)}
                                        />
                                        <InputPassword
                                            ref={input => { this.inputPassword = input; }}
                                            messageName={messages.newPassword}
                                            autoComplete="new-password"
                                            onChange={val => this.onChangePassword(val)}
                                        />
                                    </form>
                                </CardContent>
                                <CardActions style={{ justifyContent: 'center' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={this.props.loading}
                                        onClick={() => this.doPasswordUpdate()}
                                    >
                                        {this.props.loading && (
                                            <React.Fragment>
                                                <CircularProgress color="inherit" size="1rem" />
                                                &nbsp;
                                            </React.Fragment>
                                        )}
                                        <FormattedMessage {...globalMessages.confirm} />
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        disabled={this.props.loading}
                                        onClick={() => this.doResend()}
                                    >
                                        {this.props.loading && (
                                            <React.Fragment>
                                                <CircularProgress color="inherit" size="1rem" />
                                                &nbsp;
                                            </React.Fragment>
                                        )}
                                        <FormattedMessage {...globalMessages.resend} />
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }

}

PasswordSet.propTypes = {
    dispatch: PropTypes.func.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    areaCode: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    setPassword: PropTypes.func.isRequired,
    resetPassword: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        loggedIn: state.login.loggedIn,
        areaCode: state.passwordReset.areaCode,
        phoneNumber: state.passwordReset.phoneNumber,
        loading: state.passwordSet.loading,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        setPassword: (areaCode, phoneNumber, resetCode, pwd) => dispatch(
            setPasswordAction(areaCode, phoneNumber, resetCode, pwd)
        ),
        resetPassword: (areaCode, phoneNumber) => dispatch(resetPasswordAction(areaCode, phoneNumber)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordSet);
