import {
    OPEN_LOADING_OVERLAY,
    CLOSE_LOADING_OVERLAY,
} from './constants';

const initialState = {
    isVisible: false,
};

function loadingOverlayReducer(state = initialState, action) {
    switch (action.type) {
        case OPEN_LOADING_OVERLAY:
            return {
                ...state,
                isVisible: true,
            };
        case CLOSE_LOADING_OVERLAY:
            return {
                ...state,
                isVisible: false,
            };
        default:
            return state;
    }
}

export default loadingOverlayReducer;
