import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setContainerPosition as setContainerPositionAction } from 'containers/Scroll/actions';

class Scroll extends React.Component {

    constructor(props) {
        super(props);

        this.scrollTo = this.scrollTo.bind(this);
    }

    componentDidMount() {
        this.scrollTo();
    }

    componentWillUnmount() {
        this.props.setContainerPosition(this.props.sid, this.scroll.scrollTop);
    }

    scrollTo() {
        this.scroll.scrollTop = this.props.containers[this.props.sid];
    }

    render() {
        const { containers, setContainerPosition, ...props } = this.props;
        return (
            <div {...props} ref={e => { this.scroll = e; }} />
        );
    }

}

Scroll.propTypes = {
    sid: PropTypes.string.isRequired,
    containers: PropTypes.object.isRequired,
    setContainerPosition: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        containers: state.scroll.containers,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setContainerPosition: (cid, pos) => dispatch(setContainerPositionAction(cid, pos)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Scroll);
