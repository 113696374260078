import {
    CORDOVA_INIT_DONE,
    REQUEST_LOCATION_INFO,
    UPDATE_LOCATION_INFO,
    UNSET_LOCATION_INFO,
    UPDATE_CONNECTION_INFO,
    PUSH_NOTIFICATION_RECEIVE,
    PUSH_NOTIFICATION_CLEAR,
    LOAD_MERCHANT_QUEUE_REQUEST,
    LOAD_MERCHANT_QUEUE_SUCCESS,
    LOAD_MERCHANT_QUEUE_ERROR,
    LOAD_ORDER_QUEUE_REQUEST,
    LOAD_ORDER_QUEUE_SUCCESS,
    LOAD_ORDER_QUEUE_ERROR,
    LOAD_API_VERSION_SUCCESS,
    LOAD_API_VERSION_ERROR,
    LOAD_API_VERSION_REQUEST,
    SET_TERMS_OF_SERVICE_COMPATIBLE,
    CHECK_TERMS_OF_SERVICE_REQUEST,
    CHECK_TERMS_OF_SERVICE_SUCCESS,
    CHECK_TERMS_OF_SERVICE_ERROR,
    AGREE_TERMS_OF_SERVICE_REQUEST,
    AGREE_TERMS_OF_SERVICE_SUCCESS,
    AGREE_TERMS_OF_SERVICE_ERROR,
    UPDATE_DEVICE_INFORMATION_REQUEST,
    UPDATE_DEVICE_INFORMATION_SUCCESS,
    UPDATE_DEVICE_INFORMATION_ERROR,
    LOAD_SETTINGS_REQUEST,
    LOAD_SETTINGS_SUCCESS,
    LOAD_SETTINGS_ERROR,
} from './constants';

const initialState = {
    cordovaInitDone: false,

    requestCoordinates: false,
    coordinates: null,
    coordinatesUpdated: null,

    isConnected: true,

    pushNotification: null,

    merchantQueueLoading: false,
    merchantQueueError: null,
    merchantQueue: null,

    orderQueueLoading: false,
    orderQueueError: null,
    orderQueue: null,

    apiVersionLoading: false,
    apiVersionError: null,
    apiVersion: null,

    termsOfServiceCompatibleLoading: false,
    termsOfServiceCompatibleError: null,
    termsOfServiceCompatible: null,

    termsOfServiceAgreementLoading: false,
    termsOfServiceAgreementError: null,

    updateDeviceInformationLoading: false,
    updateDeviceInformationError: null,

    settingsError: null,
    settings: null,
};

function appReducer(state = initialState, action) {
    switch (action.type) {
        case CORDOVA_INIT_DONE:
            return {
                ...state,
                cordovaInitDone: true,
            };
        case REQUEST_LOCATION_INFO:
            return {
                ...state,
                requestCoordinates: true,
            };
        case UPDATE_LOCATION_INFO:
            return {
                ...state,
                requestCoordinates: false,
                coordinates: action.coordinates,
                coordinatesUpdated: new Date(),
            };
        case UNSET_LOCATION_INFO:
            return {
                ...state,
                requestCoordinates: false,
                coordinates: null,
                coordinatesUpdated: new Date(),
            };
        case UPDATE_CONNECTION_INFO:
            return {
                ...state,
                isConnected: action.isConnected,
            };
        case PUSH_NOTIFICATION_RECEIVE:
            return {
                ...state,
                pushNotification: action.pushNotification,
            };
        case PUSH_NOTIFICATION_CLEAR:
            return {
                ...state,
                pushNotification: null,
            };
        case LOAD_MERCHANT_QUEUE_REQUEST:
            return {
                ...state,
                merchantQueueLoading: true,
                merchantQueueError: null,
                merchantQueue: null,
            };
        case LOAD_MERCHANT_QUEUE_SUCCESS:
            return {
                ...state,
                merchantQueueLoading: false,
                merchantQueueError: null,
                merchantQueue: action.result,
            };
        case LOAD_MERCHANT_QUEUE_ERROR:
            return {
                ...state,
                merchantQueueLoading: false,
                merchantQueueError: action.error,
                merchantQueue: null,
            };
        case LOAD_ORDER_QUEUE_REQUEST:
            return {
                ...state,
                orderQueueLoading: true,
                orderQueueError: null,
                orderQueue: null,
            };
        case LOAD_ORDER_QUEUE_SUCCESS:
            return {
                ...state,
                orderQueueLoading: false,
                orderQueueError: null,
                orderQueue: action.result,
            };
        case LOAD_ORDER_QUEUE_ERROR:
            return {
                ...state,
                orderQueueLoading: false,
                orderQueueError: action.error,
                orderQueue: null,
            };
        case LOAD_API_VERSION_REQUEST:
            return {
                ...state,
                apiVersionLoading: true,
                apiVersionError: null,
                apiVersion: null,
            };
        case LOAD_API_VERSION_ERROR:
            return {
                ...state,
                apiVersionLoading: false,
                apiVersionError: action.error,
                apiVersion: null,
            };
        case LOAD_API_VERSION_SUCCESS:
            return {
                ...state,
                apiVersionLoading: false,
                apiVersionError: null,
                apiVersion: action.apiVersion,
            };
        case SET_TERMS_OF_SERVICE_COMPATIBLE:
            return {
                ...state,
                termsOfServiceCompatible: action.isCompatible,
            };
        case CHECK_TERMS_OF_SERVICE_REQUEST:
            return {
                ...state,
                termsOfServiceCompatibleLoading: true,
                termsOfServiceCompatibleError: null,
                termsOfServiceCompatible: null,
            };
        case CHECK_TERMS_OF_SERVICE_SUCCESS:
            return {
                ...state,
                termsOfServiceCompatibleLoading: false,
                termsOfServiceCompatibleError: null,
                termsOfServiceCompatible: action.compatible,
            };
        case CHECK_TERMS_OF_SERVICE_ERROR:
            return {
                ...state,
                termsOfServiceCompatibleLoading: false,
                termsOfServiceCompatibleError: action.error,
                termsOfServiceCompatible: null,
            };
        case AGREE_TERMS_OF_SERVICE_REQUEST:
            return {
                ...state,
                termsOfServiceAgreementLoading: true,
                termsOfServiceAgreementError: null,
            };
        case AGREE_TERMS_OF_SERVICE_SUCCESS:
            return {
                ...state,
                termsOfServiceAgreementLoading: false,
                termsOfServiceAgreementError: null,
                termsOfServiceCompatible: action.compatible,
            };
        case AGREE_TERMS_OF_SERVICE_ERROR:
            return {
                ...state,
                termsOfServiceAgreementLoading: false,
                termsOfServiceAgreementError: action.error,
            };
        case UPDATE_DEVICE_INFORMATION_REQUEST:
            return {
                ...state,
                updateDeviceInformationLoading: true,
                updateDeviceInformationError: null,
            };
        case UPDATE_DEVICE_INFORMATION_SUCCESS:
            return {
                ...state,
                updateDeviceInformationLoading: false,
                updateDeviceInformationError: null,
            };
        case UPDATE_DEVICE_INFORMATION_ERROR:
            return {
                ...state,
                updateDeviceInformationLoading: false,
                updateDeviceInformationError: action.error,
            };
        case LOAD_SETTINGS_REQUEST:
            return state;
        case LOAD_SETTINGS_ERROR:
            return {
                ...state,
                settingsError: action.error,
            };
        case LOAD_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: action.settings,
            };
        default:
            return state;
    }
}

export default appReducer;
