import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';

function DeleteButton({ onClick }) {
    return (
        <IconButton color="inherit" onClick={onClick}>
            <DeleteIcon />
        </IconButton>
    );
}

DeleteButton.propTypes = {
    onClick: PropTypes.func,
};

export default DeleteButton;
