import React from 'react';

import { Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { APP_STORE_ANDROID_URL, APP_STORE_IOS_URL } from 'config';
import ErrorScreen from 'components/ErrorScreen';
import messages from './messages';

function UpgradeScreen() {

    const { cordova } = window;

    return (
        <ErrorScreen
            classProp="error-screen"
            header={() => <FormattedMessage {...messages.apiVersionIncompatibleHeader} />}
            body={() => cordova && cordova.platformId === 'browser'
                ? <FormattedMessage {...messages.apiVersionIncompatibleBodyBrowser} />
                : <FormattedMessage {...messages.apiVersionIncompatibleBody} />
            }
            render={() => (
                <Button
                    variant="contained"
                    color="primary"
                    href={(() => {
                        if (cordova && cordova.platformId === 'android') {
                            return APP_STORE_ANDROID_URL;
                        } else if (cordova && cordova.platformId === 'ios') {
                            return APP_STORE_IOS_URL;
                        } else {
                            return '/';
                        }
                    })()}
                >
                    {cordova && cordova.platformId === 'browser'
                        ? <FormattedMessage {...messages.reload} />
                        : <FormattedMessage {...messages.getUpdate} />
                    }
                </Button>
            )}
        />
    );
}

export default UpgradeScreen;
