import { LOAD_ORDER_GROUPS_SUCCESS, LOAD_ORDER_GROUP_STATE_SUCCESS } from 'containers/Orders/constants';
import { loadOrderGroupState } from 'containers/Orders/actions';

// max polling attempts
const maxTries = 3;

// wait time between polls
const timeout = 3000;

// mapping of orderGroup._id to poll count
const timers = {};

export const START_POLLING = 'middleware/polling/START_POLLING';
export const STOP_POLLING = 'middleware/polling/STOP_POLLING';

/**
 * Action set to set the current polled order group id.
 * Used from the Orders container.
 */
function startPolling(orderGroupId) {
    return {
        type: START_POLLING,
        id: orderGroupId,
    };
}

/**
 * Action unset to set the current polled order group id.
 * Used from the Orders container.
 */
function stopPolling(orderGroupId) {
    return {
        type: STOP_POLLING,
        id: orderGroupId,
    };
}

/**
 * Runs the given callback at the configured timeout.
 */
function startTimer(orderGroupId, callbackFn) {
    if (!timers[orderGroupId] || timers[orderGroupId] < maxTries) {
        setTimeout(callbackFn, timeout);
        timers[orderGroupId] = timers[orderGroupId] ? timers[orderGroupId] + 1 : 1;
    }
}

/**
 * Clears all timers.
 */
function clearTimers() {
    Object.keys(timers).forEach(key => delete timers[key]);
}

/**
 * Redux middleware that starts polling order groups with payment state PENDING
 * when the order groups have been loaded.
 */
const polling = ({ dispatch }) => next => action => {
    switch (action.type) {
        case LOAD_ORDER_GROUPS_SUCCESS: {
            // reset timers so that polling can start
            clearTimers();

            // filter out order groups with PENDING payment state
            const pending = action.result.filter(og => og.paymentState === 'PENDING');

            // schedules loading the order group payment state
            pending.forEach(orderGroup => {
                dispatch(startPolling(orderGroup._id));
                startTimer(
                    orderGroup._id,
                    () => dispatch(loadOrderGroupState(orderGroup._id))
                );
            });
            break;
        }
        case LOAD_ORDER_GROUP_STATE_SUCCESS:
            // payment state is still PENDING -> schedule loading the order group payment state
            if (action.result.paymentState === 'PENDING' && timers[action.result._id] < maxTries) {
                startTimer(
                    action.result._id,
                    () => dispatch(loadOrderGroupState(action.result._id))
                );
            } else {
                dispatch(stopPolling(action.result._id));
            }
            break;
        default:
            break;
    }

    return next(action);
};

export default polling;
