import {
    LOGOUT_SUCCESS,
} from 'containers/Login/constants';
import {
    LOAD_PAYMENT_METHODS_REQUEST,
    LOAD_PAYMENT_METHODS_SUCCESS,
    LOAD_PAYMENT_METHODS_ERROR,
    DELETE_PAYMENT_METHOD_REQUEST,
    DELETE_PAYMENT_METHOD_SUCCESS,
    DELETE_PAYMENT_METHOD_ERROR,
} from './constants';

const initialState = {
    lastLoaded: undefined,
    loading: false,
    deletingId: '',
    error: null,
    paymentMethods: [],
};

function formatPaymentMethod(paymentMethod) {
    if (paymentMethod.type !== 'card') return paymentMethod;

    return {
        ...paymentMethod,
        displayBrand: `${paymentMethod.brand.charAt(0).toUpperCase()}${paymentMethod.brand.slice(1).toLowerCase()}`,
        displayCardNumber: `•••• ${paymentMethod.last4}`,
        displayExpDate: `${paymentMethod.expMonth}/${paymentMethod.expYear}`,
    };
}

function paymentMethodsReducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_PAYMENT_METHODS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case LOAD_PAYMENT_METHODS_SUCCESS:
            return {
                ...state,
                lastLoaded: new Date().toISOString(),
                loading: false,
                paymentMethods: action.result.map(pm => formatPaymentMethod(pm)),
                error: null,
            };
        case LOAD_PAYMENT_METHODS_ERROR:
            return {
                ...state,
                loading: false,
                paymentMethods: [],
                error: action.error,
            };
        case DELETE_PAYMENT_METHOD_REQUEST:
            return {
                ...state,
                deletingId: action.id,
                error: null,
            };
        case DELETE_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                deletingId: '',
                error: null,
                paymentMethods: state.paymentMethods.filter(pm => pm.id !== action.id),
            };
        case DELETE_PAYMENT_METHOD_ERROR:
            return {
                ...state,
                deletingId: '',
                error: action.error,
            };

        case LOGOUT_SUCCESS:
            return {
                ...initialState,
            };
        default:
            return state;
    }
}

export default paymentMethodsReducer;
