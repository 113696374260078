/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

const messages = defineMessages({
    shoppingCart: {
        id: 'ShoppingCart.shoppingCart',
        defaultMessage: 'Shopping Cart',
    },
    delete: {
        id: 'ShoppingCart.delete',
        defaultMessage: 'Delete',
    },
    confirmDeleteTitle: {
        id: 'ShoppingCart.confirmDeleteTitle',
        defaultMessage: 'Delete Shopping Cart?',
    },
    confirmDeleteContent: {
        id: 'ShoppingCart.confirmDeleteContent',
        defaultMessage: 'Do you really want to delete the shopping cart?',
    },
    finalize: {
        id: 'ShoppingCart.finalize',
        defaultMessage: 'Pay',
    },
    finalizeOrder: {
        id: 'ShoppingCart.finalizeOrder',
        defaultMessage: 'Pay',
    },
    confirmFinalizeTitle: {
        id: 'ShoppingCart.confirmFinalizeTitle',
        defaultMessage: 'Finalize order?',
    },
    confirmFinalizeContent: {
        id: 'ShoppingCart.confirmFinalizeContent',
        defaultMessage: 'Do you really want to finalize your shopping cart? You will not be able to edit your shopping cart afterwards.',
    },
    orderError: {
        id: 'ShoppingCart.orderError',
        defaultMessage: 'Error sending order',
    },
    orderErrorMerchantInvalid: {
        id: 'ShoppingCart.orderErrorMerchantInvalid',
        defaultMessage: 'Order can not be submitted because merchant is not available anymore',
    },
    orderErrorMerchantClosed: {
        id: 'ShoppingCart.orderErrorMerchantClosed',
        defaultMessage: 'Order can not be submitted because merchant is closed',
    },
    orderErrorCustomerInvalid: {
        id: 'ShoppingCart.orderErrorCustomerInvalid',
        defaultMessage: 'Order can not be submitted because your account is disabled',
    },
    orderErrorCustomerBlocked: {
        id: 'ShoppingCart.orderErrorCustomerBlocked',
        defaultMessage: 'Order can not be submitted because your account is blocked by the restaurant',
    },
    orderErrorProductConflict: {
        id: 'ShoppingCart.orderErrorProductConflict',
        defaultMessage: 'Order can not be submitted because at least one product in your shopping cart has ' +
            'been updated',
    },
    orderErrorProductNotAvailable: {
        id: 'ShoppingCart.orderErrorProductNotAvailable',
        defaultMessage: 'Order can not be submitted because at least one product in your shopping cart is ' +
            'currently not available',
    },
    orderErrorMaxOrdersPerDay: {
        id: 'ShoppingCart.orderErrorMaxOrdersPerDay',
        defaultMessage: 'Order can not be submitted because you have reached your maximum amount of orders per day',
    },
    orderErrorMaxShoppingCartProducts: {
        id: 'ShoppingCart.orderErrorMaxShoppingCartProducts',
        defaultMessage: 'Order can not be submitted because you have too many products in your shopping cart',
    },
    orderErrorTermsOfServiceOutdated: {
        id: 'ShoppingCart.orderErrorTermsOfServiceOutdated',
        defaultMessage: 'Order can not be submitted because the general terms of service and privacy policy have changed',
    },
    orderErrorPendingOrders: {
        id: 'ShoppingCart.orderErrorPendingOrders',
        defaultMessage: 'Order can not be submitted because payments are pending',
    },
    orderErrorTotalAmountTooSmall: {
        id: 'ShoppingCart.orderErrorTotalAmountTooSmall',
        defaultMessage: 'The total amount is too small for payment type on site',
    },
    orderErrorStripeCheckoutRedirect: {
        id: 'ShoppingCart.orderErrorStripeCheckoutRedirect',
        defaultMessage: 'Can not redirect to Stripe checkout',
    },
    orderErrorStripeNotActivated: {
        id: 'ShoppingCart.orderErrorStripeNotActivated',
        defaultMessage: 'Online payments are disabled for one or more merchants',
    },
    orderErrorProductOptionsInvalid: {
        id: 'ShoppingCart.orderErrorProductOptionsInvalid',
        defaultMessage: 'The currently selected product options are invalid',
    },
    emptyHeader: {
        id: 'ShoppingCart.emptyHeader',
        defaultMessage: 'Empty shopping cart',
    },
    emptyBody: {
        id: 'ShoppingCart.emptyBody',
        defaultMessage: 'You haven\'t added anything to your shopping cart.',
    },
    emptyLink: {
        id: 'ShoppingCart.emptyLink',
        defaultMessage: 'Go to stores',
    },
    newPaymentMethod: {
        id: 'ShoppingCart.newPaymentMethod',
        defaultMessage: 'New credit card',
    },
    storedPaymentMethods: {
        id: 'ShoppingCart.storedPaymentMethod',
        defaultMessage: 'Stored credit cards',
    },
    savePaymentMethod: {
        id: 'ShoppingCart.savePaymentMethod',
        defaultMessage: 'Save for future use',
    },
});

export default messages;
