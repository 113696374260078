import {
    OPEN_LOADING_OVERLAY,
    CLOSE_LOADING_OVERLAY,
} from './constants';

export function openLoadingOverlay() {
    return {
        type: OPEN_LOADING_OVERLAY,
    };
}

export function closeLoadingOverlay() {
    return {
        type: CLOSE_LOADING_OVERLAY,
    };
}
