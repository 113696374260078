import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@material-ui/core';

import messages from './messages';

function StorageDialog({
    open,
    browser,
    onClose,
    onAccept,
}) {
    let body = {};

    if (browser) {
        body = messages.browserBody;
    } else {
        body = messages.mobileBody;
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <FormattedMessage {...messages.header} />
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <FormattedMessage {...body} />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onAccept} color="primary">
                    <FormattedMessage {...messages.buttonAccept} />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

StorageDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    browser: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onAccept: PropTypes.func.isRequired,
};

export default StorageDialog;
