import { defineMessages } from 'react-intl';

const messages = defineMessages({
    title: {
        id: 'PasswordSet.title',
        defaultMessage: 'Reset Password',
    },
    subtitle: {
        id: 'PasswordSet.subtitle',
        defaultMessage: 'Confirmation code sent to:',
    },
    newPassword: {
        id: 'PasswordSet.newPassword',
        defaultMessage: 'Enter new password',
    },
    changePhoneNumber: {
        id: 'PasswordSet.changePhoneNumber',
        defaultMessage: 'Change phone number',
    },
    changePasswordSuccess: {
        id: 'PasswordSet.changePasswordSuccess',
        defaultMessage: 'You have changed your password',
    },
    changePasswordError: {
        id: 'PasswordSet.changePasswordError',
        defaultMessage: 'Error changing password',
    },
    confirmationCodeInvalid: {
        id: 'PasswordSet.confirmationCodeInvalid',
        defaultMessage: 'Confirmation code is invalid',
    },
});

export default messages;
