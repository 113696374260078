import React from 'react';
import { FormattedMessage } from 'react-intl';

import { TERMS_OF_SERVICE_HREF } from 'config';

import messages from './messages';

function TermsOfServiceRef() {
    let uri = TERMS_OF_SERVICE_HREF;

    // Mobile Chrome has no PDF plugin; open in Google Docs Viewer
    const { cordova } = window;
    if (cordova && cordova.platformId === 'android') {
        uri = `https://docs.google.com/viewerng/viewer?url=${uri}`;
    }

    return (
        <a
            href={uri}
            target="_blank"
            rel="noopener noreferrer"
            className="external-link"
        >
            <FormattedMessage {...messages.termsOfService} />
        </a>
    );
}

export default TermsOfServiceRef;
