import React from 'react';
import PropTypes from 'prop-types';
import {
    FormControl,
    FormLabel,
    Typography,
    Box,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@material-ui/core';
import FormattedAmount from 'components/FormattedAmount';

function ProductOptionRadioGroup(props) {

    const {
        optionGroup,
        options,
        locale,
        selected,
        quantity,
    } = props;
    const [value, setValue] = React.useState(selected);

    const handleChange = event => {
        setValue(event.target.value);
        const option = options.find(o => o._id === event.target.value);
        props.onChange(
            [{
                _id: event.target.value,
                name: option.name,
                price: option.priceAdditional,
                group: option.optionGroup,
                _version: option._version,
            }],
            optionGroup._id
        );
    };

    const content = (
        <FormControl classes={{ root: 'product-option-group-form-control' }}>
            <div className="product-option-group-label">
                <FormLabel>
                    <Typography variant="body2">{optionGroup.name}</Typography>
                </FormLabel>
            </div>
            <RadioGroup
                name={optionGroup.name}
                value={value}
                onChange={handleChange}
            >
                {options.map(option => {
                    const fontWeight = value === option._id ? 600 : '';
                    return (
                        <div className="product-option-line" key={`po-${option._id}`}>
                            <FormControlLabel
                                value={option._id}
                                control={<Radio color="primary" classes={{ root: 'product-option-radio-checkbox' }} />}
                                label={
                                    <Typography variant="body2" component="span">
                                        <Box fontWeight={fontWeight}>{option.name}</Box>
                                    </Typography>
                                }
                            />
                            {option.priceAdditional !== 0 ?
                                <div className="product-option-price">
                                    <Typography variant="body2" component="span" className="narrow-line">
                                        <Box fontWeight={fontWeight}>
                                            +&nbsp;
                                            <FormattedAmount
                                                value={(option.priceAdditional * quantity)}
                                                locale={locale}
                                            />
                                        </Box>
                                    </Typography>
                                </div> : ''
                            }
                        </div>
                    );
                })}
            </RadioGroup>
        </FormControl>
    );

    return content;
}

ProductOptionRadioGroup.propTypes = {
    selected: PropTypes.string,
    quantity: PropTypes.number.isRequired,
    optionGroup: PropTypes.object.isRequired,
    options: PropTypes.array.isRequired,
    locale: PropTypes.string.isRequired,
    onChange: PropTypes.func,
};

export default ProductOptionRadioGroup;
