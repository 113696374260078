import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunkMiddleware from 'middlewares/thunk';
import pollingMiddleware from 'middlewares/polling';

import AppReducer from 'containers/App/reducer';
import productsReducer from 'containers/Products/reducer';
import snackbarReducer from 'containers/Snackbar/reducer';
import loginReducer from 'containers/Login/reducer';
import languageProviderReducer from 'containers/LanguageProvider/reducer';
import registrationReducer from 'containers/Registration/reducer';
import activationReducer from 'containers/Activation/reducer';
import passwordResetReducer from 'containers/PasswordReset/reducer';
import passwordSetReducer from 'containers/PasswordSet/reducer';
import shoppingCartReducer from 'containers/ShoppingCart/reducer';
import ordersReducer from 'containers/Orders/reducer';
import orderDetailsReducer from 'containers/OrderDetails/reducer';
import menuDrawerReducer from 'containers/MenuDrawer/reducer';
import loadingOverlayReducer from 'containers/LoadingOverlay/reducer';
import scrollReducer from 'containers/Scroll/reducer';
import customerReducer from 'containers/Profile/reducer';
import merchantsReducer from 'containers/Merchants/reducer';
import merchantDetailsReducer from 'containers/MerchantDetails/reducer';
import paymentMethodsReducer from 'containers/PaymentMethods/reducer';

import createReducer from './reducers';

const reducers = {
    // Add reducers here
    global: AppReducer,
    customer: customerReducer,
    merchants: merchantsReducer,
    merchantDetails: merchantDetailsReducer,
    shoppingCart: shoppingCartReducer,
    orders: ordersReducer,
    orderDetails: orderDetailsReducer,
    loadingOverlay: loadingOverlayReducer,
    scroll: scrollReducer,
    products: productsReducer,
    snackbar: snackbarReducer,
    login: loginReducer,
    languageProvider: languageProviderReducer,
    registration: registrationReducer,
    activation: activationReducer,
    passwordReset: passwordResetReducer,
    passwordSet: passwordSetReducer,
    menuDrawer: menuDrawerReducer,
    paymentMethods: paymentMethodsReducer,
};

// const loggerMiddleware = createLogger();

function configureStore(initialState = {}, history) {
    const middlewares = [
        routerMiddleware(history),
        thunkMiddleware,
        pollingMiddleware,
    ];

    const enhancers = [
        applyMiddleware(...middlewares),
    ];

    /* eslint-disable no-underscore-dangle */
    const composeEnhancers =
        typeof window === 'object' &&
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                // Specify extension’s options like
                // name, actionsBlacklist, actionsCreators, serialize...
            }) : compose;
    /* eslint-enable */

    const store = createStore(
        createReducer(reducers, history),
        initialState,
        composeEnhancers(...enhancers),
    );

    // Hot reload reducers
    if (module.hot) {
        module.hot.accept('./reducers', () => {
            store.replaceReducer(createReducer(store.asyncReducers));
        });
    }

    return store;
}

export default configureStore;
