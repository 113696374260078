/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

const messages = defineMessages({
    chooseOptions: {
        id: 'ProductOptions.chooseOptions',
        defaultMessage: 'Choose Options',
    },
    editOptions: {
        id: 'ProductOptions.editOptions',
        defaultMessage: 'Edit Options',
    },
    addProduct: {
        id: 'ProductOptions.addProduct',
        defaultMessage: 'Add Product',
    },
    updateProduct: {
        id: 'ProductOptions.updateProduct',
        defaultMessage: 'Update Product',
    },
});

export default messages;
