import {
    UNSET_CUSTOMER_DATA,
    FETCH_CUSTOMER_DATA_ERROR,
    FETCH_CUSTOMER_DATA_REQUEST,
    FETCH_CUSTOMER_DATA_SUCCESS,
    UPDATE_CUSTOMER_DATA_REQUEST,
    UPDATE_CUSTOMER_DATA_SUCCESS,
    UPDATE_CUSTOMER_DATA_ERROR,
} from './constants';

const initialState = {
    customer: null,

    pending: false,
    error: null,

    updatePending: false,
    updateError: null,
};

function customerReducer(state = initialState, action) {
    switch (action.type) {
        case UNSET_CUSTOMER_DATA:
            return {
                ...state,
                customer: null,
            };
        case FETCH_CUSTOMER_DATA_REQUEST:
            return {
                ...state,
                error: true,
            };
        case FETCH_CUSTOMER_DATA_SUCCESS:
            return {
                ...state,
                customer: action.data,
                pending: false,
                error: false,
            };
        case FETCH_CUSTOMER_DATA_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        case UPDATE_CUSTOMER_DATA_REQUEST:
            return {
                ...state,
                updatePending: true,
            };
        case UPDATE_CUSTOMER_DATA_SUCCESS:
            return {
                ...state,
                customer: {
                    ...state.customer,
                    ...action.data,
                },
                updatePending: false,
                updateError: false,
            };
        case UPDATE_CUSTOMER_DATA_ERROR:
            return {
                ...state,
                updatePending: false,
                updateError: action.error,
            };
        default:
            return state;
    }
}

export default customerReducer;
