/**
 * @author RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, loggedIn, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            if (loggedIn === true) {
                return <Component {...props} />;
            }

            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
        }}
    />
);

PrivateRoute.propTypes = {
    loggedIn: PropTypes.bool.isRequired,
    // PropTypes copied from <Route>
    computedMatch: PropTypes.object,
    path: PropTypes.string,
    exact: PropTypes.bool,
    strict: PropTypes.bool,
    sensitive: PropTypes.bool,
    component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    render: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    location: PropTypes.object,
};

export default PrivateRoute;
