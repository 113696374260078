import { CHANGE_LOCALE } from './constants';

function changeLocaleAction(locale) {
    return {
        type: CHANGE_LOCALE,
        locale,
    };
}

export function changeLocale(locale) {
    return dispatch => {
        dispatch(changeLocaleAction(locale));
    };
}
