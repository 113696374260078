import { defineMessages } from 'react-intl';

const messages = defineMessages({
    noConnectionHeader: {
        id: 'OfflineScreen.noConnectionHeader',
        defaultMessage: 'No Connection',
    },
    noConnectionBody: {
        id: 'OfflineScreen.noConnectionBody',
        defaultMessage: 'The App cannot detect an internet connection.',
    },
});

export default messages;
