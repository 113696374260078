/**
 * @author RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { TextField } from '@material-ui/core';

import messages from './messages';

class InputCode extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            code: '',
            codeLabel: messages.code,
            codeError: false,
            codeDirty: false,
        };
    }

    onChangeCode(event) {
        const val = event.target.value;
        this.setStateCode(val, this.state.codeDirty);
        this.props.onChange(val);
    }

    setStateCode(val, dirty = false) {
        const code = val.trim();
        let codeLabel = messages.code;
        let codeError = false;
        const validRegex = /^\d{6}$/;

        if (dirty && !code) {
            codeLabel = messages.codeRequired;
            codeError = true;
        } else if (dirty && !code.match(validRegex)) {
            codeLabel = messages.codeInvalid;
            codeError = true;
        }

        this.setState({
            code,
            codeLabel,
            codeError,
            codeDirty: dirty,
        });

        return !codeError;
    }

    validate() {
        return this.setStateCode(this.state.code, true);
    }

    render() {
        return (
            <TextField
                required
                fullWidth
                autoComplete="one-time-code"
                id="code"
                type="text"
                value={this.state.code}
                label={<FormattedMessage {...this.state.codeLabel} />}
                error={this.state.codeError}
                onChange={e => this.onChangeCode(e)}
                InputProps={{
                    onBlur: e => this.onChangeCode(e),
                }}
                margin="normal"
            />
        );
    }

}

InputCode.propTypes = {
    onChange: PropTypes.func.isRequired,
};

export default InputCode;
