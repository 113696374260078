import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';

class LanguageProvider extends React.PureComponent {

    render() {
        return (
            <IntlProvider
                locale={this.props.locale}
                key={this.props.locale}
                messages={this.props.messages[this.props.locale]}
            >
                {React.Children.only(this.props.children)}
            </IntlProvider>
        );
    }

}

LanguageProvider.propTypes = {
    locale: PropTypes.string.isRequired,
    messages: PropTypes.object.isRequired,
    children: PropTypes.element.isRequired,
};

function mapStateToProps(state) {
    return {
        locale: state.languageProvider.locale,
    };
}

export default connect(mapStateToProps)(LanguageProvider);
