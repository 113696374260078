import {
    DRAWER_OPEN,
    DRAWER_CLOSE,
} from './constants';

const initialState = {
    drawerOpen: false,
};

/*
 * REDUCERS
 */

function menuDrawerReducer(state = initialState, action) {
    switch (action.type) {
        case DRAWER_OPEN:
            return {
                ...state,
                drawerOpen: true,
            };
        case DRAWER_CLOSE:
            return {
                ...state,
                drawerOpen: false,
            };
        default:
            return state;
    }
}

export default menuDrawerReducer;
