import { defineMessages } from 'react-intl';

const messages = defineMessages({
    title: {
        id: 'Profile.title',
        defaultMessage: 'Profile',
    },
    save: {
        id: 'Profile.save',
        defaultMessage: 'Save',
    },
    customerUpdateSuccess: {
        id: 'Profile.customerUpdateSuccess',
        defaultMessage: 'Profile successfully updated.',
    },
    customerUpdateError: {
        id: 'Profile.customerUpdateError',
        defaultMessage: 'Profile cannot be updated.',
    },
});

export default messages;
