import Storage from 'services/Storage';
import { showSnackbar } from 'containers/Snackbar/actions';
import {
    openLoadingOverlay,
    closeLoadingOverlay,
} from 'containers/LoadingOverlay/actions';
import messages from 'containers/App/messages';

import {
    LOAD_ORDER_GROUPS_REQUEST,
    LOAD_ORDER_GROUPS_SUCCESS,
    LOAD_ORDER_GROUPS_ERROR,
    CANCEL_ORDER_GROUP_REQUEST,
    CANCEL_ORDER_GROUP_SUCCESS,
    CANCEL_ORDER_GROUP_ERROR,
    LOAD_ORDER_GROUP_STATE_REQUEST,
    LOAD_ORDER_GROUP_STATE_SUCCESS,
    LOAD_ORDER_GROUP_STATE_ERROR,
} from './constants';
import { API_URI } from '../../config';

// #region loadOrderGroups

export function loadOrderGroupsRequest() {
    return {
        type: LOAD_ORDER_GROUPS_REQUEST,
    };
}

export function loadOrderGroupsSuccess(result) {
    return {
        type: LOAD_ORDER_GROUPS_SUCCESS,
        result,
    };
}

export function loadOrderGroupsError(error) {
    return {
        type: LOAD_ORDER_GROUPS_ERROR,
        error,
    };
}

export function loadOrderGroups() {
    return dispatch => {
        dispatch(loadOrderGroupsRequest());
        dispatch(openLoadingOverlay());

        const url = new URL('/api/customer/ordergroup', API_URI).toString();

        fetch(url, {
            method: 'GET',
            headers: { Authorization: `Bearer ${Storage.getToken()}` },
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json()
                        .then(json => Promise.reject(json));
                }
            })
            .then(json => {
                dispatch(loadOrderGroupsSuccess(json));
                dispatch(closeLoadingOverlay());
            })
            .catch(error => {
                dispatch(loadOrderGroupsError(error));
                dispatch(showSnackbar(messages.errorLoading));
                dispatch(closeLoadingOverlay());
            });
    };
}

// #endregion

// #region cancelOrderGroup

export function cancelOrderGroupRequest() {
    return {
        type: CANCEL_ORDER_GROUP_REQUEST,
    };
}

export function cancelOrderGroupSuccess() {
    return {
        type: CANCEL_ORDER_GROUP_SUCCESS,
    };
}

export function cancelOrderGroupError(error) {
    return {
        type: CANCEL_ORDER_GROUP_ERROR,
        error,
    };
}

export function cancelOrderGroup(id) {
    return dispatch => {
        dispatch(cancelOrderGroupRequest());

        const url = new URL(`/api/customer/ordergroup/${id}`, API_URI).toString();

        fetch(url, {
            method: 'PATCH',
            headers: { Authorization: `Bearer ${Storage.getToken()}` },
        })
            .then(response => {
                if (response.ok) {
                    return response;
                } else {
                    return response.json()
                        .then(json => Promise.reject(json));
                }
            })
            .then(() => {
                dispatch(cancelOrderGroupSuccess());
                dispatch(showSnackbar(messages.canceled));
                // reload order groups to refresh view
                dispatch(loadOrderGroups());
            })
            .catch(error => {
                dispatch(cancelOrderGroupError(error));
                if (error.message.toLowerCase() === 'order not cancelable') {
                    dispatch(showSnackbar(messages.errorCancel));
                } else {
                    dispatch(showSnackbar(messages.errorLoading));
                }
            });
    };
}

// #endregion

// #region loadOrderGroupState

export function loadOrderGroupStateRequest(id) {
    return {
        type: LOAD_ORDER_GROUP_STATE_REQUEST,
        id,
    };
}

export function loadOrderGroupStateSuccess(result) {
    return {
        type: LOAD_ORDER_GROUP_STATE_SUCCESS,
        result,
    };
}

export function loadOrderGroupStateError(error) {
    return {
        type: LOAD_ORDER_GROUP_STATE_ERROR,
        error,
    };
}

export function loadOrderGroupState(id) {
    return dispatch => {
        dispatch(loadOrderGroupStateRequest(id));

        const url = new URL(`/api/customer/ordergroup/${id}/state`, API_URI).toString();

        fetch(url, {
            method: 'GET',
            headers: { Authorization: `Bearer ${Storage.getToken()}` },
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json()
                        .then(json => Promise.reject(json));
                }
            })
            .then(json => {
                dispatch(loadOrderGroupStateSuccess(json));
            })
            .catch(error => {
                dispatch(loadOrderGroupStateError(error));
            });
    };
}

// #endregion
