import {
    LOAD_MERCHANT_DETAILS_REQUEST,
    LOAD_MERCHANT_DETAILS_SUCCESS,
    LOAD_MERCHANT_DETAILS_ERROR,
} from './constants';

const initialState = {
    loading: false,
    error: null,
    merchant: null,
};

function merchantDetailsReducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_MERCHANT_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                merchant: null,
            };
        case LOAD_MERCHANT_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                merchant: action.result,
            };
        case LOAD_MERCHANT_DETAILS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
                merchant: null,
            };
        default:
            return state;
    }
}

export default merchantDetailsReducer;
