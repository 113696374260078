import React from 'react';
import PropTypes from 'prop-types';

import { CURRENCY } from 'config';

import './styles.scss';

function formatAmount(value, locale) {
    try {
        // pattern "0.00"
        return (Math.trunc(value) / 100).toLocaleString(locale, { minimumFractionDigits: 2 });
    } catch (e) {
        return value;
    }
}

function FormattedAmount({ value, locale, canceled = false }) {
    const classes = ['formatted-amount'];

    if (canceled) {
        classes.push('formatted-amount-canceled');
    }

    return (
        <span className={classes.join(' ')}>{formatAmount(value, locale)} {CURRENCY}</span>
    );
}

FormattedAmount.propTypes = {
    value: PropTypes.number.isRequired,
    locale: PropTypes.string.isRequired,
    canceled: PropTypes.bool,
};

export default FormattedAmount;
