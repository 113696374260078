/**
 * @author RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createHashHistory } from 'history';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { StylesProvider } from '@material-ui/styles';

import App from './containers/App';
import configureStore from './store';
import { translationMessages } from './i18n';

import theme from './theme';

import './main.scss';
import './favicon.ico';

const initialState = {};
const history = createHashHistory();

const store = configureStore(initialState, history);

const platformId = window.cordova ? window.cordova.platformId : '';
document.body.classList.add(platformId);

const render = messages => {
    ReactDOM.render(
        <StylesProvider injectFirst>
            <MuiThemeProvider theme={theme}>
                <Provider store={store}>
                    <ConnectedRouter history={history}>
                        <App history={history} messages={messages} />
                    </ConnectedRouter>
                </Provider>
            </MuiThemeProvider>
        </StylesProvider>,
        document.getElementById('app')
    );
};

if (module.hot) {
    module.hot.accept(['./i18n', './containers/App'], () => {
        ReactDOM.unmountComponentAtNode(document.getElementById('app'));
        render(translationMessages);
    });
}

if (window.Cypress) {
    window.store = store;
}

render(translationMessages);
