import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import {
    Divider,
    Grid,
    Typography,
} from '@material-ui/core';

import {
    Receipt as ReceiptIcon,
    DateRange as CalendarIcon,
    CreditCard as CreditCardIcon,
} from '@material-ui/icons';

import { ORDER_PAYMENT_TYPES } from 'utils';

import MenuBar from 'containers/MenuBar';
import LocaleDate from 'containers/LocaleDate';
import OrderStateText from 'containers/OrderStateText';

import ProductTable from 'components/ProductTable';
import MerchantSummary from 'components/MerchantSummary';
import FormattedSupportId from 'components/FormattedSupportId';
import CancelationReasons from 'components/CancelationReasons';

import globalMessages from 'containers/App/messages';
import {
    loadOrderDetails,
    cancelOrder,
} from './actions';
import messages from './messages';

import './styles.scss';

class OrderDetails extends React.Component {

    componentDidMount() {
        this.loadData();
        this.resume = this.loadData.bind(this);
        document.addEventListener('resume', this.resume);
    }

    componentWillUnmount() {
        document.removeEventListener('resume', this.resume);
    }

    loadData() {
        // this.props.loadOrderQueue();
        this.props.loadOrderDetails(this.props.match.params.id);
    }

    handleBack() {
        const {
            dispatch,
            location,
            order,
        } = this.props;

        if (order) {
            const { _id: merchantId } = order.merchant;
            // after ordering, go back to merchant
            const { from } = location.state || { from: { pathname: `/products/${merchantId}` } };
            from.state = { from: location, ...from.state };
            dispatch(push(from));
        } else {
            // order not loaded (for example not found)
            dispatch(push('/orders'));
        }
    }

    handleRefresh(e) {
        this.loadData();
        e.preventDefault();
    }

    render() {
        const {
            order,
            merchant,
            locale,
        } = this.props;

        let content;

        if (!order) {
            content = <div />;
        } else {
            content = (
                <div>
                    <Grid container justify="center" spacing={0} className="page">
                        <Grid item xs={6} className="icon-label-wrapper">
                            <ReceiptIcon className="text-hint" />
                            <Typography variant="body2" align="left" className="text-hint">
                                <FormattedMessage {...messages.orderNumber} />
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body2" align="right">
                                <FormattedSupportId value={order.supportId} />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid container justify="center" spacing={0} className="page">
                        <Grid item xs={6} className="icon-label-wrapper">
                            <CalendarIcon className="text-hint" />
                            <Typography variant="body2" align="left" className="text-hint">
                                <FormattedMessage {...messages.orderDate} />
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <LocaleDate isoDate={order.createdAt} variant="body2" />
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid container justify="center" spacing={0} className="page">
                        <Grid item xs={6} className="icon-label-wrapper">
                            <CreditCardIcon className="text-hint" />
                            <Typography variant="body2" align="left" className="text-hint">
                                <FormattedMessage {...globalMessages.paymentType} />
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="body2" align="right">
                                        <FormattedMessage {...globalMessages[ORDER_PAYMENT_TYPES[order.paymentType]]} />
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider />
                    {order.state === 'CANCELED' &&
                        <CancelationReasons
                            reasons={order.canceledReasons}
                            comment={order.canceledComment}
                        />
                    }
                    <OrderStateText
                        id={order._id}
                        state={order.state}
                        updatedAt={order.updatedAt}
                        pickedUpAt={order.pickedUpAt}
                        canceledAt={order.canceledAt}
                    />
                    <ProductTable products={order.products} note={order.note} locale={locale} />
                    <Divider />
                    {
                        // check if the merchant data is available
                        // for a disabled or hidden merchant no merchant data is available
                        merchant
                            ? <MerchantSummary merchant={merchant} />
                            : (
                                <div className="merchant-summary">
                                    <Typography variant="h6" className="page">
                                        {order.merchant.name}
                                    </Typography>
                                </div>
                            )
                    }
                </div>
            );
        }

        return (
            <div className="order-details-page">
                <MenuBar
                    titleMessage={messages.title}
                    onBackClick={() => this.handleBack()}
                    onRefreshClick={e => this.handleRefresh(e)}
                />

                <div className="page-content">
                    {content}
                </div>
            </div>
        );

    }

}

OrderDetails.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    loadOrderDetails: PropTypes.func,
    loadOrderQueue: PropTypes.func,
    cancelOrder: PropTypes.func,
    order: PropTypes.object,
    merchant: PropTypes.object,
    dispatch: PropTypes.func,
    locale: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return {
        merchant: state.merchantDetails.merchant,
        order: state.orderDetails.order,
        locale: state.languageProvider.locale,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        loadOrderDetails: id => dispatch(loadOrderDetails(id)),
        cancelOrder: id => dispatch(cancelOrder(id)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrderDetails));
