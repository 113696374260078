/**
 * @author RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { TextField } from '@material-ui/core';

import messages from './messages';

class InputStreet extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            street: props.street || '',
            streetLabel: messages.street,
            streetError: false,
            streetDirty: false,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.street && prevState.street !== nextProps.street) {
            return {
                ...prevState,
                street: nextProps.street,
            };
        }
        return null;
    }

    onChangeStreet(event) {
        const val = event.target.value;
        this.setStateStreet(val, this.state.streetDirty);
        this.props.onChange(val);
    }

    onBlurStreet(event) {
        const val = event.target.value.trim();
        this.setStateStreet(val, true);
        this.props.onChange(val);
    }

    /**
     * Validates the street, and sets the state for it.
     * @param {string} val The value of the street input.
     * @param {boolean} dirty Flag if the input has already lost the focus once.
     * @return {boolean} True if valid, otherwise false.
     */
    setStateStreet(val, dirty = false) {
        const street = val;
        let streetLabel = messages.street;
        let streetError = false;

        if (dirty && !val) {
            streetLabel = messages.streetRequired;
            streetError = true;
        } else if (dirty && val.length < 1) {
            streetLabel = messages.streetInvalid;
            streetError = true;
        } else if (dirty && val.length > 150) {
            streetLabel = messages.streetInvalid;
            streetError = true;
        }

        this.setState({
            street,
            streetLabel,
            streetError,
            streetDirty: dirty,
        });

        return !streetError;
    }

    validate() {
        return this.setStateStreet(this.state.street, true);
    }

    render() {
        return (
            <TextField
                required
                fullWidth
                autoComplete="new-street"
                id="street"
                type="text"
                value={this.state.street}
                label={<FormattedMessage {...this.state.streetLabel} />}
                error={this.state.streetError}
                onChange={e => this.onChangeStreet(e)}
                InputProps={{
                    onBlur: e => this.onBlurStreet(e),
                }}
                margin="normal"
            />
        );
    }

}

InputStreet.propTypes = {
    street: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

export default InputStreet;
