/**
 * @author RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import InputFirstName from '../InputFirstName';
import InputLastName from '../InputLastName';
import InputPhoneNumber from '../InputPhoneNumber';
import InputEmail from '../InputEmail';
import InputStreet from '../InputStreet';
import InputPostcode from '../InputPostcode';
import InputCity from '../InputCity';

class ProfileForm extends React.PureComponent {

    validate() {
        const firstNameValid = this.inputFirstName.validate();
        const lastNameValid = this.inputLastName.validate();
        const emailValid = this.inputEmail.validate();
        const streetValid = this.inputStreet.validate();
        const postcodeValid = this.inputPostcode.validate();
        const cityValid = this.inputCity.validate();
        return firstNameValid && lastNameValid && emailValid
            && streetValid && postcodeValid && cityValid;
    }

    render() {
        return (
            <form noValidate autoComplete="off">
                <InputFirstName
                    ref={input => { this.inputFirstName = input; }}
                    firstName={this.props.firstName}
                    onChange={val => this.props.onChangeFirstName(val)}
                />
                <InputLastName
                    ref={input => { this.inputLastName = input; }}
                    lastName={this.props.lastName}
                    onChange={val => this.props.onChangeLastName(val)}
                />
                <InputEmail
                    ref={input => { this.inputEmail = input; }}
                    email={this.props.email}
                    onChange={val => this.props.onChangeEmail(val)}
                />
                <InputStreet
                    ref={input => { this.inputStreet = input; }}
                    street={this.props.street}
                    onChange={val => this.props.onChangeStreet(val)}
                />
                <Grid container justify="center" spacing={1}>
                    <Grid item xs={4}>
                        <InputPostcode
                            ref={input => { this.inputPostcode = input; }}
                            postcode={this.props.postcode}
                            onChange={val => this.props.onChangePostcode(val)}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <InputCity
                            ref={input => { this.inputCity = input; }}
                            city={this.props.city}
                            onChange={val => this.props.onChangeCity(val)}
                        />
                    </Grid>
                </Grid>

                <InputPhoneNumber
                    disabled
                    ref={input => { this.inputPhoneNumber = input; }}
                    phoneNumber={this.props.phoneNumber}
                />
            </form>
        );
    }

}

ProfileForm.propTypes = {
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    street: PropTypes.string.isRequired,
    postcode: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    // triggered on change
    onChangeFirstName: PropTypes.func.isRequired,
    onChangeLastName: PropTypes.func.isRequired,
    onChangeEmail: PropTypes.func.isRequired,
    onChangeStreet: PropTypes.func.isRequired,
    onChangePostcode: PropTypes.func.isRequired,
    onChangeCity: PropTypes.func.isRequired,
};

export default ProfileForm;
