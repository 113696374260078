import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Redirect, Link } from 'react-router-dom';
import {
    Box,
    Button,
    Grid,
    Typography,
    NativeSelect,
    CircularProgress,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Select,
    MenuItem,
    Checkbox,
} from '@material-ui/core';
import { CheckCircleOutline as CheckCircleOutlineIcon } from '@material-ui/icons';
import { camelCase, pickBy, isNil } from 'lodash';

import MenuBar from 'containers/MenuBar';

import ConfirmDialog from 'components/ConfirmDialog';
import FullScreen from 'components/FullScreen';
import CartProductsTable from 'components/CartProductsTable';
import FormattedAmount from 'components/FormattedAmount';

import globalMessages from 'containers/App/messages';
import { showSnackbar as showSnackbarAction } from 'containers/Snackbar/actions';
import { loadMerchants as loadMerchantsAction } from 'containers/Merchants/actions';
import { loadPaymentMethods as loadPaymentMethodsAction } from 'containers/PaymentMethods/actions';

import {
    clearCart as clearCartAction,
    setComment as setCommentAction,
    setProduct as setProductAction,
    submitOrder as submitOrderAction,
    setPaymentMethod as setPaymentMethodAction,
    savePaymentMethod as savePaymentMethodAction,
    createNewPaymentMethod as createNewPaymentMethodAction,
} from './actions';
import messages from './messages';

import './styles.scss';

class ShoppingCart extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            comments: props.comments,
            redirect: false,
            redirectToLogin: false,
            openConfirmSubmit: false,
            openConfirmDelete: false,
        };
    }

    componentDidMount() {
        const {
            merchants,
            merchantsLoading,
            loggedIn,
            customer,
            userCoordinates,
            paymentMethodsLastLoaded,
        } = this.props;

        if (!merchants && !merchantsLoading) {
            this.props.loadMerchants(loggedIn, customer, userCoordinates);
        }

        if (!paymentMethodsLastLoaded && loggedIn) {
            this.props.loadPaymentMethods();
        }

        document.addEventListener(
            'resume',
            () => this.props.loadMerchants(loggedIn, customer, userCoordinates)
        );

        this.checkAndWarnMaxProducts();
    }

    componentDidUpdate() {
        this.checkAndWarnMaxProducts();
    }

    checkAndWarnMaxProducts() {
        if (this.props.tooManyProducts) {
            this.props.showSnackbar({
                ...globalMessages.tooManyProducts,
                values: { max: this.maxProducts() },
            });
        }
    }

    maxProducts(merchantId) {
        const { merchants } = this.props;

        return merchants[merchantId]?.maxShoppingCartProducts || Infinity;
    }

    handleBackClick() {
        this.setState(() => ({
            redirect: true,
        }));
    }

    handleDeleteClick() {
        this.setState(() => ({
            comments: {},
            openConfirmDelete: false,
            redirect: true,
        }));
        this.props.clearCart();
    }

    handleSubmitClick() {
        const {
            products,
            loggedIn,
            paymentType,
            paymentMethod,
            shouldSavePaymentMethod,
            shouldCreateNewPaymentMethod,
            isSubmitting,
            comments,
        } = this.props;

        this.setState({ openConfirmSubmit: false }, () => {
            if (!loggedIn) {
                this.setState(() => ({
                    redirectToLogin: true,
                }));
            } else if (!isSubmitting) {
                const platform = (window.cordova?.platformId ?? '').toUpperCase();

                const orders = Object.keys(products).map(merchantId => pickBy({
                    merchantId,
                    note: comments[merchantId],
                    // TODO: Set in backend via ordergroup?
                    platform,
                    products: Object.keys(products[merchantId].items).map(pId => pickBy({
                        _id: products[merchantId].items[pId]._id,
                        _version: products[merchantId].items[pId]._version,
                        quantity: products[merchantId].items[pId].quantity,
                        options: products[merchantId].items[pId]?.options?.map(option => ({
                            _id: option._id,
                            _version: option._version,
                        })),
                    }, v => !isNil(v))),
                }));

                this.props.submitOrder({
                    paymentType,
                    paymentMethodId: shouldCreateNewPaymentMethod ? null : paymentMethod,
                    shouldSavePaymentMethod,
                    orders,
                });
            }
        });
    }

    handleIncrement(merchantId, product, e) {
        const { products } = this.props;
        const maxAllowed = this.maxProducts(merchantId);

        if (products[merchantId].totalCount < maxAllowed) {
            this.props.setProduct({
                merchantId,
                product,
                quantity: 1,
                max: maxAllowed,
            });
            e.preventDefault();
        } else {
            this.props.showSnackbar({
                ...globalMessages.tooManyProducts,
                values: { max: maxAllowed },
            });
            e.preventDefault();
        }
    }

    handleDecrement(merchantId, product, e) {
        const maxAllowed = this.maxProducts(merchantId);

        this.props.setProduct({
            merchantId,
            product,
            quantity: -1,
            max: maxAllowed,
        });
        e.preventDefault();
    }

    handleChangeComment({ merchantId, comment }) {
        this.setState(state => ({
            comments: { ...state.comments, [merchantId]: comment },
        }));
    }

    handleBlurComment({ merchantId, comment }) {
        this.props.setComment(merchantId, comment);
    }

    handleChangePaymentType(e) {
        this.props.setPaymentType(e.target.value);
    }

    handleChangePaymentMethod(e) {
        this.props.createNewPaymentMethod(e.target.value !== 'stored');
    }

    renderPaymentTypeOptions() {
        const { intl, paymentType } = this.props;
        const paymentOptions = ['STRIPE'];

        const options = paymentOptions.length === 1
            ? (
                <Typography
                    variant="body2"
                    align="right"
                    style={{ lineHeight: '2rem' }}
                >
                    {intl.formatMessage({ ...globalMessages[camelCase(paymentOptions[0])] })}
                </Typography>
            )
            : (
                <NativeSelect
                    style={{ float: 'right' }}
                    className="cart-pick-up-type-select"
                    value={paymentType}
                    onChange={e => this.handleChangePaymentType(e)}
                    data-testid="payment-type-select"
                    classes={{ root: 'cart-body2' }}
                >
                    {paymentOptions.map(t => (
                        <option key={t} value={t}>
                            {intl.formatMessage({ ...globalMessages[camelCase(t)] })}
                        </option>
                    ))}
                </NativeSelect>
            );

        return (
            <Grid container justify="center" spacing={0} className="page cart-payment-box">
                <Grid item xs={4}>
                    <Typography
                        variant="body2"
                        align="left"
                        style={{ lineHeight: '2rem' }}
                    >
                        <FormattedMessage {...globalMessages.paymentType} />
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    {options}
                </Grid>
            </Grid>
        );
    }

    renderPaymentMethodOptions() {
        const {
            intl,
            availablePaymentMethods,
            setPaymentMethod,
            paymentMethod,
            shouldCreateNewPaymentMethod,
        } = this.props;

        const selectBox = (
            <Select
                style={{ float: 'right' }}
                className="cart-pick-up-type-select"
                value={paymentMethod}
                onChange={e => setPaymentMethod(e.target.value)}
                classes={{ root: 'cart-body2' }}
            >
                {availablePaymentMethods.map(pm => (
                    <MenuItem key={pm.id} value={pm.id}>
                        {`${pm.displayBrand} ${pm.displayCardNumber} (${pm.displayExpDate})`}
                    </MenuItem>
                ))}
            </Select>
        );

        return (
            <Grid container justify="center" spacing={0} className="page cart-payment-box">
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <RadioGroup
                            name="paymentMethod"
                            value={shouldCreateNewPaymentMethod ? 'new' : 'stored'}
                            onChange={e => this.handleChangePaymentMethod(e)}
                        >
                            {availablePaymentMethods.length > 0 &&
                                <FormControlLabel
                                    classes={{
                                        root: 'cart-payment-method-root',
                                        label: 'cart-payment-method-label',
                                    }}
                                    value="stored"
                                    control={<Radio classes={{ root: 'cart-payment-method-button-root' }} />}
                                    label={(
                                        <Box display="flex" flexGrow={1}>
                                            <FormattedMessage {...messages.storedPaymentMethods} />
                                            {selectBox}
                                        </Box>
                                    )}
                                />
                            }
                            <FormControlLabel
                                value="new"
                                control={<Radio />}
                                label={intl.formatMessage(messages.newPaymentMethod)}
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
        );
    }

    renderSaveCardCheckbox() {
        const { shouldSavePaymentMethod, savePaymentMethod } = this.props;

        return (
            <Grid container justify="center" spacing={0} className="page cart-payment-box">
                <Grid item xs={12}>
                    <FormControl>
                        <FormControlLabel
                            control={<Checkbox
                                checked={shouldSavePaymentMethod}
                                onChange={e => savePaymentMethod(e.target.checked)}
                                name="shouldSavePaymentMethod"
                            />}
                            label={<FormattedMessage {...messages.savePaymentMethod} />}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        );
    }

    renderButtonText() {
        const {
            processing,
            success,
            totalAmount,
            settings,
            locale,
        } = this.props;

        if (processing) {
            return (
                <Box>
                    <CircularProgress color="inherit" size="1rem" />
                </Box>
            );
        } else if (success) {
            return <CheckCircleOutlineIcon color="inherit" size="1rem" />;
        } else {
            return (
                <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                    <FormattedMessage {...messages.finalizeOrder} />
                    <FormattedAmount value={totalAmount + settings.deliveryCosts} locale={locale} />
                </Box>
            );
        }
    }

    render() {
        const {
            location,
            merchants,
            products,
            locale,
            processing,
            settings,
            totalAmount,
            shouldCreateNewPaymentMethod,
        } = this.props;
        const {
            redirect,
            redirectToLogin,
            openConfirmSubmit,
            openConfirmDelete,
            comments,
        } = this.state;

        let menuBar;
        let content;

        if (redirectToLogin) {
            return <Redirect to={{ pathname: '/login', state: { from: '/cart' } }} />;
        } else if (redirect) {
            const { from } = location.state || { from: '/' };
            return <Redirect to={from} />;
        } else if (!merchants || Object.keys(products).length <= 0) {
            menuBar = (
                <MenuBar
                    titleMessage={messages.shoppingCart}
                    onBackClick={() => this.handleBackClick()}
                />
            );
            content = (
                <FullScreen
                    classProp="empty-cart-screen"
                    header={() => <FormattedMessage {...messages.emptyHeader} />}
                    body={() => <FormattedMessage {...messages.emptyBody} />}
                    render={() => (
                        <Typography variant="body1">
                            <Link to="/merchants">
                                <FormattedMessage {...messages.emptyLink} />
                            </Link>
                        </Typography>
                    )}
                />
            );
        } else {
            menuBar = (
                <MenuBar
                    titleMessage={messages.shoppingCart}
                    onBackClick={() => this.handleBackClick()}
                    onDeleteClick={() => this.setState({ openConfirmDelete: true })}
                />
            );

            content = (
                <form noValidate autoComplete="off">
                    <div className="cart-products-wrapper">
                        <CartProductsTable
                            onIncrement={(mId, p, e) => this.handleIncrement(mId, p, e)}
                            onDecrement={(mId, p, e) => this.handleDecrement(mId, p, e)}
                            onChangeComment={e => this.handleChangeComment(e)}
                            onBlurComment={e => this.handleBlurComment(e)}
                            merchants={merchants}
                            products={products}
                            comments={comments}
                            totalAmount={totalAmount}
                            deliveryCosts={settings.deliveryCosts}
                            locale={locale}
                            location={location}
                        />
                    </div>
                    {this.renderPaymentTypeOptions()}
                    {this.renderPaymentMethodOptions()}
                    {shouldCreateNewPaymentMethod && this.renderSaveCardCheckbox()}
                    <div className="cart-button-wrapper">
                        <Button
                            fullWidth
                            variant="contained"
                            className="cart-button"
                            disabled={processing}
                            onClick={() => this.setState({ openConfirmSubmit: true })}
                        >
                            {this.renderButtonText()}
                        </Button>
                    </div>
                </form>
            );
        }

        return (
            <div className="shopping-cart-page">
                {menuBar}

                <ConfirmDialog
                    onClose={() => this.setState({ openConfirmDelete: false })}
                    open={openConfirmDelete}
                    titleMessage={messages.confirmDeleteTitle}
                    contentMessage={messages.confirmDeleteContent}
                    confirmMessage={messages.delete}
                    onCancelClick={() => this.setState({ openConfirmDelete: false })}
                    onConfirmClick={() => this.handleDeleteClick()}
                />

                <ConfirmDialog
                    onClose={() => this.setState({ openConfirmSubmit: false })}
                    open={openConfirmSubmit}
                    titleMessage={messages.confirmFinalizeTitle}
                    contentMessage={messages.confirmFinalizeContent}
                    confirmMessage={messages.finalize}
                    onCancelClick={() => this.setState({ openConfirmSubmit: false })}
                    onConfirmClick={() => this.handleSubmitClick()}
                />

                <div className="page-content">
                    {content}
                </div>
            </div>
        );
    }

}

ShoppingCart.propTypes = {
    merchantId: PropTypes.string,
    setPickUp: PropTypes.func,
    setPaymentType: PropTypes.func,

    // intl
    intl: PropTypes.object,

    // redux state
    location: PropTypes.object,
    merchants: PropTypes.object,
    merchantsLoading: PropTypes.bool.isRequired,
    locale: PropTypes.string.isRequired,
    settings: PropTypes.object,
    comments: PropTypes.object,
    products: PropTypes.object,
    tooManyProducts: PropTypes.bool,
    totalCount: PropTypes.number,
    paymentType: PropTypes.string,
    paymentMethod: PropTypes.string,
    shouldSavePaymentMethod: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    processing: PropTypes.bool,
    success: PropTypes.bool,
    totalAmount: PropTypes.number,
    loginResult: PropTypes.object,
    loggedIn: PropTypes.bool,
    userCoordinates: PropTypes.object,
    customer: PropTypes.object,
    availablePaymentMethods: PropTypes.array.isRequired,
    paymentMethodsLastLoaded: PropTypes.string,
    shouldCreateNewPaymentMethod: PropTypes.bool.isRequired,

    // redux dispatch
    clearCart: PropTypes.func.isRequired,
    setComment: PropTypes.func.isRequired,
    setProduct: PropTypes.func.isRequired,
    submitOrder: PropTypes.func.isRequired,
    showSnackbar: PropTypes.func.isRequired,
    loadMerchants: PropTypes.func.isRequired,
    loadPaymentMethods: PropTypes.func.isRequired,
    setPaymentMethod: PropTypes.func.isRequired,
    savePaymentMethod: PropTypes.func.isRequired,
    createNewPaymentMethod: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        location: state.router.location,

        locale: state.languageProvider.locale,

        merchants: state.merchants.merchants,
        merchantsLoading: state.merchants.merchantsLoading,
        settings: state.global.settings,

        comments: state.shoppingCart.comments,
        products: state.shoppingCart.products,
        totalCount: state.shoppingCart.totalCount,
        paymentType: state.shoppingCart.paymentType,
        paymentMethod: state.shoppingCart.paymentMethod,
        shouldSavePaymentMethod: state.shoppingCart.shouldSavePaymentMethod,
        shouldCreateNewPaymentMethod: state.shoppingCart.shouldCreateNewPaymentMethod,
        isSubmitting: state.shoppingCart.isSubmitting,
        processing: state.shoppingCart.processing,
        success: state.shoppingCart.success,
        tooManyProducts: state.shoppingCart.tooManyProducts,
        totalAmount: state.shoppingCart.totalAmount,

        merchantProducts: state.products.products,

        loginResult: state.login.result,
        loggedIn: state.login.loggedIn,

        userCoordinates: state.global.coordinates,
        customer: state.customer.customer,

        availablePaymentMethods: state.paymentMethods.paymentMethods,
        paymentMethodsLastLoaded: state.paymentMethods.lastLoaded,
    };
}

const mapDispatchToProps = {
    // shopping cart
    clearCart: clearCartAction,
    setComment: setCommentAction,
    setProduct: setProductAction,
    submitOrder: submitOrderAction,
    setPaymentMethod: setPaymentMethodAction,
    savePaymentMethod: savePaymentMethodAction,
    createNewPaymentMethod: createNewPaymentMethodAction,

    // snackbar
    showSnackbar: showSnackbarAction,

    // merchants
    loadMerchants: loadMerchantsAction,

    // payment methods
    loadPaymentMethods: loadPaymentMethodsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ShoppingCart));
