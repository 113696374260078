import { defineMessages } from 'react-intl';

const messages = defineMessages({
    storedPaymentMethods: {
        id: 'PaymentMethods.storedPaymentMethods',
        defaultMessage: 'Stored credit cards',
    },
    validUntil: {
        id: 'PaymentMethods.validUntil',
        defaultMessage: 'valid until',
    },
    noPaymentMethodsStored: {
        id: 'PaymentMethods.noPaymentMethodsStored',
        defaultMessage: 'You haven\'t stored any credit cards.',
    },
    deletePaymentMethodDialogTitle: {
        id: 'PaymentMethods.deletePaymentMethodDialogTitle',
        defaultMessage: 'Delete credit card?',
    },
    deletePaymentMethodDialogContent: {
        id: 'PaymentMethods.deletePaymentMethodDialogContent',
        defaultMessage: 'Do you want to delete this credit card? ' +
            'It will no longer be provided as available selection for future payments.',
    },
    deletePaymentMethodDialogConfirm: {
        id: 'PaymentMethods.deletePaymentMethodDialogConfirm',
        defaultMessage: 'Delete',
    },
});

export default messages;
