import { defineMessages } from 'react-intl';

const messages = defineMessages({
    orderCanceledPretext: {
        id: 'CancelationReasons.orderCanceledPretext',
        defaultMessage: 'Feedback from the restaurant',
    },
    NOT_AVAILABLE_ANYMORE: {
        id: 'CancelationReasons.NOT_AVAILABLE_ANYMORE',
        defaultMessage: 'At least one chosen item is not available anymore.',
    },
    INVALID_COMMENTS: {
        id: 'CancelationReasons.INVALID_COMMENTS',
        defaultMessage: 'Your order comments cannot be processed.',
    },
});

export default messages;
