import { showSnackbar } from 'containers/Snackbar/actions';
import messages from 'containers/App/messages';
import {
    openLoadingOverlay,
    closeLoadingOverlay,
} from 'containers/LoadingOverlay/actions';
import {
    loadProductsError,
    loadProductsRequest,
    loadProductsSuccess,
} from 'containers/Products/actions';
import {
    LOAD_MERCHANT_DETAILS_REQUEST,
    LOAD_MERCHANT_DETAILS_SUCCESS,
    LOAD_MERCHANT_DETAILS_ERROR,
} from './constants';
import { API_URI } from '../../config';

export function loadMerchantDetailsRequest() {
    return {
        type: LOAD_MERCHANT_DETAILS_REQUEST,
    };
}

export function loadMerchantDetailsSuccess(result) {
    return {
        type: LOAD_MERCHANT_DETAILS_SUCCESS,
        result,
    };
}

export function loadMerchantDetailsError(error) {
    return {
        type: LOAD_MERCHANT_DETAILS_ERROR,
        error,
    };
}

export function loadMerchantDetails(merchantId, showErrorIfNotFound = true) {
    return dispatch => {
        dispatch(loadMerchantDetailsRequest());
        dispatch(loadProductsRequest());
        dispatch(openLoadingOverlay());

        const url = new URL(`/api/public/merchant/${merchantId}`, API_URI).toString();

        fetch(url)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json()
                        .then(json => Promise.reject(json));
                }
            })
            .then(json => {
                const {
                    products,
                    categories,
                    productOptionGroups,
                    productOptions,
                    ...merchant
                } = json;
                dispatch(loadMerchantDetailsSuccess(merchant));
                dispatch(loadProductsSuccess({
                    products,
                    categories,
                    productOptionGroups,
                    productOptions,
                }));
                dispatch(closeLoadingOverlay());
            })
            .catch(error => {
                console.log(error);
                if (error.message.toLowerCase() !== 'merchant not found'
                    || (error.message.toLowerCase() === 'merchant not found' && showErrorIfNotFound)) {
                    dispatch(showSnackbar(messages.errorLoading));
                    dispatch(loadMerchantDetailsError(error));
                    dispatch(loadProductsError(error));
                }
                dispatch(closeLoadingOverlay());
            });
    };
}
