import { defineMessages } from 'react-intl';

const messages = defineMessages({
    stores: {
        id: 'App.stores',
        defaultMessage: 'Stores',
    },
    errorLoading: {
        id: 'App.errorLoading',
        defaultMessage: 'Error loading data',
    },
    errorCancel: {
        id: 'App.errorCancel',
        defaultMessage: 'The order cannot be canceled.',
    },
    login: {
        id: 'App.login',
        defaultMessage: 'Login',
    },
    orders: {
        id: 'App.orders',
        defaultMessage: 'Orders',
    },
    confirm: {
        id: 'App.confirm',
        defaultMessage: 'Confirm',
    },
    monday: {
        id: 'App.monday',
        defaultMessage: 'Monday',
    },
    tuesday: {
        id: 'App.tuesday',
        defaultMessage: 'Tuesday',
    },
    wednesday: {
        id: 'App.wednesday',
        defaultMessage: 'Wednesday',
    },
    thursday: {
        id: 'App.thursday',
        defaultMessage: 'Thursday',
    },
    friday: {
        id: 'App.friday',
        defaultMessage: 'Friday',
    },
    saturday: {
        id: 'App.saturday',
        defaultMessage: 'Saturday',
    },
    sunday: {
        id: 'App.sunday',
        defaultMessage: 'Sunday',
    },
    closed: {
        id: 'App.closed',
        defaultMessage: 'Closed',
    },
    closedToday: {
        id: 'App.closedToday',
        defaultMessage: 'Today closed',
    },
    closedTemporarily: {
        id: 'App.closedTemporarily',
        defaultMessage: 'Temporarily closed',
    },
    closedCurrently: {
        id: 'App.closedCurrently',
        defaultMessage: 'Currently closed',
    },
    opensAt: {
        id: 'App.opensAt',
        defaultMessage: 'Opens at',
    },
    openNow: {
        id: 'App.openNow',
        defaultMessage: 'Currently open',
    },
    nextInLine: {
        id: 'App.nextInLine',
        defaultMessage: 'You are next in line!',
    },
    open: {
        id: 'App.open',
        defaultMessage: 'Your order is open',
    },
    pending: {
        id: 'App.pending',
        defaultMessage: 'Your order reqruies further action',
    },
    inPreparation: {
        id: 'App.inPreparation',
        defaultMessage: 'Your order is in preparation',
    },
    prepared: {
        id: 'App.prepared',
        defaultMessage: 'Your order is ready to be picked up',
    },
    pickedUp: {
        id: 'App.pickedUp',
        defaultMessage: 'Your order has been picked up.',
    },
    pickedUpWithTime: {
        id: 'App.pickedUpWithTime',
        defaultMessage: 'Your order has been picked up at {date} {time}.',
    },
    canceled: {
        id: 'App.canceled',
        defaultMessage: 'Your order has been canceled.',
    },
    canceledWithTime: {
        id: 'App.canceledWithTime',
        defaultMessage: 'Your order has been canceled at {date} {time}.',
    },
    aborted: {
        id: 'App.aborted',
        defaultMessage: 'Your order has been aborted.',
    },
    ordersQueue: {
        id: 'App.ordersQueue',
        defaultMessage: 'Orders ahead of you',
    },
    tooManyProducts: {
        id: 'App.tooManyProducts',
        defaultMessage: 'You can only order a maximum of {max} products.',
    },
    about: {
        id: 'App.about',
        defaultMessage: 'About',
    },
    invalidToken: {
        id: 'App.invalidToken',
        defaultMessage: 'You have been logged out.',
    },
    asap: {
        id: 'App.asap',
        defaultMessage: 'As soon as possible',
    },
    preOrder: {
        id: 'App.preOrder',
        defaultMessage: 'Pre-order',
    },
    paymentType: {
        id: 'App.paymentType',
        defaultMessage: 'Payment type',
    },
    online: {
        id: 'App.online',
        defaultMessage: 'Online',
    },
    stripe: {
        id: 'App.stripe',
        defaultMessage: 'Credit card',
    },
    deliveryCosts: {
        id: 'App.deliveryCosts',
        defaultMessage: 'Delivery costs',
    },
    totalAmount: {
        id: 'App.totalAmount',
        defaultMessage: 'Total',
    },
    comments: {
        id: 'App.comments',
        defaultMessage: 'Special requests and comments',
    },
    resend: {
        id: 'App.resend',
        defaultMessage: 'Resend',
    },
    paymentMethod: {
        id: 'App.paymentMethod',
        defaultMessage: 'Credit card',
    },
    paymentMethods: {
        id: 'App.paymentMethods',
        defaultMessage: 'Credit cards',
    },
});

export default messages;
