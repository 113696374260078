import { push } from 'connected-react-router';
import { showSnackbar } from 'containers/Snackbar/actions';
import { setLogin } from 'containers/Login/actions';
import {
    PASSWORD_SET_REQUEST,
    PASSWORD_SET_SUCCESS,
    PASSWORD_SET_ERROR,
} from './constants';
import messages from './messages';
import { API_URI } from '../../config';
import { getDeviceWithPushToken } from '../../device';

/*
 * ACTION CREATORS
 */

function passwordSetRequest() {
    return {
        type: PASSWORD_SET_REQUEST,
    };
}

function passwordSetSuccess(result) {
    return {
        type: PASSWORD_SET_SUCCESS,
        result,
    };
}

function passwordSetError(error) {
    return {
        type: PASSWORD_SET_ERROR,
        error,
    };
}

/*
 * DISPATCHERS
 */

export function setPassword(areaCode, phoneNumber, resetCode, password) {
    return async dispatch => {
        dispatch(passwordSetRequest());

        const device = await getDeviceWithPushToken();
        const phoneNumberWithAreaCode = `${areaCode}${phoneNumber}`;
        const url = new URL('/api/public/customer/reset', API_URI).toString();
        const payload = {
            phoneNumber: phoneNumberWithAreaCode,
            resetCode,
            password,
            device,
        };

        fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json()
                        .then(json => Promise.reject(json));
                }
            })
            .then(json => {
                dispatch(passwordSetSuccess());

                // save token
                dispatch(setLogin(json.token));
                dispatch(showSnackbar(messages.changePasswordSuccess));

                // navigate to login page, which does the redirect back to origin page
                dispatch(push('/login'));
            })
            .catch(error => {
                dispatch(passwordSetError(error));

                if (error.message.toLowerCase() === 'confirmation code invalid') {
                    dispatch(showSnackbar(messages.confirmationCodeInvalid));
                } else {
                    dispatch(showSnackbar(messages.changePasswordError));
                }
            });
    };
}
