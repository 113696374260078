/**
 * @author RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { TextField } from '@material-ui/core';
import './styles.scss';

import messages from './messages';

class InputPostcode extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            postcode: props.postcode || '',
            postcodeLabel: messages.postcode,
            postcodeError: false,
            postcodeDirty: false,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.postcode && prevState.postcode !== nextProps.postcode) {
            return {
                ...prevState,
                postcode: nextProps.postcode,
            };
        }
        return null;
    }

    onChangePostcode(event) {
        const val = event.target.value;
        this.setStatePostcode(val, this.state.postcodeDirty);
        this.props.onChange(val);
    }

    onBlurPostcode(event) {
        const val = event.target.value;
        this.setStatePostcode(val, true);
    }

    /**
     * Validates the postcode, and sets the state for it.
     * @param {string} val The value of the postcode input.
     * @param {boolean} dirty Flag if the input has already lost the focus once.
     * @return {boolean} True if valid, otherwise false.
     */
    setStatePostcode(val, dirty = false) {
        const postcode = val.trim();
        let postcodeLabel = messages.postcode;
        let postcodeError = false;
        const validRegex = /^\d{4}$/;

        if (dirty && !val) {
            postcodeLabel = messages.postcodeRequired;
            postcodeError = true;
        } else if (dirty && !postcode.match(validRegex)) {
            postcodeLabel = messages.postcodeInvalid;
            postcodeError = true;
        }

        this.setState({
            postcode,
            postcodeLabel,
            postcodeError,
            postcodeDirty: dirty,
        });

        return !postcodeError;
    }

    validate() {
        return this.setStatePostcode(this.state.postcode, true);
    }

    render() {
        return (
            <TextField
                required
                fullWidth
                autoComplete="new-postcode"
                id="postcode"
                type="number"
                value={this.state.postcode}
                label={<FormattedMessage {...this.state.postcodeLabel} />}
                error={this.state.postcodeError}
                onChange={e => this.onChangePostcode(e)}
                InputProps={{
                    onBlur: e => this.onBlurPostcode(e),
                }}
                InputLabelProps={{
                    classes: {
                        root: 'input-postcode-label',
                        shrink: 'input-postcode-label-shrinked',
                    },
                }}
                margin="normal"
            />
        );
    }

}

InputPostcode.propTypes = {
    postcode: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

export default InputPostcode;
