import {
    OPEN_SNACKBAR,
    CLOSE_SNACKBAR,
    CLOSE_SNACKBAR_PAGE_CHANGE,
} from './constants';

/*
 * ACTION CREATORS
 */

export function openSnackbar(message, closeOnPageChange) {
    return {
        type: OPEN_SNACKBAR,
        message,
        closeOnPageChange,
    };
}

export function closeSnackbar() {
    return {
        type: CLOSE_SNACKBAR,
        message: null,
    };
}

export function closeSnackbarPageChange() {
    return {
        type: CLOSE_SNACKBAR_PAGE_CHANGE,
        message: null,
    };
}

/*
 * DISPATCHERS
 */

/**
 * Shows the snackbar.
 * @param {object} message The message object used for i18n, displayed as text.
 * @param {boolean} closeOnPageChange If the snackbar should be closed when the page is changed.
 */
export function showSnackbar(message, closeOnPageChange = false) {
    return dispatch => dispatch(openSnackbar(message, closeOnPageChange));
}

/**
 * Closes the snackbar when the user has decided to close it.
 */
export function hideSnackbar() {
    return dispatch => dispatch(closeSnackbar());
}

/**
 * Hides the snackbar when the page has been changed.
 */
export function hideSnackbarPageChange() {
    return dispatch => dispatch(closeSnackbarPageChange());
}
