import {
    LOAD_ORDER_DETAILS_REQUEST,
    LOAD_ORDER_DETAILS_SUCCESS,
    LOAD_ORDER_DETAILS_ERROR,
    CANCEL_ORDER_REQUEST,
    CANCEL_ORDER_SUCCESS,
    CANCEL_ORDER_ERROR,
} from './constants';

const initialState = {
    loading: false,
    error: null,
    order: null,
};

function orderDetailsReducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_ORDER_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
                order: null,
                error: null,
            };
        case LOAD_ORDER_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                order: action.response,
            };
        case LOAD_ORDER_DETAILS_ERROR:
            return {
                ...state,
                loading: false,
                order: null,
                error: action.error,
            };
        case CANCEL_ORDER_REQUEST:
        case CANCEL_ORDER_SUCCESS:
        case CANCEL_ORDER_ERROR:
        default:
            return state;
    }
}

export default orderDetailsReducer;
