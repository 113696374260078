import Storage from 'services/Storage';
import { showSnackbar } from 'containers/Snackbar/actions';
import {
    openLoadingOverlay,
    closeLoadingOverlay,
} from 'containers/LoadingOverlay/actions';
import messages from 'containers/App/messages';
import {
    LOAD_PAYMENT_METHODS_REQUEST,
    LOAD_PAYMENT_METHODS_SUCCESS,
    LOAD_PAYMENT_METHODS_ERROR,
    DELETE_PAYMENT_METHOD_REQUEST,
    DELETE_PAYMENT_METHOD_SUCCESS,
    DELETE_PAYMENT_METHOD_ERROR,
} from './constants';
import { API_URI } from '../../config';

// #region loadPaymentMethods

export function loadPaymentMethodsRequest() {
    return {
        type: LOAD_PAYMENT_METHODS_REQUEST,
    };
}

export function loadPaymentMethodsSuccess(result) {
    return {
        type: LOAD_PAYMENT_METHODS_SUCCESS,
        result,
    };
}

export function loadPaymentMethodsError(error) {
    return {
        type: LOAD_PAYMENT_METHODS_ERROR,
        error,
    };
}

export function loadPaymentMethods() {
    return dispatch => {
        dispatch(loadPaymentMethodsRequest());
        dispatch(openLoadingOverlay());

        const url = new URL('/api/customer/payment-method', API_URI).toString();

        fetch(url, {
            method: 'GET',
            headers: { Authorization: `Bearer ${Storage.getToken()}` },
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json()
                        .then(json => Promise.reject(json));
                }
            })
            .then(json => {
                dispatch(loadPaymentMethodsSuccess(json));
                dispatch(closeLoadingOverlay());
            })
            .catch(error => {
                dispatch(loadPaymentMethodsError(error));
                dispatch(showSnackbar(messages.errorLoading));
                dispatch(closeLoadingOverlay());
            });
    };
}

// #endregion

// #region deletePaymentMethod

export function deletePaymentMethodRequest(paymentMethodId) {
    return {
        type: DELETE_PAYMENT_METHOD_REQUEST,
        id: paymentMethodId,
    };
}

export function deletePaymentMethodSuccess(paymentMethodId) {
    return {
        type: DELETE_PAYMENT_METHOD_SUCCESS,
        id: paymentMethodId,
    };
}

export function deletePaymentMethodError(error) {
    return {
        type: DELETE_PAYMENT_METHOD_ERROR,
        error,
    };
}

export function deletePaymentMethod(paymentMethodId) {
    return dispatch => {
        dispatch(deletePaymentMethodRequest(paymentMethodId));

        const url = new URL(`/api/customer/payment-method/${paymentMethodId}`, API_URI).toString();

        fetch(url, {
            method: 'DELETE',
            headers: { Authorization: `Bearer ${Storage.getToken()}` },
        })
            .then(response => {
                if (response.ok) {
                    dispatch(deletePaymentMethodSuccess(paymentMethodId));
                    return Promise.resolve();
                } else {
                    return response.json()
                        .then(json => Promise.reject(json));
                }
            })
            .catch(error => {
                dispatch(deletePaymentMethodError(error));
                dispatch(showSnackbar(messages.errorLoading));
            });
    };
}

// #endregion
