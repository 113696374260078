import { defineMessages } from 'react-intl';

const messages = defineMessages({
    default: {
        id: 'SettingsLocale.default',
        defaultMessage: 'Default',
    },
    'german-ch': {
        id: 'SettingsLocale.german-ch',
        defaultMessage: 'German (Switzerland)',
    },
    german: {
        id: 'SettingsLocale.german',
        defaultMessage: 'German',
    },
    english: {
        id: 'SettingsLocale.english',
        defaultMessage: 'English',
    },
});

export default messages;
