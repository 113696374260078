import { defineMessages } from 'react-intl';

const messages = defineMessages({
    title: {
        id: 'PasswordReset.title',
        defaultMessage: 'Reset password',
    },
    subtitle: {
        id: 'PasswordReset.subtitle',
        defaultMessage: 'Send confirmation code to:',
    },
    reset: {
        id: 'PasswordReset.reset',
        defaultMessage: 'Request confirmation code',
        description: 'Label for the reset button',
    },
    resetError: {
        id: 'PasswordReset.resetError',
        defaultMessage: 'Error resetting password',
    },
    tooManyPasswordResetAttempts: {
        id: 'PasswordReset.tooManyPasswordResetAttempts',
        defaultMessage: 'Too many password reset attempts, try again later.',
    },
    confirmationCodeSent: {
        id: 'PasswordReset.confirmationCodeSent',
        defaultMessage: 'Confirmation code has been sent',
    },
});

export default messages;
