/*
 * CONSTANTS
 */

export const LOGIN_REQUEST = 'Login/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'Login/LOGIN_SUCCESS';
export const LOGIN_ERROR = 'Login/LOGIN_ERROR';

export const LOGOUT_REQUEST = 'Login/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'Login/LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'Login/LOGOUT_ERROR';
