import {
    ACTIVATION_REQUEST,
    ACTIVATION_SUCCESS,
    ACTIVATION_ERROR,
} from './constants';

const initialState = {
    loading: false,
    result: null,
    error: null,
};

/*
 * REDUCERS
 */

function activationReducer(state = initialState, action) {
    switch (action.type) {
        case ACTIVATION_REQUEST:
            return {
                ...state,
                loading: true,
                result: null,
                error: null,
            };
        case ACTIVATION_SUCCESS:
            return {
                ...state,
                loading: false,
                result: action.result,
                error: null,
            };
        case ACTIVATION_ERROR:
            return {
                ...state,
                loading: false,
                result: null,
                error: action.error,
            };
        default:
            return state;
    }
}

export default activationReducer;
