import { defineMessages } from 'react-intl';

const messages = defineMessages({
    lastName: {
        id: 'InputLastName.lastName',
        defaultMessage: 'Last name',
    },
    lastNameRequired: {
        id: 'InputLastName.lastNameRequired',
        defaultMessage: 'Last name required',
    },
    lastNameInvalid: {
        id: 'InputLastName.lastNameInvalid',
        defaultMessage: 'Last name must have between 1 and 75 characters',
    },
});

export default messages;
