class Statusbar {

    static init() {
        document.addEventListener('deviceready', () => {
            try {
                Statusbar.setStatusbarHeight();
                console.log('Statusbar: service initialized');

            } catch (err) {
                console.log(`Statusbar: Error initializing service: ${err}`);
            }
        });
    }

    /**
     * Computes the statusbar height (in consideration of a display notch),
     * and sets the CSS variable '--statusbar-height' in the document style.
     */
    static setStatusbarHeight() {
        const statusbar = Statusbar.getPlugin();
        if (!statusbar) {
            return;
        }

        statusbar.height(height => {
            const roundedHeight = Math.ceil(height);
            if (Number.isNaN(roundedHeight)) {
                document.documentElement.style.setProperty('--statusbar-height', '0px');
            } else {
                document.documentElement.style.setProperty('--statusbar-height', `${height}px`);
            }
        });
    }

    /**
     * Get the Cordova StatusBar plugin instance.
     * @returns {null|*}
     */
    static getPlugin() {
        if (window.cordova
            && (window.cordova.platformId === 'ios' || window.cordova.platformId === 'android')
            && window.StatusBar) {
            return window.StatusBar;
        } else {
            throw Error(`Statusbar: Plugin for platform ${window.cordova.platformId} not available`);
        }
    }

}

export default Statusbar;
