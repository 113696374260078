/**
 * @author RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import {
    AppBar,
    Toolbar,
    IconButton,
} from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import MenuDrawer from 'containers/MenuDrawer';
import { openMenuDrawer } from 'containers/MenuDrawer/action';

import BackButton from 'components/BackButton';
import InfoButton from 'components/InfoButton';
import DeleteButton from 'components/DeleteButton';
import RefreshButton from 'components/RefreshButton';
import Title from 'components/Title';

class MenuBar extends React.Component {

    componentDidMount() {
        const { StatusBar } = window;

        // the webview initially displays the launch image
        // set the background color of the webview to white opaque once this component has been rendered
        setTimeout(() => {
            StatusBar.setWebViewOpaque();
        }, 1000);
    }

    openDrawer() {
        this.props.dispatch(openMenuDrawer());
    }

    render() {
        const {
            intl,
            title,
            titleMessage,
            onBackClick,
            onInfoClick,
            onDeleteClick,
            onRefreshClick,
            color,
        } = this.props;

        const propStyles = color ? { style: { backgroundColor: color } } : {};

        const background = { color: 'primary' };

        const titleText = title || (titleMessage ? intl.formatMessage(titleMessage) : '');

        return (
            <div className="menubar">
                <AppBar position="fixed" {...background} {...propStyles}>
                    <Toolbar classes={{ root: 'toolbar' }}>
                        {onBackClick &&
                            <BackButton onClick={onBackClick} />
                        }

                        {!onBackClick &&
                            <IconButton
                                className="menu"
                                color="inherit"
                                aria-label="Menu"
                                onClick={() => this.openDrawer()}
                                data-testid="menu-drawer"
                            >
                                <MenuIcon />
                            </IconButton>
                        }
                        <MenuDrawer />
                        <Title text={titleText} />

                        {onInfoClick &&
                            <InfoButton onClick={onInfoClick} />
                        }

                        {onDeleteClick &&
                            <DeleteButton onClick={onDeleteClick} />
                        }

                        {onRefreshClick &&
                            <RefreshButton onClick={onRefreshClick} />
                        }

                        {!onInfoClick && !onDeleteClick && !onRefreshClick &&
                            <IconButton>
                                <span className="placeholder" />
                            </IconButton>
                        }
                    </Toolbar>
                </AppBar>
            </div>
        );
    }

}

MenuBar.propTypes = {
    intl: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    title: PropTypes.string,
    titleMessage: PropTypes.object,
    onBackClick: PropTypes.func,
    onInfoClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    onRefreshClick: PropTypes.func,
    color: PropTypes.string,
};

export default connect()(injectIntl(MenuBar));
