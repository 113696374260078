import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import './styles.scss';

function FullScreen({
    header,
    body,
    render,
    classProp,
}) {
    return (
        <div className={[classProp, 'screen-box'].join(' ')}>
            <Typography variant="h5" paragraph>
                {header()}
            </Typography>
            <Typography variant="body1" paragraph>
                {body()}
            </Typography>
            {render()}
        </div>
    );
}

FullScreen.propTypes = {
    header: PropTypes.func,
    body: PropTypes.func,
    render: PropTypes.func,
    classProp: PropTypes.string,
};

export default FullScreen;
