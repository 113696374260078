/**
 * @author RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Redirect, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
    Grid,
    Card,
    CardContent,
    CardActions,
    Button,
    Typography,
    CircularProgress,
} from '@material-ui/core';

import MenuBar from 'containers/MenuBar';
import FormattedPhoneNumber from 'components/FormattedPhoneNumber';
import InputCode from 'components/InputCode';

import {
    register as registerAction,
    clearRegistration as clearRegistrationAction,
} from '../Registration/actions';

import { activate as activateAction } from './actions';

import globalMessages from '../App/messages';
import messages from './messages';

class Activation extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            activationCode: '',
        };
    }

    onBackClick() {
        const {
            clearRegistration,
            dispatch,
        } = this.props;

        clearRegistration();
        dispatch(push('/login'));
    }

    onChangeActivationCode(code) {
        this.setState({ activationCode: code });
    }

    doActivation() {
        const valid = this.inputCode.validate();
        if (valid) {
            this.props.activate(
                this.props.areaCode,
                this.props.phoneNumber,
                this.state.activationCode
            );
        }
    }

    doResend() {
        const {
            areaCode,
            phoneNumber,
            firstName,
            lastName,
            email,
            street,
            postcode,
            city,
            password,
            register,
        } = this.props;

        register(
            areaCode,
            phoneNumber,
            firstName,
            lastName,
            email,
            street,
            postcode,
            city,
            password,
        );
    }

    render() {
        if (this.props.loggedIn) {
            return <Redirect to="/merchants" />;
        }

        // prior to activation the registration page must be opened
        if (!this.props.phoneNumber) {
            return <Redirect to="/registration" />;
        }

        const phoneNumberWithAreaCode = `${this.props.areaCode}${this.props.phoneNumber}`;

        return (
            <div className="activation-page">
                <MenuBar titleMessage={messages.title} onBackClick={() => this.onBackClick()} />
                <div className="page-content">
                    <Grid container justify="center" spacing={0} className="page">
                        <Grid item xs={12} sm={8}>
                            <Card className="activation-card">
                                <CardContent>
                                    <Typography variant="h6" align="center">
                                        <FormattedMessage {...messages.subtitle} />
                                    </Typography>
                                    <Typography variant="subtitle1" align="center">
                                        <FormattedPhoneNumber value={phoneNumberWithAreaCode} />
                                    </Typography>
                                    <Typography variant="subtitle1" align="center">
                                        <Link to="/registration">
                                            <FormattedMessage {...messages.changePhoneNumber} />
                                        </Link>
                                    </Typography>
                                    <form noValidate autoComplete="off">
                                        <InputCode
                                            ref={input => { this.inputCode = input; }}
                                            onChange={val => this.onChangeActivationCode(val)}
                                        />
                                    </form>
                                </CardContent>
                                <CardActions style={{ justifyContent: 'center' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={this.props.loading}
                                        onClick={() => this.doActivation()}
                                    >
                                        {this.props.loading && (
                                            <React.Fragment>
                                                <CircularProgress color="inherit" size="1rem" />
                                                &nbsp;
                                            </React.Fragment>
                                        )}
                                        <FormattedMessage {...globalMessages.confirm} />
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        disabled={this.props.loading}
                                        onClick={() => this.doResend()}
                                    >
                                        {this.props.loading && (
                                            <React.Fragment>
                                                <CircularProgress color="inherit" size="1rem" />
                                                &nbsp;
                                            </React.Fragment>
                                        )}
                                        <FormattedMessage {...globalMessages.resend} />
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }

}

Activation.propTypes = {
    dispatch: PropTypes.func.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    activate: PropTypes.func.isRequired,
    clearRegistration: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    areaCode: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    street: PropTypes.string.isRequired,
    postcode: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
    return {
        loggedIn: state.login.loggedIn,
        loading: state.activation.loading,
        areaCode: state.registration.areaCode,
        phoneNumber: state.registration.phoneNumber,
        firstName: state.registration.firstName,
        lastName: state.registration.lastName,
        email: state.registration.email,
        street: state.registration.street,
        postcode: state.registration.postcode,
        city: state.registration.city,
        password: state.registration.password,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        activate: (areaCode, phoneNumber, activationCode) => dispatch(
            activateAction(areaCode, phoneNumber, activationCode)
        ),
        // eslint-disable-next-line max-params
        register: (areaCode, phoneNumber, firstName, lastName, email, street, postcode, city, password) => dispatch(
            registerAction(areaCode, phoneNumber, firstName, lastName, email, street, postcode, city, password)
        ),
        clearRegistration: () => dispatch(clearRegistrationAction()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Activation);
