/**
 * @author RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */

/**
 * Public API URIs.
 * Webpacks define plugin will set the uri
 * @type {string}
 */
export const API_URI = __API_URI__; // eslint-disable-line no-undef

/**
 * The REST API version required to work properly.
 * @type {number}
 */
export const API_VERSION = 4;

/**
 * The link to the terms and conditions linked on the registration page.
 * For the test environment the http://docs-mybuxi.risedev.at domain is used.
 * For the production environment the http://docs.mybuxi.ch domain is used.
 * @type {string}
 */
export const TERMS_OF_SERVICE_HREF = `${API_URI.replace('api', 'docs')}/terms-of-service`;

/**
 * The prefix used to store config items.
 * @type {string}
 */
export const APP_CONFIG_PREFIX = 'MYBUXI_CUSTOMER';

/**
 * Links for updating the app
 * @type {string}
 */
export const APP_STORE_IOS_URL = 'itms-apps://itunes.apple.com/app/1436496548';
export const APP_STORE_ANDROID_URL = 'market://details?id=ch.mybuxi.customer';

/**
 * The Stripe API public key, used for the checkout.
 */
export const STRIPE_API_PUBLIC_KEY = __STRIPE_API_PUBLIC_KEY__; // eslint-disable-line no-undef

/**
 * Currency Symbol which should be displayed throughout the app.
 * @type {string}
 */
export const CURRENCY = 'CHF';
