import { defineMessages } from 'react-intl';

const messages = defineMessages({
    title: {
        id: 'OrderDetails.title',
        defaultMessage: 'Order Details',
    },
    info: {
        id: 'OrderDetails.info',
        defaultMessage: 'Additional info',
    },
    cancelOrder: {
        id: 'OrderDetails.cancelOrder',
        defaultMessage: 'Cancel Order',
    },
    orderNumber: {
        id: 'OrderDetails.orderNumber',
        defaultMessage: 'Order number',
    },
    orderDate: {
        id: 'OrderDetails.orderDate',
        defaultMessage: 'Order date',
    },
    cancelNotPossible: {
        id: 'OrderDetails.cancelNotPossible',
        defaultMessage: 'You currently cannot cancel your order. Please contact the restaurant directly.',
    },
});

export default messages;
