import { isEmpty } from 'lodash';

import {
    LOAD_PAYMENT_METHODS_SUCCESS,
    DELETE_PAYMENT_METHOD_SUCCESS,
} from 'containers/PaymentMethods/constants';
import { LOGOUT_SUCCESS } from 'containers/Login/constants';

import {
    SET_PRODUCT,
    CLEAR_CART,
    SET_COMMENT,
    SUBMIT_ORDER_REQUEST,
    SUBMIT_ORDER_SUCCESS,
    SUBMIT_ORDER_ERROR,
    SET_PRODUCT_VALIDITY,
    PAYMENT_TYPE,
    SET_PAYMENT_TYPE,
    SET_PAYMENT_METHOD,
    SAVE_PAYMENT_METHOD,
    CREATE_NEW_PAYMENT_METHOD,
} from './constants';

const initialState = {
    processing: false,
    hover: false,
    products: {},
    comments: {},
    submitError: null,
    tooManyProducts: false,
    totalCount: 0,
    totalAmount: 0,
    paymentType: PAYMENT_TYPE.stripe,
    paymentMethod: undefined,
    shouldCreateNewPaymentMethod: true,
    shouldSavePaymentMethod: false,
    isSubmitting: false,
    success: false,
};

function shoppingCartReducer(state = initialState, action) {
    switch (action.type) {
        case SET_PRODUCT: {
            // Build item id with product id and option ids
            let itemId = `${action.product._id}`;
            if (action.product.options) {
                itemId = `${itemId}/${action.product.options.map(option => option._id).join('/')}`;
            }

            // If this product already exists in store use its quantity, else use init with zero
            const currentQuantity = state.products[action.merchantId]?.items[itemId]?.quantity ?? 0;

            // The new product quantity depends on the existence of replaceId
            const quantity = action.replaceId
                ? action.quantity
                : currentQuantity + action.quantity;

            // If product options exist add them to total amount
            const totalPrice = quantity
                * (action.product.price + (action.product.options?.reduce((a, b) => a + b.price, 0) ?? 0));

            // Init the new state
            // Object rest/spread changes position of products in object (Safari, see #184)
            const newState = { ...state };

            newState.products = { ...state.products };
            newState.products[action.merchantId] = { items: {} };

            newState.products[action.merchantId].items = { ...state.products[action.merchantId]?.items };

            newState.products[action.merchantId].items[itemId] = { ...action.product };
            newState.products[action.merchantId].items[itemId].quantity = quantity;
            newState.products[action.merchantId].items[itemId].totalPrice = totalPrice;

            // Remove old product item which was replaced
            if (action.replaceId && action.replaceId !== itemId) {
                delete newState.products[action.merchantId].items[action.replaceId];
            }

            // When everything is set correctly recalculate the total values
            // Firstly, calculate new merchant totals
            newState.products[action.merchantId].totalCount = Object.keys(newState.products[action.merchantId].items)
                .reduce((acc, cur) => acc + newState.products[action.merchantId].items[cur].quantity, 0);
            newState.products[action.merchantId].totalAmount = Object.keys(newState.products[action.merchantId].items)
                .reduce((acc, cur) => acc + newState.products[action.merchantId].items[cur].totalPrice, 0);

            // Secondly, calculate overall totals (depend on merchant totals)
            newState.totalCount = Object.keys(newState.products)
                .reduce((acc, cur) => acc + newState.products[cur].totalCount, 0);
            newState.totalAmount = Object.keys(newState.products)
                .reduce((acc, cur) => acc + newState.products[cur].totalAmount, 0);

            // Thirdly, set all fields dependent on the totals
            newState.hover = newState.totalCount > 0;
            newState.tooManyProducts = action.max > 0
                && action.max < newState.products[action.merchantId].totalCount;

            // Remove entry if quantity is zero
            if (currentQuantity + action.quantity < 1) {
                delete newState.products[action.merchantId].items[itemId];
            }

            // If merchant no longer holds any items remove
            if (isEmpty(newState.products[action.merchantId].items)) {
                delete newState.products[action.merchantId];
            }

            return newState;
        }
        case SET_PRODUCT_VALIDITY: {
            const newProducts = { ...state.products };
            newProducts[action.productId].isValid = action.isValid;
            return {
                ...state,
                products: newProducts,
            };
        }
        case SUBMIT_ORDER_SUCCESS: {
            return {
                ...state,
                success: true,
                processing: false,
            };
        }
        case CLEAR_CART:
            return {
                ...initialState,
                paymentMethod: state.paymentMethod,
                shouldCreateNewPaymentMethod: !state.paymentMethod,
            };
        case SET_COMMENT:
            return {
                ...state,
                comments: { ...state.comments, [action.merchantId]: action.text },
            };
        case SET_PAYMENT_TYPE:
            return {
                ...state,
                paymentType: action.paymentType,
            };
        case SET_PAYMENT_METHOD:
            return {
                ...state,
                paymentMethod: action.paymentMethod,
            };
        case SAVE_PAYMENT_METHOD:
            return {
                ...state,
                shouldSavePaymentMethod: action.save,
            };
        case CREATE_NEW_PAYMENT_METHOD:
            return {
                ...state,
                shouldCreateNewPaymentMethod: action.create,
            };
        case SUBMIT_ORDER_REQUEST:
            return {
                ...state,
                isSubmitting: true,
                processing: true,
                success: false,
            };
        case SUBMIT_ORDER_ERROR:
            return {
                ...state,
                submitError: action.error,
                isSubmitting: false,
                processing: false,
                success: false,
            };

        // Login/Logout actions
        case LOGOUT_SUCCESS: {
            return {
                ...initialState,
            };
        }

        // PaymentMethods actions
        case LOAD_PAYMENT_METHODS_SUCCESS:
            return {
                ...state,
                paymentMethod: action.result.find(pm => pm.id === state.paymentMethod)?.id || action.result[0]?.id,
                // This only works, because we load the payment methods only once for the shopping cart.
                // If we want to load the payment methods everytime the customer opens the shopping cart the line below
                // won't work because it would reset inputs the customer did previously.
                shouldCreateNewPaymentMethod: !(action.result?.length > 0),
            };
        case DELETE_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                paymentMethod: action.id === state.paymentMethod ? null : state.paymentMethod,
                shouldCreateNewPaymentMethod: action.id === state.paymentMethod,
            };
        default:
            return state;
    }
}

export default shoppingCartReducer;
