import Storage from 'services/Storage';
import { showSnackbar } from 'containers/Snackbar/actions';
import {
    UNSET_CUSTOMER_DATA,
    FETCH_CUSTOMER_DATA_REQUEST,
    FETCH_CUSTOMER_DATA_SUCCESS,
    FETCH_CUSTOMER_DATA_ERROR,
    UPDATE_CUSTOMER_DATA_REQUEST,
    UPDATE_CUSTOMER_DATA_SUCCESS,
    UPDATE_CUSTOMER_DATA_ERROR,
} from './constants';
import messages from './messages';
import { API_URI } from '../../config';

export function unsetCustomerData() {
    return {
        type: UNSET_CUSTOMER_DATA,
    };
}

// #region fetchCustomerData

export function fetchCustomerDataRequest() {
    return {
        type: FETCH_CUSTOMER_DATA_REQUEST,
    };
}

export function fetchCustomerDataSuccess(data) {
    return {
        type: FETCH_CUSTOMER_DATA_SUCCESS,
        data,
    };
}

export function fetchCustomerDataError(error) {
    return {
        type: FETCH_CUSTOMER_DATA_ERROR,
        error,
    };
}

export function fetchCustomerData() {
    return dispatch => {
        dispatch(fetchCustomerDataRequest());

        const url = new URL('/api/customer', API_URI).toString();

        return fetch(url, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${Storage.getToken()}`,
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json()
                        .then(json => Promise.reject(json));
                }
            })
            .then(json => {
                dispatch(fetchCustomerDataSuccess(json));
                return Promise.resolve(json);
            })
            .catch(err => {
                dispatch(fetchCustomerDataError(err));
                return Promise.reject(err);
            });
    };
}

// #endregion

// #region updateCustomerData

const updateCustomerDataRequest = () => ({
    type: UPDATE_CUSTOMER_DATA_REQUEST,
});

const updateCustomerDataSuccess = data => ({
    type: UPDATE_CUSTOMER_DATA_SUCCESS,
    data,
});

const updateCustomerDataError = error => ({
    type: UPDATE_CUSTOMER_DATA_ERROR,
    error,
});

export function updateCustomerData(customer) {
    return dispatch => {
        dispatch(updateCustomerDataRequest());

        const url = new URL('/api/customer', API_URI).toString();

        return fetch(url, {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${Storage.getToken()}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(customer),
        }).then(response => {
            if (response.ok) {
                const customerUpdate = {
                    ...customer,
                    _version: customer._version + 1,
                };
                dispatch(showSnackbar(messages.customerUpdateSuccess));
                return dispatch(updateCustomerDataSuccess(customerUpdate));
            } else {
                return Promise.reject(response.status);
            }
        }).catch(err => {
            dispatch(showSnackbar(messages.customerUpdateError));
            return dispatch(updateCustomerDataError(err));
        });
    };
}

// #endregion
