import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
    AppBar,
    Tabs,
    Tab,
    Typography,
    List,
    ListItem,
    Grid,
    Button,
} from '@material-ui/core';
import SwipeableViews from '@rise/react-swipeable-views';

import MenuBar from 'containers/MenuBar';
import LocaleDate from 'containers/LocaleDate';

import FormattedSupportId from 'components/FormattedSupportId';
import FormattedAmount from 'components/FormattedAmount';
import ConfirmDialog from 'components/ConfirmDialog';
import LoadingBox from 'components/LoadingBox';
import Scroll from 'containers/Scroll';
import { ORDER_STATE_SLIM_ENUM } from 'utils';
import { STRIPE_API_PUBLIC_KEY } from 'config';

import {
    loadOrderGroups as loadOrderGroupsAction,
    cancelOrderGroup as cancelOrderGroupAction,
    loadOrderGroupState as loadOrderGroupStateAction,
} from './actions';

import globalMessages from '../App/messages';
import messages from './messages';

import './styles.scss';

const ORDER_STATES_OPEN = ['PENDING', 'OPEN', 'IN_PREPARATION', 'PREPARED'];
const ORDER_STATES_CLOSED = ['PICKED_UP', 'CANCELED', 'ABORTED'];
const ORDER_STATES_CANCELED = ['CANCELED', 'ABORTED'];

class Orders extends React.Component { // eslint-disable-line

    constructor(props) {
        super(props);

        const { tab } = props.location.state || { tab: 0 };
        this.state = {
            selectedTab: tab,
            orderToCancel: null,
            openConfirmCancel: false,
            checkoutResult: null, // Stripe checkout result
        };
    }

    static getDerivedStateFromProps(nextProps) {
        const params = new URLSearchParams(nextProps.history.location.search);
        const checkoutResult = params.get('result');
        return {
            checkoutResult,
        };
    }

    componentDidMount() {
        this.loadData();
        this.resume = this.loadData.bind(this);
        document.addEventListener('resume', this.resume);
    }

    componentDidUpdate(prevProps, prevState) {
        // Stripe checkout result has been set as URL paramter -> reload order
        if (prevState.checkoutResult !== this.state.checkoutResult) {
            this.loadData();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('resume', this.resume);
    }

    loadData() {
        // this.props.loadOrderQueue();
        this.props.loadOrderGroups();
    }

    handleRefresh(e) {
        this.loadData();
        e.preventDefault();
    }

    handleTabChange(e, tab) {
        this.setState(() => ({
            selectedTab: tab,
        }));
        e.preventDefault();
    }

    handleTabChangeIndex(index) {
        this.setState(() => ({
            selectedTab: index,
        }));
    }

    handleResumePayment({ nextActionUrl, sessionId }) {
        if (nextActionUrl) {
            window.location = nextActionUrl;
        } else {
            // eslint-disable-next-line no-undef
            const stripe = Stripe(STRIPE_API_PUBLIC_KEY);

            stripe.redirectToCheckout({
                sessionId,
            }).then(result => {
                console.log('Stripe payment failed:', result.error.message);
            });
        }
    }

    handleCancelPayment(orderGroupId) {
        this.setState(() => ({ openConfirmCancel: false }));
        this.props.cancelOrderGroup(orderGroupId);
    }

    renderOrderState(state) {
        return (
            <Typography className="state-text" variant="subtitle2">
                <FormattedMessage {...globalMessages[ORDER_STATE_SLIM_ENUM[state]]} />
            </Typography>
        );
    }

    renderTabContent(orderGroups, locale, polling) { // eslint-disable-line class-methods-use-this
        if (!orderGroups.length) {
            return (
                <ListItem>
                    <Typography><FormattedMessage {...messages.noOrdersAvailable} /></Typography>
                </ListItem>
            );
        } else {
            return orderGroups.map(orderGroup => {
                const orderSum = orderGroup.capturedAmount ?? orderGroup.totalAmount + orderGroup.deliveryCosts;

                const isAllCanceled = orderGroup.orders.reduce(
                    (acc, cur) => acc && ORDER_STATES_CANCELED.includes(cur.state), true
                );
                const resumeOrCancelPayment = orderGroup.paymentState === 'PENDING'
                    && orderGroup.stripe?.checkoutResult !== 'SUCCESS'
                    && (orderGroup.stripe?.sessionId || orderGroup.stripe?.nextActionUrl);

                const orders = orderGroup.orders.map(order => {
                    const to = {
                        pathname: `/orders/${order._id}`,
                        state: {
                            from: {
                                ...this.props.location,
                                state: { tab: this.state.selectedTab },
                            },
                        },
                    };

                    return (
                        <Link key={`order-link.${order._id}`} to={to} className="order-item-link">
                            <Grid
                                key={`order.${order._id}`}
                                container
                                justify="space-between"
                                className="order-group-order"
                            >
                                <Grid item xs={9}>
                                    <Typography variant="body2">{order.merchant.name}</Typography>
                                    {this.renderOrderState(order.state)}
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="body2" align="right">
                                        <FormattedAmount
                                            value={order.price}
                                            locale={locale}
                                            canceled={ORDER_STATES_CANCELED.includes(order.state)}
                                        />
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Link>
                    );
                });

                return (
                    <ListItem key={orderGroup._id} className="order-item" disableGutters>
                        <Grid container spacing={0} justify="space-between" className="order-item-info">
                            <LoadingBox isVisible={polling === orderGroup._id && !resumeOrCancelPayment} />

                            {/* HEADER */}
                            <Grid container className="order-group-header">
                                <Grid item xs={6}>
                                    <Typography variant="subtitle1">
                                        <FormattedSupportId value={orderGroup.supportId} />
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <LocaleDate variant="subtitle1" isoDate={orderGroup.createdAt} />
                                </Grid>
                            </Grid>

                            {/* MERCHANT ORDERS */}
                            {orders}

                            {/* FOOTER: Total amount */}
                            {!isAllCanceled &&
                                <Grid container className="order-group-footer">
                                    <Grid container>
                                        <Grid item xs={9}>
                                            <Typography variant="body2">
                                                <FormattedMessage {...globalMessages.deliveryCosts} />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography variant="body2" align="right">
                                                <FormattedAmount
                                                    value={orderGroup.deliveryCosts}
                                                    locale={locale}
                                                />
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={9}>
                                            <Typography variant="body2">
                                                <strong>
                                                    <FormattedMessage {...globalMessages.totalAmount} />
                                                </strong>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography variant="body2" align="right">
                                                <strong>
                                                    <FormattedAmount
                                                        value={orderSum}
                                                        locale={locale}
                                                    />
                                                </strong>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }

                            {resumeOrCancelPayment &&
                                <>
                                    <Grid container justify="center" spacing={0}>
                                        <Grid item xs={12} className="stripe-resume-button-container">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                onClick={() => this.handleResumePayment(orderGroup.stripe)}
                                            >
                                                <FormattedMessage {...messages.resumePayment} />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid container justify="center" spacing={0}>
                                        <Grid item xs={12} className="stripe-resume-button-container">
                                            <Button
                                                color="secondary"
                                                fullWidth
                                                onClick={() => this.setState({
                                                    openConfirmCancel: true,
                                                    orderToCancel: orderGroup._id,
                                                })}
                                            >
                                                <FormattedMessage {...messages.cancelOrderGroup} />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </ListItem>
                );
            });
        }
    }

    render() {
        const { orderGroups, locale, polling } = this.props;
        const {
            openConfirmCancel,
            orderToCancel,
            selectedTab,
            redirectToOrder,
            selectedOrder,
        } = this.state;

        let content;

        if (redirectToOrder) {
            content = <Redirect to={`/orders/${selectedOrder}`} />;
        } else if (!orderGroups) {
            content = <div />;
        } else {
            // order groups with state OPEN / IN_PREPARATION / PREPARED
            // sorted ascending (first order on top)
            const openOrderGroups = orderGroups
                .filter(og => og.orders
                    .filter(o => ORDER_STATES_OPEN.includes(o.state)).length > 0)
                .sort((og1, og2) => new Date(og1.createdAt) - new Date(og2.createdAt));

            // order groups with state PICKED_UP / CANCELED
            // sorted descending (latest order on top)
            const previousOrderGroups = orderGroups
                .filter(og => og.orders
                    .filter(o => ORDER_STATES_CLOSED.includes(o.state)).length === og.orders.length)
                .sort((og1, og2) => new Date(og2.createdAt) - new Date(og1.createdAt));

            content = (
                <div>
                    <AppBar position="sticky" className="sticky-app-bar">
                        <Tabs
                            value={selectedTab}
                            onChange={(e, tab) => this.handleTabChange(e, tab)}
                            variant="fullWidth"
                        >
                            <Tab
                                className="tab-open-orders"
                                label={<FormattedMessage {...messages.openOrders} />}
                            />
                            <Tab
                                className="tab-prev-orders"
                                label={<FormattedMessage {...messages.previousOrders} />}
                            />
                        </Tabs>
                    </AppBar>
                    <SwipeableViews
                        index={selectedTab}
                        onChangeIndex={idx => this.handleTabChangeIndex(idx)}
                        adjustHeight
                    >
                        <List>
                            {this.renderTabContent(openOrderGroups, locale, polling)}
                        </List>
                        <List>
                            {this.renderTabContent(previousOrderGroups, locale, polling)}
                        </List>
                    </SwipeableViews>
                </div>
            );
        }

        return (
            <div className="orders-page">
                <MenuBar titleMessage={globalMessages.orders} onRefreshClick={e => this.handleRefresh(e)} />
                <Scroll sid="orders" className="page-content">
                    {content}
                </Scroll>
                <ConfirmDialog
                    onClose={() => this.setState({ openConfirmCancel: false, orderToCancel: null })}
                    open={openConfirmCancel}
                    titleMessage={messages.confirmCancelTitle}
                    contentMessage={messages.confirmCancelContent}
                    confirmMessage={messages.cancelOrderGroup}
                    onCancelClick={() => this.setState({ openConfirmCancel: false, orderToCancel: null })}
                    onConfirmClick={() => this.handleCancelPayment(orderToCancel)}
                />
            </div>
        );
    }

}

Orders.propTypes = {
    history: PropTypes.object,
    loadOrderGroups: PropTypes.func,
    cancelOrderGroup: PropTypes.func,
    orderGroups: PropTypes.array,
    location: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
    loadOrderGroupState: PropTypes.func.isRequired,
    polling: PropTypes.string,
};

function mapStateToProps(state) {
    return {
        orderGroups: state.orders.orderGroups,
        locale: state.languageProvider.locale,
        polling: state.orders.polling,
    };
}

const mapDispatchToProps = {
    loadOrderGroups: loadOrderGroupsAction,
    cancelOrderGroup: cancelOrderGroupAction,
    loadOrderGroupState: loadOrderGroupStateAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Orders));
