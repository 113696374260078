import { defineMessages } from 'react-intl';

const messages = defineMessages({
    apiVersionIncompatibleHeader: {
        id: 'UpgradeScreen.apiVersionIncompatibleHeader',
        defaultMessage: 'mybuxi Update Available',
    },
    apiVersionIncompatibleBody: {
        id: 'UpgradeScreen.apiVersionIncompatibleBody',
        // eslint-disable-next-line max-len
        defaultMessage: 'There is a newer version of mybuxi available. To use mybuxi please install the latest version.',
    },
    apiVersionIncompatibleBodyBrowser: {
        id: 'UpgradeScreen.apiVersionIncompatibleBodyBrowser',
        defaultMessage: 'There is a newer version of mybuxi available. To use mybuxi please reload the page.',
    },
    getUpdate: {
        id: 'UpgradeScreen.getUpdate',
        defaultMessage: 'Get Update',
    },
    reload: {
        id: 'UpgradeScreen.reload',
        defaultMessage: 'Reload',
    },
});

export default messages;
