import { defineMessages } from 'react-intl';

const messages = defineMessages({
    phoneNumber: {
        id: 'MerchantSummary.phoneNumber',
        defaultMessage: 'Phone number',
    },
    pickUpAddress: {
        id: 'MerchantSummary.pickUpAddress',
        defaultMessage: 'Pick-up address',
    },
});

export default messages;
