import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function BoxLink(props) {
    // onDragStart: disable draggable links on iPad, see #89
    return (
        <Link className="box-link" onDragStart={e => e.preventDefault()} {...props}>
            {props.children}
        </Link>
    );
}

BoxLink.propTypes = {
    children: PropTypes.node,
};

export default BoxLink;
