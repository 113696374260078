/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

const messages = defineMessages({
    openingHours: {
        id: 'MerchantDetails.openingHours',
        defaultMessage: 'Opening hours',
    },
    preorderTitle: {
        id: 'MerchantDetails.preorderTitle',
        defaultMessage: 'Pre-ordering allowed',
    },
    preorderHelp: {
        id: 'MerchantDetails.preorderHelp',
        defaultMessage: 'At this restaurant, you can choose a specific time for picking up your order.',
    },
    closeDialog: {
        id: 'MerchantDetails.closeDialog',
        defaultMessage: 'Close',
    },
});

export default messages;
