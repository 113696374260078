import {
    OPEN_SNACKBAR,
    CLOSE_SNACKBAR, CLOSE_SNACKBAR_PAGE_CHANGE,
} from './constants';

const initialState = {
    open: false,
    message: null,
    closeOnPageChange: false,
};

/*
 * REDUCERS
 */

function snackbarReducer(state = initialState, action) {
    switch (action.type) {
        case OPEN_SNACKBAR:
            return {
                ...state,
                open: true,
                message: action.message,
                closeOnPageChange: action.closeOnPageChange,
            };
        case CLOSE_SNACKBAR:
            return {
                ...state,
                open: false,
                message: null,
            };
        case CLOSE_SNACKBAR_PAGE_CHANGE:
            // checks if the snackbar is open and should be closed on page change
            if (state.open && state.closeOnPageChange) {
                return {
                    ...state,
                    open: false,
                    message: null,
                };
            } else {
                return state;
            }
        default:
            return state;
    }
}

export default snackbarReducer;
