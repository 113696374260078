import {
    LOAD_PRODUCTS_REQUEST,
    LOAD_PRODUCTS_SUCCESS,
    LOAD_PRODUCTS_ERROR,
} from './constants';

const initialState = {
    loading: false,
    error: null,
    categories: null,
    products: null,
    productOptionGroups: null,
    productOptions: null,
};

function productsReducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_PRODUCTS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                categories: null,
                products: null,
                productOptionGroups: null,
                productOptions: null,
            };
        case LOAD_PRODUCTS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                categories: action.result.categories,
                products: action.result.products,
                productOptionGroups: action.result.productOptionGroups,
                productOptions: action.result.productOptions,
            };
        case LOAD_PRODUCTS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
                categories: null,
                products: null,
                productOptionGroups: null,
                productOptions: null,
            };
        default:
            return state;
    }
}

export default productsReducer;
