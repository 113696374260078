import {
    SET_CONTAINER_POSITION,
    RESET_CONTAINER_POSITION,
    RESET_ALL_CONTAINER_POSITIONS,
} from './constants';

export function setContainerPosition(containerId, position) {
    return {
        type: SET_CONTAINER_POSITION,
        containerId,
        position,
    };
}

export function resetContainerPosition(containerId) {
    return {
        type: RESET_CONTAINER_POSITION,
        containerId,
    };
}

export function resetAllContainerPositions() {
    return {
        type: RESET_ALL_CONTAINER_POSITIONS,
    };
}
