import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

import './styles.scss';

class LoadingOverlay extends React.Component {

    constructor(props) {
        super(props);

        this.timeout = null;
        this.state = {
            showOverlay: false,
        };
    }

    componentDidUpdate(props) {
        if (props.isVisible !== this.props.isVisible) {
            this.setDelay();
        }
    }

    componentWillUnmount() {
        this.clearDelay();
    }

    setDelay() {
        const { isVisible } = this.props;

        if (isVisible) {
            if (!this.timeout) {
                this.timeout = setTimeout(() => {
                    this.timeout = null;
                    this.setState({ showOverlay: true });
                }, 250);
            }
        } else {
            this.clearDelay();
        }
    }

    clearDelay() {
        clearTimeout(this.timeout);
        this.timeout = null;
        this.setState({ showOverlay: false });
    }

    render() {
        return (
            <div className={`loading-overlay ${!this.state.showOverlay ? 'hidden' : ''}`}>
                <div className="loading-center">
                    <CircularProgress />
                </div>
            </div>
        );
    }

}

LoadingOverlay.propTypes = {
    isVisible: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
    return {
        isVisible: state.loadingOverlay.isVisible,
    };
}

export default connect(mapStateToProps)(LoadingOverlay);
