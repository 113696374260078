import { showSnackbar } from 'containers/Snackbar/actions';
import {
    openLoadingOverlay,
    closeLoadingOverlay,
} from 'containers/LoadingOverlay/actions';
import globalMessages from 'containers/App/messages';
import { normalize } from '../../utils';
import {
    LOAD_MERCHANTS_REQUEST,
    LOAD_MERCHANTS_SUCCESS,
    LOAD_MERCHANTS_ERROR,
} from './constants';
import { API_URI } from '../../config';

// #region loadMerchants

export function loadMerchantsRequest() {
    return {
        type: LOAD_MERCHANTS_REQUEST,
    };
}

export function loadMerchantsSuccess(result) {
    return {
        type: LOAD_MERCHANTS_SUCCESS,
        result,
    };
}

export function loadMerchantsError(error) {
    return {
        type: LOAD_MERCHANTS_ERROR,
        error,
    };
}

export function loadMerchants(loggedIn, customer = null, coordinates = null) {
    return async dispatch => {

        dispatch(loadMerchantsRequest());
        dispatch(openLoadingOverlay());

        const params = new URLSearchParams();

        if (coordinates) {
            params.append('lng', coordinates.lng);
            params.append('lat', coordinates.lat);
        }

        if (loggedIn && customer && customer.tester) {
            params.append('include_invisible', customer.tester);
        }

        const url = new URL(`/api/public/merchant?${params.toString()}`, API_URI).toString();

        fetch(url)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json()
                        .then(json => Promise.reject(json));
                }
            })
            .then(json => {
                dispatch(loadMerchantsSuccess(normalize(json, '_id')));
                dispatch(closeLoadingOverlay());
            })
            .catch(error => {
                dispatch(loadMerchantsError(error));
                dispatch(showSnackbar(globalMessages.errorLoading));
                dispatch(closeLoadingOverlay());
            });
    };
}

// #endregion
