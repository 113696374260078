import {
    SET_CONTAINER_POSITION,
    RESET_CONTAINER_POSITION,
    RESET_ALL_CONTAINER_POSITIONS,
} from './constants';

const initialState = {
    containers: {},
};

/*
 * REDUCERS
 */

function scrollReducer(state = initialState, action) {
    switch (action.type) {
        case SET_CONTAINER_POSITION: {
            const newState = { ...state };
            newState.containers[action.containerId] = action.position;
            return newState;
        }
        case RESET_CONTAINER_POSITION: {
            const newState = { ...state };
            newState.containers[action.containerId] = 0;
            return newState;
        }
        case RESET_ALL_CONTAINER_POSITIONS:
            return {
                ...state,
                containers: {},
            };
        default:
            return state;
    }
}

export default scrollReducer;
