import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
    IconButton,
    Grid,
    Typography,
    Button,
    TextField,
} from '@material-ui/core';
import {
    Add as AddIcon,
    Remove as RemoveIcon,
} from '@material-ui/icons';
import FormattedAmount from 'components/FormattedAmount';
import globalMessages from 'containers/App/messages';
import './styles.scss';

function CartProductsTable({
    onIncrement,
    onDecrement,
    products,
    merchants,
    comments,
    locale,
    location,
    onChangeComment,
    onBlurComment,
    deliveryCosts,
    totalAmount,
}) {
    const content = [];
    let productRows = [];
    const finalPrice = totalAmount + deliveryCosts;

    Object.keys(products).forEach(merchantId => { // merchant ids
        // Product list
        Object.keys(products[merchantId].items).forEach(i => { // product ids
            const item = products[merchantId].items[i];
            const productPrice = item.quantity * (item.price + (item.options?.reduce((a, b) => a + b.price, 0) ?? 0));
            const textStyles = [];
            if (item.isValid) {
                textStyles.push('cart-list-product-valid');
            } else {
                textStyles.push('cart-list-product--invalid');
            }
            textStyles.push('cart-list-product-title');
            textStyles.push('narrow-line');
            const to = item.options
                ? {
                    pathname: `/product-options/${merchantId}/${item._id}`,
                    state: {
                        from: {
                            ...location,
                        },
                        quantity: item.quantity,
                        options: item.options,
                        shoppingCartId: i,
                    },
                }
                : {};

            productRows.push((
                <div key={`${merchantId}.item.${i}`}>
                    <div className="cart-list-item">
                        <div className="cart-list-minus">
                            <IconButton color="primary" onClick={e => onDecrement(merchantId, item, e)}>
                                <RemoveIcon />
                            </IconButton>
                        </div>
                        <div className="cart-list-quantity" align="center">
                            <Typography variant="body2" className="narrow-line">
                                {item.quantity}
                            </Typography>
                        </div>
                        <div className="cart-list-plus">
                            <IconButton color="primary" onClick={e => onIncrement(merchantId, item, e)}>
                                <AddIcon />
                            </IconButton>
                        </div>
                        <Link to={to} className="cart-list-item-link">
                            <div className="cart-list-product">
                                <Typography variant="body2" className={textStyles.join(' ')}>
                                    {item.name}
                                </Typography>
                                {item.options
                                    ? item.options.map(option => (
                                        <div className="cart-list-option-line" key={`o-${i}-${option._id}`}>
                                            <Typography variant="caption" display="block" className="text-faded">
                                                {option.name}
                                            </Typography>
                                        </div>
                                    ))
                                    : ''
                                }
                            </div>
                            <div className="cart-list-price">
                                <Typography variant="body2" className="narrow-line cart-list-price-text">
                                    <FormattedAmount
                                        value={productPrice}
                                        locale={locale}
                                    />
                                </Typography>
                            </div>
                        </Link>
                    </div>
                </div>
            ));
        });

        // Comment text area
        productRows.push((
            <div key={`${merchantId}.comment`} className="cart-comment-wrapper">
                <TextField
                    id="multiline-flexible"
                    label={<FormattedMessage {...globalMessages.comments} />}
                    fullWidth
                    multiline
                    rowsMax="4"
                    margin="normal"
                    value={comments[merchantId]}
                    onChange={e => onChangeComment({ merchantId, comment: e.target.value })}
                    InputProps={{ onBlur: e => onBlurComment({ merchantId, comment: e.target.value }) }}
                    // This is not a duplicate! InputProps =/= inputProps
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    inputProps={{
                        maxLength: 100,
                    }}
                />
            </div>
        ));

        // Merchant info header
        content.push(
            <div key={`${merchantId}.info`} className="cart-merchant-box">
                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    spacing={0}
                    className="page cart-list-merchant-header"
                >
                    <Grid item>
                        <Typography variant="h6" className="page cart-list-header">
                            {merchants[merchantId].name}
                        </Typography>
                    </Grid>
                    <Grid item className="align-right">
                        <Button
                            component={Link}
                            to={{ pathname: `/merchants/${merchants[merchantId]._id}`, state: { from: location } }}
                            color="primary"
                            className="link-plain"
                        >
                            Details
                        </Button>
                    </Grid>
                </Grid>
                {productRows}
            </div>
        );

        productRows = [];
    });

    const merchantSummary = Object.keys(products).map(merchantId => (
        <Grid key={`p-total-${merchantId}`} container>
            <Grid item xs={9}>
                <div className="cart-list-product">
                    <Typography variant="body2">
                        {merchants[merchantId].name}
                    </Typography>
                </div>
            </Grid>
            <Grid item xs={3}>
                <div className="cart-list-price">
                    <Typography variant="body2" align="right" className="narrow-line">
                        <FormattedAmount
                            value={products[merchantId].totalAmount}
                            locale={locale}
                        />
                    </Typography>
                </div>
            </Grid>
        </Grid>
    ));

    content.push(
        <div key="p-total" className="cart-comment-wrapper">
            <Grid
                container
                justify="space-between"
                alignItems="center"
                spacing={0}
            >
                <Grid item xs={12}>
                    <Typography variant="h6" className="page cart-list-header">
                        Summe
                    </Typography>
                </Grid>

                {merchantSummary}

                <Grid container>
                    <Grid item xs={9}>
                        <div className="cart-list-product cart-list-summary-item">
                            <Typography variant="body2">
                                <FormattedMessage {...globalMessages.deliveryCosts} />
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className="cart-list-price">
                            <Typography variant="body2" align="right" className="narrow-line">
                                <FormattedAmount value={deliveryCosts} locale={locale} />
                            </Typography>
                        </div>
                    </Grid>
                </Grid>

                <Grid container className="cart-list-total">
                    <Grid item xs={9}>
                        <div className="cart-list-product">
                            <Typography variant="body2">
                                <strong>
                                    <FormattedMessage {...globalMessages.totalAmount} />
                                </strong>
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className="cart-list-price">
                            <Typography variant="body2" align="right" className="narrow-line">
                                <strong>
                                    <FormattedAmount value={finalPrice} locale={locale} />
                                </strong>
                            </Typography>
                        </div>
                    </Grid>
                </Grid>

            </Grid>
        </div>
    );

    return (
        <div className="cart-products-table">
            {content}
        </div>
    );
}

CartProductsTable.propTypes = {
    comments: PropTypes.object,
    locale: PropTypes.string.isRequired,
    location: PropTypes.object,
    merchants: PropTypes.object,
    products: PropTypes.object,
    deliveryCosts: PropTypes.number,
    totalAmount: PropTypes.number,

    onBlurComment: PropTypes.func,
    onChangeComment: PropTypes.func,
    onDecrement: PropTypes.func,
    onIncrement: PropTypes.func,
};

export default withRouter(CartProductsTable);
