import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import './styles.scss';
import warningImg from './warning.png';

function ErrorScreen({
    header,
    body,
    render,
    classProp,
    img,
}) {
    return (
        <div className={[classProp, 'screen-box'].join(' ')}>
            <img src={img} alt="warning" draggable="false" />
            <Typography variant="h5" paragraph>
                {header()}
            </Typography>
            <Typography variant="body1" paragraph>
                {body()}
            </Typography>
            {render()}
        </div>
    );
}

ErrorScreen.propTypes = {
    header: PropTypes.func,
    body: PropTypes.func,
    render: PropTypes.func,
    classProp: PropTypes.string,
    img: PropTypes.string,
};

ErrorScreen.defaultProps = {
    img: warningImg,
};

export default ErrorScreen;
