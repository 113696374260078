import {
    LOAD_MERCHANTS_REQUEST,
    LOAD_MERCHANTS_SUCCESS,
    LOAD_MERCHANTS_ERROR,
} from './constants';

const initialState = {
    merchantsLoading: false,
    merchantsError: null,
    merchants: null,
};

function merchantsReducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_MERCHANTS_REQUEST:
            return {
                ...state,
                merchantsLoading: true,
                merchantsError: null,
            };
        case LOAD_MERCHANTS_SUCCESS:
            return {
                ...state,
                merchantsLoading: false,
                merchantsError: null,
                merchants: action.result,
            };
        case LOAD_MERCHANTS_ERROR:
            return {
                ...state,
                merchantsLoading: false,
                merchantsError: action.error,
            };
        default:
            return state;
    }
}

export default merchantsReducer;
