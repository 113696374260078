import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';

import messages from 'containers/App/messages';
import { ORDER_STATE_ENUM } from 'utils';

import './styles.scss';

function OrderStateText({
    orderQueue,
    id,
    state,
    updatedAt,
    pickedUpAt,
    canceledAt,
    locale,
}) {
    let stateClass;
    if (orderQueue && orderQueue[id] && orderQueue[id].position === 1) {
        stateClass = ORDER_STATE_ENUM.NEXT_IN_LINE;
    } else {
        stateClass = ORDER_STATE_ENUM[state];
    }

    const message = messages[stateClass];

    let date;
    let time;
    if (state === 'PICKED_UP') {
        date = moment(pickedUpAt).locale(locale).format('LL');
        time = moment(pickedUpAt).locale(locale).format('LT');
    } else if (state === 'CANCELED') {
        date = moment(canceledAt).locale(locale).format('LL');
        time = moment(canceledAt).locale(locale).format('LT');
    } else {
        date = moment(updatedAt).locale(locale).format('LL');
        time = moment(updatedAt).locale(locale).format('LT');
    }

    return (
        <div className="order-state-text">
            <Typography variant="body2" align="center" className="order-state-text--inner">
                {message &&
                    <FormattedMessage
                        {...message}
                        values={{ date, time }}
                    />
                }
            </Typography>
        </div>
    );
}

OrderStateText.states = ORDER_STATE_ENUM;

OrderStateText.propTypes = {
    orderQueue: PropTypes.object,
    id: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    updatedAt: PropTypes.string,
    pickedUpAt: PropTypes.string,
    canceledAt: PropTypes.string,
    locale: PropTypes.string.isRequired,
};

OrderStateText.defaultProps = {
    updatedAt: new Date().toISOString(),
};

function mapStateToProps(state) {
    return {
        orderQueue: state.global.orderQueue,
        locale: state.languageProvider.locale,
    };
}

export default connect(mapStateToProps)(OrderStateText);
