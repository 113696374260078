import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@material-ui/core';

import TermsOfServiceRef from 'components/TermsOfServiceRef';

import messages from './messages';

function TermsOfServiceDialog({
    open,
    onClose,
    onDecline,
    onAccept,
}) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <FormattedMessage {...messages.termsOfServiceVersionIncompatibleHeader} />
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <FormattedMessage {...messages.termsOfServiceVersionIncompatibleBody} />
                    <br />
                    <TermsOfServiceRef />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onDecline} color="default">
                    <FormattedMessage {...messages.termsOfServiceDecline} />
                </Button>
                <Button onClick={onAccept} color="primary">
                    <FormattedMessage {...messages.termsOfServiceAccept} />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

TermsOfServiceDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onDecline: PropTypes.func.isRequired,
    onAccept: PropTypes.func.isRequired,
};

export default TermsOfServiceDialog;
